import produce from 'immer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createActions, createReducer } from 'reduxsauce';
import { createSelector } from 'reselect';

import { isUnitedStates } from 'utils/locale';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions(
  {
    didMount: ['component', 'payload'],
    setDistanceUnits: ['units'],
    toggleActivityEntryModal: null,
    toggleTicketsCheckoutModal: null,
    toggleDonationsCheckoutModal: null,
    toggleChallengeJoinModal: null,
    toggleSharePageModal: { isInJoinFlow: false },
    toggleP2PGoalsModal: null,
    toggleVideoModal: ['videoID'],
  },
  { prefix: 'APP/' },
);

export const AppTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  sessionNumber: -1,
  distanceUnits: isUnitedStates ? 'miles' : 'km',
  isActivityEntryOpen: false,
  isTicketsCheckoutOpen: false,
  isDonationsCheckoutOpen: false,
  isChallengeJoinOpen: false,
  isSharePageOpen: false,
  isP2PGoalsOpen: false,
  isSharePageInJoinFlow: false,
  isVideoModalOpen: false,
  currentVideoID: null,
};

/* ------------- Reducers ------------- */

// component has mounted
export const didMount = state =>
  produce(state, draft => {
    draft.sessionNumber += 1;
  });

// set distance units
export const setDistanceUnits = (state, action) => {
  const { units } = action;

  return produce(state, draft => {
    draft.distanceUnits = units;
  });
};

// toggle the opening and closing of the activity entry modal
export const toggleActivityEntry = (state, action) =>
  produce(state, draft => {
    draft.isActivityEntryOpen = !draft.isActivityEntryOpen;
  });

// toggle the opening and closing of the ticket checkout modal
export const toggleTicketsCheckout = (state, action) =>
  produce(state, draft => {
    draft.isTicketsCheckoutOpen = !draft.isTicketsCheckoutOpen;
  });

// toggle the opening and closing of the donations checkout modal
export const toggleDonationsCheckout = (state, action) =>
  produce(state, draft => {
    draft.isDonationsCheckoutOpen = !draft.isDonationsCheckoutOpen;
  });

// toggle the opening and closing of the share-page modal
export const toggleSharePage = (state, action) =>
  produce(state, draft => {
    const { isInJoinFlow } = action;
    draft.isSharePageOpen = !draft.isSharePageOpen;
    draft.isSharePageInJoinFlow = isInJoinFlow;
  });

// toggle the opening and closing of the challenge join modal
export const toggleChallengeJoin = (state, action) =>
  produce(state, draft => {
    draft.isChallengeJoinOpen = !draft.isChallengeJoinOpen;
  });

// toggle the opening and closing of the p2p goals modal
export const toggleP2PGoals = (state, action) =>
  produce(state, draft => {
    draft.isP2PGoalsOpen = !draft.isP2PGoalsOpen;
  });

// toggle the opening and closing of the video modal
export const toggleVideoModal = (state, action) => {
  const { videoID } = action;

  return produce(state, draft => {
    draft.isVideoModalOpen = !draft.isVideoModalOpen;
    draft.currentVideoID = videoID || null;
  });
};

/* ------------- Hookup Reducers To Types ------------- */

const persistConfig = {
  storage,
  key: 'app',
  whitelist: ['sessionNumber', 'distanceUnits'],
};

const appReducer = createReducer(INITIAL_STATE, {
  [Types.DID_MOUNT]: didMount,
  [Types.SET_DISTANCE_UNITS]: setDistanceUnits,
  [Types.TOGGLE_ACTIVITY_ENTRY_MODAL]: toggleActivityEntry,
  [Types.TOGGLE_TICKETS_CHECKOUT_MODAL]: toggleTicketsCheckout,
  [Types.TOGGLE_DONATIONS_CHECKOUT_MODAL]: toggleDonationsCheckout,
  [Types.TOGGLE_CHALLENGE_JOIN_MODAL]: toggleChallengeJoin,
  [Types.TOGGLE_SHARE_PAGE_MODAL]: toggleSharePage,
  [Types.TOGGLE_P2_P_GOALS_MODAL]: toggleP2PGoals,
  [Types.TOGGLE_VIDEO_MODAL]: toggleVideoModal,
});

export const reducer = persistReducer(persistConfig, appReducer);

/* ------------- Selectors ------------- */

const app = state => state.app;
const getSessionNumber = createSelector(app, a => a.sessionNumber);
const getDistanceUnits = createSelector(app, a => a.distanceUnits);
const isActivityEntryOpen = createSelector(app, a => a.isActivityEntryOpen);
const isTicketsCheckoutOpen = createSelector(app, a => a.isTicketsCheckoutOpen);
const isDonationsCheckoutOpen = createSelector(app, a => a.isDonationsCheckoutOpen);
const isChallengeJoinOpen = createSelector(app, a => a.isChallengeJoinOpen);
const isSharePageOpen = createSelector(app, a => a.isSharePageOpen);
const isSharePageInJoinFlow = createSelector(app, a => a.isSharePageInJoinFlow);
const isP2PGoalsOpen = createSelector(app, a => a.isP2PGoalsOpen);
const isVideoModalOpen = createSelector(app, a => a.isVideoModalOpen);
const getCurrentVideoID = createSelector(app, a => a.currentVideoID);

export const selectors = state => {
  return {
    getSessionNumber: getSessionNumber(state),
    getDistanceUnits: getDistanceUnits(state),
    isActivityEntryOpen: isActivityEntryOpen(state),
    isTicketsCheckoutOpen: isTicketsCheckoutOpen(state),
    isDonationsCheckoutOpen: isDonationsCheckoutOpen(state),
    isChallengeJoinOpen: isChallengeJoinOpen(state),
    isSharePageOpen: isSharePageOpen(state),
    isSharePageInJoinFlow: isSharePageInJoinFlow(state),
    isP2PGoalsOpen: isP2PGoalsOpen(state),
    isVideoModalOpen: isVideoModalOpen(state),
    getCurrentVideoID: getCurrentVideoID(state),
  };
};
