export default class PartnersAPI {
  constructor(services) {
    this.services = services;
    services.afterLoad(() => {
      // after load
    });
  }

  list({ category, query }) {
    return this.services.baseAPIv2.get('/admin/partners', false, {
      type: category,
      match: query,
    });
  }

  create(data) {
    const {
      type,
      name,
      username,
      summary,
      avatar_url,
      web_url,
      use_custom_payment,
      checkout_url,
      owner_ids,
      i18n,
    } = data;

    return this.services.baseAPIv2.post('/admin/partners', {
      type,
      name,
      username,
      summary,
      web_url,
      avatar_url,
      use_custom_payment,
      checkout_url,
      owner_ids,
      i18n,
    });
  }

  update(id, data) {
    const {
      name,
      username,
      summary,
      avatar_url,
      web_url,
      use_custom_payment,
      checkout_url,
      stripe_payment_methods,
      owner_ids,
      i18n,
    } = data;

    return this.services.baseAPIv2.put(`/admin/partners/${id}`, {
      name,
      username,
      summary,
      web_url,
      avatar_url,
      use_custom_payment,
      checkout_url,
      stripe_payment_methods,
      owner_ids,
      i18n,
    });
  }

  archive(id) {
    return this.services.baseAPIv2.patch(`/admin/partners/${id}`);
  }

  get(id) {
    return this.services.baseAPIv2.get(`/admin/partners/${id}`, false);
  }

  unlinkStripeAccount(id) {
    return this.services.baseAPIv2.patch(`/admin/partners/unlink-stripe/${id}`);
  }
}
