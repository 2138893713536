export default class ChallengeVideosAdminAPI {
  constructor(services) {
    this.services = services;
    services.afterLoad(() => {
      // after load
    });
  }

  list({ query, challengeID }) {
    return this.services.baseAPIv2.get('/admin/challenge-videos/', false, {
      match: query,
      challenge_id: challengeID,
    });
  }

  batchUpdate({ challengeID, removedChallengeVideos, newChallengeVideos, updatedChallengeVideos }) {
    return this.services.baseAPIv2.post(`/admin/challenge-videos/batch-update/${challengeID}`, {
      removedChallengeVideos,
      newChallengeVideos,
      updatedChallengeVideos,
    });
  }
}
