import getUserLocale from 'get-user-locale';

export default class AuthAPI {
  constructor(services) {
    this.services = services;
    services.afterLoad(() => {});
  }

  reset({ identity }) {
    return this.services.baseAPIv2.post('auth/reset', { identity }, true);
  }

  updatePassword({ token, password }) {
    return this.services.baseAPIv2.patch(`auth/reset/${token}`, { password }, true);
  }

  checkEmail({ email }) {
    return this.services.baseAPIv2.get('auth/check-email', false, {
      match: email,
    });
  }

  checkUsername({ username }) {
    return this.services.baseAPIv2.get('auth/check-username', false, {
      match: username,
    });
  }

  claimChallengeTicket({ challengeTicketID }) {
    return this.services.baseAPIv2.put(`auth/claim-challenge-ticket/${challengeTicketID}`);
  }

  login({ identity, password, challengeID, groupIDs = [], ticketID }) {
    return this.services.baseAPIv2.post('auth/login', {
      identity,
      password,
      challenge_id: challengeID,
      group_ids: groupIDs,
      ticket_id: ticketID,
    });
  }

  signup({
    email,
    firstName,
    lastName,
    username,
    password,
    challengeID,
    groupIDs = [],
    ticketID,
    marketingAccepted,
  }) {
    const locale = getUserLocale();
    return this.services.baseAPIv2.post('auth/signup', {
      email,
      first_name: firstName,
      last_name: lastName,
      username,
      password,
      challenge_id: challengeID,
      group_ids: groupIDs,
      ticket_id: ticketID,
      locale,
      marketing_opt_in: marketingAccepted,
    });
  }

  fb({ accessToken, challengeID, groupIDs = [], ticketID }) {
    const locale = getUserLocale();
    return this.services.baseAPIv2.post('auth/fb', {
      access_token: accessToken,
      challenge_id: challengeID,
      group_ids: groupIDs,
      ticket_id: ticketID,
      locale,
    });
  }

  strava({ authCode, challengeID, groupIDs = [], ticketID }) {
    const locale = getUserLocale();

    return this.services.baseAPIv2.post('auth/strava/web', {
      code: authCode,
      challenge_id: challengeID,
      group_ids: groupIDs,
      ticket_id: ticketID,
      locale,
    });
  }

  stripe({ email, partnerID }) {
    return this.services.baseAPIv2.post('auth/stripe/invite', {
      email,
      partner_id: partnerID,
    });
  }

  connectSlack(slackUserId) {
    return this.services.baseAPIv2.get(`auth/slack/connect/${slackUserId}`);
  }
}
