export default class ChallengesAPI {
  constructor(services) {
    this.services = services;
    services.afterLoad(() => {});
  }

  partial({ challengeID, distanceUnits }) {
    return this.services.baseAPIv2.get(`challenges/${challengeID}/partial?units=${distanceUnits}`);
  }

  groupLists({ challengeID, distanceUnits, ownerID }) {
    return this.services.baseAPIv2.get(
      `challenges/${challengeID}/group-lists?units=${distanceUnits}&owner_id=${ownerID}`,
    );
  }

  createGroup({ challengeID, name, groupListID, imageURL, ownerID }) {
    return this.services.baseAPIv2.post(`challenges/${challengeID}/groups`, {
      name,
      image_url: imageURL,
      group_list_id: groupListID,
      owner_id: ownerID,
    });
  }

  deleteGroup({ challengeID, groupID, ownerID }) {
    return this.services.baseAPIv2.put(`challenges/${challengeID}/groups`, {
      group_id: groupID,
      owner_id: ownerID,
    });
  }

  leaderboard({ challengeID }) {
    return this.services.baseAPIv2.get(`challenges/${challengeID}/leaderboard2`);
  }

  groupsLeaderboard({ challengeID }) {
    return this.services.baseAPIv2.get(`challenges/${challengeID}/groups-leaderboard2`);
  }

  getWysiwygContent({ challengeID }) {
    return this.services.baseAPIv2.get(`challenges/${challengeID}/wysiwyg-content`);
  }

  createWysiwygContent({ challengeID, content }) {
    if (!content) throw new Error('WYSIWYG Content is null or undefined');

    return this.services.baseAPIv2.post(`challenges/${challengeID}/wysiwyg-content`, {
      content: JSON.stringify(content),
    });
  }

  updateWysiwygContent({ challengeID, content }) {
    if (!content) throw new Error('WYSIWYG Content is null or undefined');

    return this.services.baseAPIv2.patch(`challenges/${challengeID}/wysiwyg-content`, {
      content: JSON.stringify(content),
    });
  }

  tickets({ challengeID }) {
    return this.services.baseAPIv2.get(`challenges/${challengeID}/tickets`);
  }

  buyTickets({ challengeID, data }) {
    return this.services.baseAPIv2.post(`challenges/${challengeID}/tickets/buy`, {
      ...data,
    });
  }

  challengeTicket({ challengeID, ticketID }) {
    return this.services.baseAPIv2.get(`challenges/${challengeID}/tickets/purchased/${ticketID}`);
  }

  featuredActivityTypes({ challengeID }) {
    return this.services.baseAPIv2.get(`challenges/${challengeID}/featured-activity-types`);
  }

  verifyCoupon({ challengeID, couponCode }) {
    return this.services.baseAPIv2.post(`challenges/${challengeID}/verify-coupon`, {
      coupon_code: couponCode,
    });
  }

  verifyUnlockCode({ challengeID, unlockCode }) {
    return this.services.baseAPIv2.post(`challenges/${challengeID}/verify-unlock-code`, {
      unlock_code: unlockCode,
    });
  }

  listP2PDonations({ challengeID }) {
    return this.services.baseAPIv2.get(`challenges/${challengeID}/donations`);
  }

  searchP2PDonations({ challengeID, search, group }) {
    const url = new URL(`http://atlasgo.org/challenges/${challengeID}/donations`);

    if (search) url.searchParams.set('search', encodeURIComponent(search));
    if (group) url.searchParams.set('group', encodeURIComponent(group));

    return this.services.baseAPIv2.get(`${url.pathname}${url.search}`);
  }

  getP2PFundraiser({ challengeID, fundraiser, group }) {
    const url = new URL(`http://atlasgo.org/challenges/${challengeID}/donations`);

    if (group) url.searchParams.set('group', encodeURIComponent(group));
    if (fundraiser) url.searchParams.set('fundraiser', encodeURIComponent(fundraiser));

    return this.services.baseAPIv2.get(`${url.pathname}${url.search}`);
  }

  getP2PDonationsTotal({ challengeID }) {
    return this.services.baseAPIv2.get(`challenges/${challengeID}/donations/total`);
  }

  P2PIndividualsLeaderboard({ challengeID }) {
    return this.services.baseAPIv2.get(
      `challenges/${challengeID}/donations/individuals-leaderboard`,
    );
  }

  P2PTeamsLeaderboard({ challengeID }) {
    return this.services.baseAPIv2.get(
      `challenges/${challengeID}/donations/group-list-leaderboard`,
    );
  }

  makeDonation({ challengeID, data }) {
    return this.services.baseAPIv2.post(`challenges/${challengeID}/donations/checkout`, {
      ...data,
    });
  }

  getGroupDetails({ challengeID, groupID }) {
    return this.services.baseAPIv2.get(`challenges/${challengeID}/p2p/groups/${groupID}`);
  }

  getGroupDonationHistory({ challengeID, groupID }) {
    return this.services.baseAPIv2.get(`challenges/${challengeID}/p2p/groups/${groupID}/donations`);
  }

  // '/challenges/:id/p2p/groups/:typeID/wysiwyg-content'
  getGroupWysiwygContent({ challengeID, groupID }) {
    return this.services.baseAPIv2.get(
      `challenges/${challengeID}/p2p/groups/${groupID}/wysiwyg-content`,
    );
  }

  createGroupWysiwygContent({ challengeID, groupID, content }) {
    if (!content) throw new Error('WYSIWYG Content is null or undefined');

    return this.services.baseAPIv2.post(
      `challenges/${challengeID}/p2p/groups/${groupID}/wysiwyg-content`,
      {
        content: JSON.stringify(content),
      },
    );
  }

  updateGroupWysiwygContent({ challengeID, groupID, content }) {
    if (!content) throw new Error('WYSIWYG Content is null or undefined');

    return this.services.baseAPIv2.patch(
      `challenges/${challengeID}/p2p/groups/${groupID}/wysiwyg-content`,
      {
        content: JSON.stringify(content),
      },
    );
  }

  getUserDonationHistory({ challengeID, userID }) {
    return this.services.baseAPIv2.get(`challenges/${challengeID}/p2p/users/${userID}/donations`);
  }

  // /challenges/:id/p2p/users/:typeID/wysiwyg-content
  getUserWysiwygContent({ challengeID, userID }) {
    return this.services.baseAPIv2.get(
      `challenges/${challengeID}/p2p/users/${userID}/wysiwyg-content`,
    );
  }

  createUserWysiwygContent({ challengeID, userID, content }) {
    if (!content) throw new Error('WYSIWYG Content is null or undefined');

    return this.services.baseAPIv2.post(
      `challenges/${challengeID}/p2p/users/${userID}/wysiwyg-content`,
      {
        content: JSON.stringify(content),
      },
    );
  }

  updateUserWysiwygContent({ challengeID, userID, content }) {
    if (!content) throw new Error('WYSIWYG Content is null or undefined');

    return this.services.baseAPIv2.patch(
      `challenges/${challengeID}/p2p/users/${userID}/wysiwyg-content`,
      {
        content: JSON.stringify(content),
      },
    );
  }

  updateGoal({ challengeID, goalID }) {
    return this.services.baseAPIv2.patch(`challenges/${challengeID}/p2p/goals/${goalID}`);
  }
}
