import ReactGA from 'react-ga';
// styles
import TagManager from 'react-gtm-module';
import { FALLBACK_PAGEVIEW, SEND_EVENT, SEND_PAGE_VIEW } from '@recruit-tech/react-redux-analytics';
import v from 'voca';

const tagManagerArgs = {
  gtmId: '',
};

// For 'Climb for a cure'. Added 5/18/21
if (window.location.href.includes('60401d5b1eafc55a9b6f6116')) {
  tagManagerArgs.gtmId = 'GTM-WFPWMGF';
}

if (window.location.href.includes('6080b8eee49c90056e488442')) {
  tagManagerArgs.gtmId = 'GTM-NVGS8M';
}

TagManager.initialize(tagManagerArgs);

let currentPage = '';

const getNextPage = location => {
  if (location === null) return location;
  const pathname =
    location.pathname.length > 1 ? v.trimRight(location.pathname, '/') : location.pathname;

  return `${pathname}${location.search}`;
};

const sendPageView = ({ location, variables }) => {
  const { title } = variables || {};

  try {
    ReactGA.pageview(location, null, title);
    window.dataLayer.push({ event: 'pageview' });
  } catch (e) {
    throw e;
  }
};

const sendEvent = ({ eventName, variables }) => {
  try {
    ReactGA[eventName](variables);
  } catch (e) {
    throw e;
  }
};

export const googleAnalytics = store => next => action => {
  const { type, payload } = action;

  switch (type) {
    case '@@router/LOCATION_CHANGE': {
      const nextPage = getNextPage(payload.location);

      if (currentPage !== nextPage && !nextPage.includes('/challenges/')) {
        currentPage = nextPage;
        sendPageView({ location: nextPage });
      }
      break;
    }
    case SEND_PAGE_VIEW: {
      const nextPage = getNextPage(payload.location);

      if (currentPage !== nextPage) {
        currentPage = nextPage;
        sendPageView({
          location: getNextPage(payload.location),
          variables: payload.variables,
        });
      }
      break;
    }
    case SEND_EVENT:
      sendEvent({
        eventName: payload.eventName || 'event',
        variables: payload.variables,
      });
      break;
    case FALLBACK_PAGEVIEW:
      sendPageView({
        location: getNextPage(payload.location),
        variables: { ...payload.variables, fallbackPageView: true },
      });
      break;
    default:
      break;
  }

  return next(action);
};
