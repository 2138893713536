import { create } from 'apisauce';
import i18n from 'i18next';

import DebugConfig from 'config/debug';
import Reactotron from 'config/reactotron';
import { getBaseURLs } from 'utils';

export default class BaseAPI {
  constructor(services) {
    services.afterLoad(() => {});

    this.services = services;
  }

  get sessionKey() {
    return this.services.sessionKey;
  }

  route(action) {
    return action[0] === '/' ? action : `/${action}`;
  }

  getCurrentLng() {
    return i18n.language || window.localStorage.i18nextLng || 'en';
  }

  getAPI(noAuth = false, url) {
    const baseURL = url || `${getBaseURLs().http}/v2`;
    const api = create({ baseURL });

    api.setHeader('Accept-Language', this.getCurrentLng());

    if (!noAuth) api.setHeader('X-Atlas-Session-Key', `Bearer ${this.sessionKey}`);
    if (process.env.NODE_ENV === 'development' && DebugConfig.useReactotron) {
      api.addMonitor(Reactotron.apisauce);
    }

    return api;
  }

  get(action, noAuth, data = undefined, baseURL, headers = {}) {
    const api = this.getAPI(noAuth, baseURL);

    api.setHeaders(headers);

    return api.get(this.route(action), data);
  }

  post(action, data, noAuth, headers = {}, baseURL) {
    const api = this.getAPI(noAuth, baseURL);

    api.setHeaders(headers);

    return api.post(this.route(action), data);
  }

  put(action, data, noAuth) {
    const api = this.getAPI(noAuth);

    return api.put(this.route(action), data);
  }

  delete(action, noAuth) {
    const api = this.getAPI(noAuth);

    return api.delete(this.route(action));
  }

  patch(action, data, noAuth) {
    const api = this.getAPI(noAuth);

    return api.patch(this.route(action), data);
  }

  devFetch(action, method) {
    const api = this.getAPI(true, 'http://localhost:8000/v2/');

    return api[method](this.route(action));
  }

  // temporary for testing P2P
  getStagingAPI(noAuth = false) {
    const baseURL = 'https://api-staging.atlasrun.com/v2';

    const api = create({ baseURL });
    const getCurrentLng = i18n.language || window.localStorage.i18nextLng || 'en';

    api.setHeader('Accept-Language', getCurrentLng);
    if (!noAuth) api.setHeader('X-Atlas-Session-Key', `Bearer ${this.sessionKey}`);

    if (process.env.NODE_ENV === 'development' && DebugConfig.useReactotron) {
      api.addMonitor(Reactotron.apisauce);
    }

    return api;
  }

  getStaging(action, noAuth, data = undefined) {
    const api = this.getStagingAPI(noAuth);

    return api.get(this.route(action), data);
  }
}
