export default {
  fields: {
    data: {
      keyArgs: false,
      merge(existing, incoming, { variables }) {
        if (!incoming) return existing;
        if (!existing) return incoming;

        // if this is using numeric pagination, just return the incoming data
        if (variables.paged) return incoming;

        // if it is a pull to refresh request, also just return the incoming data
        if (variables.refresh) return incoming;

        const offset = (variables.page - 1) * variables.limit;
        const merged = existing ? existing.slice(0) : [];

        incoming.forEach((item, i) => {
          merged[offset + i] = item;
        });

        return merged;
      },
    },
  },
};
