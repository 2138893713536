export const ROUTES = {
  // public routes
  landing: '/',
  auth: '/auth',
  home: '/home',
  goBoard: '/dash',
  pwReset: '/reset-password',
  join: (id = ':id') => `/challenges/${id}/join`,
  joinExpires: (id = ':id', base64Timestamp = ':base64Timestamp') =>
    `/challenges/${id}/${base64Timestamp}/join`,
  notFound: '/404',
  challenge: (id = ':id') => `/challenges/${id}`,
  challengeFundraise: (id = ':id') => `/challenges/${id}/fundraise`,
  challengeGroup: (id = ':id', groupID = ':groupID') => `/challenges/${id}/groups/${groupID}`,
  challengeGroupUser: (id = ':id', userID = ':userID') => `/challenges/${id}/users/${userID}`,
  challengeTicketsRedirect: (id = ':id') => `/challenges/${id}/tickets`,
  challengeDonationsRedirect: (id = ':id') => `/challenges/${id}/donations`,
  status: '/status',
  androidGpsSupport: '/android-gps-support',
  activityTypesList: '/activities',
  webexInitiator: '/webex/initiator',
  webexShared: (id = ':id') => `/webex/shared/${id}`,
  connectSlack: '/connect-slack/:slackUserId',

  // embeds
  sweatySelfies: '/embed/sweaty-selfies/:id',
  leaderboards: '/embed/leaderboards/:id',
  progress: '/embed/progress/:id',
  fundraisingLeaderboards: '/embed/fundraising-leaderboards/:id',
  fundraisingProgress: '/embed/fundraising-progress/:id',

  // admin routes
  admin: '/admin',
  partners: '/admin/partners',
  challenges: '/admin/challenges',
  challengeMaps: '/admin/challenge-maps',
  challengeGroupLists: (id = ':id', name = ':name') => `/admin/challenges?id=${id}&name=${name}`,
  activityTypes: '/admin/activity-types',
  tickets: '/admin/tickets',
  tracking: '/admin/tracking',
  currencies: '/admin/currencies',
  users: '/admin/users',
  groups: '/admin/groups',
  groupList: (
    id = ':id',
    name = ':name',
    challengeID = ':challengeID',
    challengeName = ':challengeName',
    groupListsLength = ':groupListsLength',
  ) => {
    return `/admin/groups?group_list_id=${id}&name=${name}&challenge_id=${challengeID}&challenge_name=${challengeName}&gl_length=${groupListsLength}`;
  },
  challengeIntegrations: (id = ':id') => `/admin/challenges/${id}/integrations`,
  infra: '/admin/infra',
  feedStories: '/admin/feed-stories',
  reports: '/admin/reports',
  roles: '/admin/roles',
  activityVideos: '/admin/activity-videos',
  achievements: '/admin/achievements',
  surveys: '/admin/surveys',
};
