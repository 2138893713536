import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

/* eslint-disable */
function paramsToObject(entries) {
  const result = {};
  for (const entry of entries) {
    const [key, value] = entry;
    result[key] = value;
  }
  return result;
}

export default function bindRouteParamsToProps() {
  return function(EmbedClass) {
    const klass = class extends PureComponent {
      static propTypes = {
        match: PropTypes.any,
      };

      render() {
        const { match, location } = this.props;
        const routeParams = match.params || {};

        const urlQuery = new URLSearchParams(location.search || '');
        const entries = urlQuery.entries();
        const queryParams = paramsToObject(entries);

        return <EmbedClass {...routeParams} queryParams={queryParams} {...this.props} />;
      }
    };

    return klass;
  };
}
/* eslint-enable */
