import { decrypt, encrypt } from 'utils';

export default class ActivityAPI {
  constructor(services) {
    this.services = services;
    services.afterLoad(() => {});
  }

  submit({
    meters,
    activityType,
    isManualEntry = true,
    seconds = null,
    units,
    importId = null,
    activityId = null,
    token = JSON.stringify({}),
  }) {
    try {
      const secret = 'atlas-run';
      const decryptedToken = decrypt(token, secret);
      const parsedToken = JSON.parse(decryptedToken);

      parsedToken.step = 2;

      const newToken = encrypt(JSON.stringify(parsedToken), secret);

      return this.services.baseAPIv2.post(
        'make-impact',
        {
          meters,
          activity_type: activityType,
          is_manual_entry: isManualEntry,
          seconds,
          units,
          import_id: importId,
          activity_id: activityId,
        },
        false,
        {
          'X-Atlas-CSRF-Token': newToken,
        },
      );
    } catch (error) {
      return new Promise((res, rej) => {
        res({ ok: false, originalError: new Error('Invalid CSRF Token. Try again.') });
      });
    }
  }
}
