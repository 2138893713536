export default class currencyAPI {
  constructor(services) {
    this.services = services;
    services.afterLoad(() => {
      // after load
    });
  }

  list({ query }) {
    return this.services.baseAPIv2.get('/currency/', false, {
      match: query,
    });
  }
}
