import i18next from 'i18next';

export const getLanguage = () => i18next.language || window.localStorage.i18nextLng || 'en-US';
const locale = getLanguage();

export function isUnitedStates() {
  return navigator.language.toLowerCase().indexOf('us') !== -1;
}

export function currencyFormatter(amount, currencyCode = 'USD') {
  if (currencyCode == null) return amount;

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 0,
  }).format(amount);
}

export function currencyFormatterObject(currencyCode = 'USD') {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 0,
  });
}

export function getLocalized({ object, field, language }) {
  if (object.i18n != null) {
    const localized = object.i18n[language] ? object.i18n[language][field] : null;
    if (localized != null) {
      return localized;
    }
  }

  return object[field];
}
