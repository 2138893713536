import CryptoJS from 'crypto-js';

import { APP_SECRET } from 'config/constants';

const passphrase = APP_SECRET;

export function encrypt(text, secret) {
  return CryptoJS.AES.encrypt(text, secret || passphrase).toString();
}

export function decrypt(cipherText, secret) {
  const bytes = CryptoJS.AES.decrypt(cipherText, secret || passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);

  return originalText;
}
