import { create } from 'apisauce';

import DebugConfig from 'config/debug';
import Reactotron from 'config/reactotron';
import { getBaseURLs } from 'utils';

export default class BaseAPI {
  constructor(services) {
    services.afterLoad(() => {});
    this.services = services;
  }

  get sessionKey() {
    return this.services.sessionKey;
  }

  getAPI() {
    const baseURL = getBaseURLs(true).http;

    return create({ baseURL });
  }

  post(action, data = {}) {
    const self = this;
    const api = this.getAPI();
    const sanitizedAction = action[0] === '/' ? action : `/${action}`;

    if (process.env.NODE_ENV === 'development' && DebugConfig.useReactotron) {
      api.addMonitor(Reactotron.apisauce);
    }

    return new Promise((resolve, reject) => {
      api
        .post(sanitizedAction, { session_key: self.sessionKey, ...data })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          if (error.response) {
            if (error.response.status === 403 || error.response.status === 401) {
              // authentication error
              self.onAuthCredentialsRejected();
              reject(new Error('API Error: Authentication credentials rejected.'));
            } else {
              // request OK but non-2xx response
              reject(new Error(`API Error: Received an HTTP ${error.response.status}`));
            }
          } else {
            reject(new Error('API Error: Unable to reach the server at this time'));
          }
        });
    });
  }

  postMultiPartWithImage(action, params = {}, file) {
    const self = this;
    const api = this.getAPI();
    const sanitizedAction = action[0] === '/' ? action : `/${action}`;

    const data = new FormData();

    // append the session key to the form data
    data.append('session_key', self.sessionKey);

    // append all the files to the form data
    // files.forEach((file, i) => (data.append(`files[${i}]`, file)));
    data.append('image', file);

    // append the params to the form data if any
    Object.keys(params).forEach(key => data.append(key, params[key]));

    if (process.env.NODE_ENV === 'development' && DebugConfig.useReactotron) {
      api.addMonitor(Reactotron.apisauce);
    }

    return new Promise((resolve, reject) => {
      api
        .post(sanitizedAction, data, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  onAuthCredentialsRejected = () => {};
}
