// https://docs.sentry.io/platforms/javascript/guides/react/performance/
// https://github.com/sentry-demos/tracing/blob/master/react/src/index.js
// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/
// https://docs.sentry.io/platforms/javascript/guides/react/performance/instrumentation/automatic-instrumentation/
// https://docs.sentry.io/platforms/javascript/guides/react/performance/connect-services/
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { SENTRY_DSN } from './constants';
import { history } from './history';

const tracingOrigins = ['localhost:8000', 'api.atlas.run', /^\//];

Sentry.init({
  dsn: SENTRY_DSN,
  environment: process.env.NODE_ENV,
  release: `atlasGOWebClient@${require('../../package.json').version}`,
  // debug: DebugConfig.debugSentry,
  // beforeSend(event) {
  //   const user = getStore().getState().user;
  //   if (event.exception) {
  //     Sentry.showReportDialog({
  //       eventId: event.event_id,
  //       user: {
  //         name: user.firstName,
  //         email: user.email,
  //       },
  //     });
  //   }
  //   return event;
  // },
  integrations: [
    new BrowserTracing({
      tracingOrigins,

      // Can also use reactRouterV3Instrumentation or reactRouterV4Instrumentation
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  tracesSampleRate: 0.5,
  autoSessionTracking: true,
  normalizeDepth: 6,
});
