import styled from 'styled-components/macro';

const size = props => `${props.size || 50}px`;
const borderWidth = props => `${props.borderWidth || 4}px`;
const color = props => props.color || props.theme.colors.darkGreen;

export const Spinner = styled.div`
  width: ${props => size(props)};
  height: ${props => size(props)};
  margin: 0;
  background: transparent;
  box-shadow: 0px 1px 0px rgba(6.12, 184, 160, 0.8);
  border-radius: 100%;
  animation: 0.75s spin linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
      box-shadow: 0px 1px 0px rgba(6.12, 184, 160, 0.8);
    }
    50% {
      transform: rotate(180deg);
      box-shadow: 0px 1px 0px rgba(6.12, 214, 145, 1);
    }
    100% {
      transform: rotate(360deg);
      box-shadow: 0px 1px 0px rgba(6.12, 184, 160, 0.8);
    }
  }
`;

export const ButtonSpinner = styled.div`
  width: ${props => size(props)};
  height: ${props => size(props)};
  margin: 0;
  background: transparent;
  border-top: ${props => borderWidth(props)} solid ${props => color(props)};
  border-right: ${props => borderWidth(props)} solid transparent;
  border-radius: 50%;
  /* -webkit-animation: 1s spin linear infinite; */
  animation: 1s spin linear infinite;
  @-webkit-keyframes spin {
    from {
      /* -webkit-transform: rotate(0deg); */
      transform: rotate(0deg);
    }
    to {
      /* -webkit-transform: rotate(360deg); */
      transform: rotate(360deg);
    }
  }
  @keyframes spin {
    from {
      /* -webkit-transform: rotate(0deg); */
      transform: rotate(0deg);
    }
    to {
      /* -webkit-transform: rotate(360deg); */
      transform: rotate(360deg);
    }
  }
`;
