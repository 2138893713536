import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import bg from './locales/bg';
import de from './locales/de';
import en from './locales/en';
import es from './locales/es';
import fr from './locales/fr';
import it from './locales/it';
import nl from './locales/nl';
import pt from './locales/pt';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { app: en },
      fr: { app: fr },
      nl: { app: nl },
      de: { app: de },
      es: { app: es },
      it: { app: it },
      pt: { app: pt },
      bg: { app: bg },
    },
    fallbackLng: {
      'en-US': ['en'],
      'fr-FR': ['fr'],
      default: ['en'],
    },
    returnEmptyString: false,
    debug: false,
    ns: ['app'],
    defaultNS: 'app',
    nsSeparator: false,
    keySeparator: 'false',
    interpolation: {
      escapeValue: false,
    },
    react: {
      // useSuspense: false,
    },
  });

export default i18n;
