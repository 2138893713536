import React from 'react';
import PropTypes from 'prop-types';

import { ButtonSpinner, Spinner } from './ActivitySpinner.styles';

const ActivitySpinner = props => {
  const { type } = props;
  if (type === 'button') {
    return <ButtonSpinner {...props} />;
  }

  return <Spinner {...props} />;
};

ActivitySpinner.defaultProps = {
  size: 48,
};

ActivitySpinner.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  borderWidth: PropTypes.number,
  type: PropTypes.string,
};

export default ActivitySpinner;
