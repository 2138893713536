export default class challengesAdminAPI {
  constructor(services) {
    this.services = services;
    services.afterLoad(() => {
      // after load
    });
  }

  list({ query }) {
    return this.services.baseAPIv2.get('/admin/challenges/', false, {
      match: query,
    });
  }

  create(data) {
    const {
      allows_user_created_groups,
      cover_opacity,
      cover_url,
      dash_color_main,
      dash_hero_image_url,
      dash_hero_text_color,
      dash_social_image_url,
      dash_stat_options,
      dashboard_bd_cta,
      default_individual_goal_target_amount,
      default_individual_goal_user_can_go_over_target_amount,
      disable_follow,
      disable_individual_leaderboards,
      disable_leaderboards,
      disable_other_stats,
      disable_search,
      disable_users_list,
      donation_goal_email,
      donation_notification_email,
      donation_receipt_email,
      donation_url,
      force_completion,
      has_app_invite_friends_text,
      hidden,
      hidden_on_open_challenge_listing,
      hide_tickets_remaining,
      i18n,
      individual_goal_type,
      individual_shows_goal,
      individual_shows_progress,
      individual_shows_raised,
      invite_friends_text,
      invite_url,
      long_summary,
      max_age_for_activity_import,
      max_users_per_group,
      name,
      non_profit_partner_id,
      owner_ids,
      p2p_currency,
      p2p_donation_target,
      p2p_force_completion,
      p2p_raised_offset,
      p2p_requires_address,
      p2p_shows_goal,
      p2p_shows_progress,
      p2p_shows_raised,
      p2p_thank_you_message,
      peer_to_peer_supported,
      pm_feature_tags,
      raise_currency_id,
      raised_offset,
      shows_goal,
      shows_only_challenge_specific_stories,
      shows_optional_fees,
      shows_optional_fundraising_fees,
      shows_progress,
      shows_raised,
      sponsor_partner_id,
      sponsored_color_scheme,
      sponsored_text,
      sponsored_text_color,
      start_date,
      suggest_public_challenge_on_completion,
      summary,
      supported_languages,
      supports_anonymous_p2p_donations,
      supports_individual_share_page,
      supports_manual_entry,
      supports_user_integrations,
      surpass_goal,
      target_amount_usd,
      ticket_receipt_email,
      ticket_terms_html,
      time_zone,
      type,
      unlock_code,
      users_must_select_group,
    } = data;

    return this.services.baseAPIv2.post('/admin/challenges/', {
      allows_user_created_groups,
      cover_opacity,
      cover_url,
      dash_color_main,
      dash_hero_image_url,
      dash_hero_text_color,
      dash_social_image_url,
      dash_stat_options,
      dashboard_bd_cta,
      default_individual_goal_target_amount,
      default_individual_goal_user_can_go_over_target_amount,
      disable_follow,
      disable_individual_leaderboards,
      disable_leaderboards,
      disable_other_stats,
      disable_search,
      disable_users_list,
      donation_goal_email,
      donation_notification_email,
      donation_receipt_email,
      donation_url,
      force_completion,
      has_app_invite_friends_text,
      hidden,
      hidden_on_open_challenge_listing,
      hide_tickets_remaining,
      i18n,
      individual_goal_type,
      individual_shows_goal,
      individual_shows_progress,
      individual_shows_raised,
      invite_friends_text,
      invite_url,
      long_summary,
      max_age_for_activity_import,
      max_users_per_group,
      name,
      non_profit_partner_id,
      owner_ids,
      p2p_currency,
      p2p_donation_target,
      p2p_force_completion,
      p2p_raised_offset,
      p2p_requires_address,
      p2p_shows_goal,
      p2p_shows_progress,
      p2p_shows_raised,
      p2p_thank_you_message,
      peer_to_peer_supported,
      pm_feature_tags,
      raise_currency_id,
      raised_offset,
      shows_goal,
      shows_only_challenge_specific_stories,
      shows_optional_fees,
      shows_optional_fundraising_fees,
      shows_progress,
      shows_raised,
      sponsor_partner_id,
      sponsored_color_scheme,
      sponsored_text,
      sponsored_text_color,
      start_date,
      suggest_public_challenge_on_completion,
      summary,
      supported_languages,
      supports_anonymous_p2p_donations,
      supports_individual_share_page,
      supports_manual_entry,
      supports_user_integrations,
      surpass_goal,
      target_amount_usd,
      ticket_receipt_email,
      ticket_terms_html,
      time_zone,
      type,
      unlock_code,
      users_must_select_group,
    });
  }

  update(id, data) {
    const {
      allows_user_created_groups,
      cover_opacity,
      cover_url,
      dash_color_main,
      dash_hero_image_url,
      dash_hero_text_color,
      dash_social_image_url,
      dash_stat_options,
      dashboard_bd_cta,
      default_individual_goal_target_amount,
      default_individual_goal_user_can_go_over_target_amount,
      disable_follow,
      disable_individual_leaderboards,
      disable_leaderboards,
      disable_other_stats,
      disable_search,
      disable_users_list,
      donation_goal_email,
      donation_notification_email,
      donation_receipt_email,
      donation_url,
      force_completion,
      has_app_invite_friends_text,
      hidden,
      hidden_on_open_challenge_listing,
      hide_tickets_remaining,
      i18n,
      individual_goal_type,
      individual_shows_goal,
      individual_shows_progress,
      individual_shows_raised,
      invite_friends_text,
      invite_url,
      long_summary,
      max_age_for_activity_import,
      max_users_per_group,
      name,
      non_profit_partner_id,
      owner_ids,
      p2p_currency,
      p2p_donation_target,
      p2p_force_completion,
      p2p_raised_offset,
      p2p_requires_address,
      p2p_shows_goal,
      p2p_shows_progress,
      p2p_shows_raised,
      p2p_thank_you_message,
      peer_to_peer_supported,
      pm_feature_tags,
      raise_currency_id,
      raised_offset,
      shows_goal,
      shows_only_challenge_specific_stories,
      shows_optional_fees,
      shows_optional_fundraising_fees,
      shows_progress,
      shows_raised,
      sponsor_partner_id,
      sponsored_color_scheme,
      sponsored_text,
      sponsored_text_color,
      start_date,
      suggest_public_challenge_on_completion,
      summary,
      supported_languages,
      supports_anonymous_p2p_donations,
      supports_individual_share_page,
      supports_manual_entry,
      supports_user_integrations,
      surpass_goal,
      target_amount_usd,
      ticket_receipt_email,
      ticket_terms_html,
      time_zone,
      type,
      unlock_code,
      users_must_select_group,
    } = data;

    return this.services.baseAPIv2.put(`/admin/challenges/${id}`, {
      allows_user_created_groups,
      cover_opacity,
      cover_url,
      dash_color_main,
      dash_hero_image_url,
      dash_hero_text_color,
      dash_social_image_url,
      dash_stat_options,
      dashboard_bd_cta,
      default_individual_goal_target_amount,
      default_individual_goal_user_can_go_over_target_amount,
      disable_follow,
      disable_individual_leaderboards,
      disable_leaderboards,
      disable_other_stats,
      disable_search,
      disable_users_list,
      donation_goal_email,
      donation_notification_email,
      donation_receipt_email,
      donation_url,
      force_completion,
      has_app_invite_friends_text,
      hidden,
      hidden_on_open_challenge_listing,
      hide_tickets_remaining,
      i18n,
      individual_goal_type,
      individual_shows_goal,
      individual_shows_progress,
      individual_shows_raised,
      invite_friends_text,
      invite_url,
      long_summary,
      max_age_for_activity_import,
      max_users_per_group,
      name,
      non_profit_partner_id,
      owner_ids,
      p2p_currency,
      p2p_donation_target,
      p2p_force_completion,
      p2p_raised_offset,
      p2p_requires_address,
      p2p_shows_goal,
      p2p_shows_progress,
      p2p_shows_raised,
      p2p_thank_you_message,
      peer_to_peer_supported,
      pm_feature_tags,
      raise_currency_id,
      raised_offset,
      shows_goal,
      shows_only_challenge_specific_stories,
      shows_optional_fees,
      shows_optional_fundraising_fees,
      shows_progress,
      shows_raised,
      sponsor_partner_id,
      sponsored_color_scheme,
      sponsored_text,
      sponsored_text_color,
      start_date,
      suggest_public_challenge_on_completion,
      summary,
      supported_languages,
      supports_anonymous_p2p_donations,
      supports_individual_share_page,
      supports_manual_entry,
      supports_user_integrations,
      surpass_goal,
      target_amount_usd,
      ticket_receipt_email,
      ticket_terms_html,
      time_zone,
      type,
      unlock_code,
      users_must_select_group,
    });
  }

  // archive(id) {
  //   return this.services.baseAPIv2.patch(`/admin/activity-types/${id}`);
  // }

  get(id) {
    return this.services.baseAPIv2.get(`/admin/challenges/${id}`, false);
  }

  copy(id, copyOptions) {
    return this.services.baseAPIv2.put(`/admin/challenges/${id}/copy`, copyOptions);
  }

  reset(id, resetSelections) {
    return this.services.baseAPIv2.put(`/admin/challenges/${id}/reset`, resetSelections);
  }

  getOverlays(challengeID) {
    return this.services.baseAPIv2.get(`/admin/overlays/${challengeID}`);
  }

  overlayStats(challengeID) {
    return this.services.baseAPIv2.get(`/admin/overlays/${challengeID}/overlay-stats`);
  }

  overlaysBatchUpdate({ challengeID, overlays, removedOverlayIDs }) {
    return this.services.baseAPIv2.post(`/admin/overlays/${challengeID}/batch-update`, {
      overlays,
      removedOverlayIDs,
    });
  }

  listIntegrations({ challengeID }) {
    return this.services.baseAPIv2.get(`/admin/challenges/${challengeID}/integrations`);
  }
}
