import ReactGA from 'react-ga';

if (window.location.href.includes('60a1fea05ccc73506bc000e7')) {
  ReactGA.initialize('UA-3023316-8', {
    debug: false,
    titleCase: false,
  });
} else {
  ReactGA.initialize('UA-90311002-4', {
    debug: false,
    titleCase: false,
  });
}

export default ReactGA;
