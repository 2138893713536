export default class IconsAdminAPI {
  constructor(services) {
    this.services = services;
    services.afterLoad(() => {
      // after load
    });
  }

  list() {
    return this.services.baseAPIv2.get('/admin/icons/');
  }
}
