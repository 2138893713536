import { analyticsMiddleware } from '@recruit-tech/react-redux-analytics';
import * as Sentry from '@sentry/react';
import { routerMiddleware } from 'connected-react-router';
import { enableES5, setAutoFreeze } from 'immer';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import { googleAnalytics } from 'config/analytics/reduxMiddleware';
import DebugConfig, { __DEV__ } from 'config/debug';
import { history } from 'config/history';
import LogRocket from 'config/log-rocket';
import Reactotron from 'config/reactotron';
import { applyMiddleware, compose, createStore } from 'redux';

import { rootSaga } from './sagas/root';
import createRootReducer from './reducers';

// disable immers' object autofreeze as it interferes with redux persist
setAutoFreeze(false);

// enable immer es5 support for older browsers such as IE 11
enableES5();

export default context => {
  /* ----------------- Redux Configuration ----------------- */
  const middleware = [];
  const enhancers = [];

  /* ------------- Connected React Router Config ------------- */
  // https://github.com/supasate/connected-react-router#step-2
  const reducers = createRootReducer(history);
  const routerMiddlewareWithHistory = routerMiddleware(history);
  middleware.push(routerMiddlewareWithHistory);

  /* ------------- react-redux-analytics Middleware ------------- */
  // https://github.com/recruit-tech/react-redux-analytics#getting-started
  middleware.push(analyticsMiddleware({ getLocationInStore: state => state.router.location }));

  /* ------------- React Router v4 ReactGA Middleware ------------- */
  // https://github.com/react-ga/react-ga/wiki/React-Router-v4-Redux-Middleware
  // let's add google analytics middleware here
  middleware.push(googleAnalytics);

  /* ------------- Redux Persist Configuration ------------- */
  // https://github.com/rt2zz/redux-persist#example
  // https://github.com/maxdeviant/redux-persist-transform-encrypt#usage
  const persistConfig = {
    key: 'root',
    storage,
    whitelist: [],
    stateReconciler: autoMergeLevel2,
    debug: __DEV__,
  };
  const reducer = persistReducer(persistConfig, reducers);

  /* ---------------- Redux Saga Middleware ---------------- */
  // https://redux-saga.js.org/docs/introduction/BeginnerTutorial.html
  // https://github.com/infinitered/reactotron/blob/master/docs/plugin-redux-saga.md#step-2---plugin-to-redux-saga
  const sagaMonitor = DebugConfig.useReactotron ? Reactotron.createSagaMonitor() : null;
  const sagaMiddleware = createSagaMiddleware({ sagaMonitor });
  sagaMiddleware.setContext(context);
  middleware.push(sagaMiddleware);

  /* --------------- Redux Logger Middleware --------------- */
  // https://github.com/LogRocket/redux-logger#usage
  if (DebugConfig.useReduxLogger) {
    const { createLogger } = require('redux-logger');
    const logger = createLogger({
      collapsed: (getState, action, logEntry) => !logEntry.error,
    });

    middleware.push(logger);
  }

  /* --------------- LogRocket Middleware should come LAST --------------- */
  if (DebugConfig.useLogRocket) middleware.push(LogRocket.reduxMiddleware());

  /* --------------- Redux Dev Tools Compose Function --------------- */
  // https://github.com/zalmoxisus/redux-devtools-extension#13-use-redux-devtools-extension-package-from-npm
  const composeEnhancers = DebugConfig.useReduxDevTools ? composeWithDevTools : compose;

  /* ---------------- Assemble Middleware ------------------- */
  enhancers.push(applyMiddleware(...middleware));
  // hook reactotron
  if (DebugConfig.useReactotron) enhancers.push(Reactotron.createEnhancer());

  // also throw in the Sentry Redux enhancer
  // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/
  enhancers.push(Sentry.createReduxEnhancer({}));

  /* ---------------- Create the Store now :) ------------------- */
  const store = createStore(reducer, composeEnhancers(...enhancers));
  const persistor = persistStore(store);

  /* ---------------- Start our Sagas ------------------- */
  sagaMiddleware.run(rootSaga);

  return { store, persistor, history };
};
