export default class CurrenciesAdminAPI {
  constructor(services) {
    this.services = services;
    services.afterLoad(() => {
      // after load
    });
  }

  list() {
    return this.services.baseAPIv2.get('/admin/currencies/');
  }

  get(id) {
    return this.services.baseAPIv2.get(`/admin/currencies/${id}`, false);
  }

  create(data) {
    const {
      name,
      iso_4217_currency_code,
      sprintf_format,
      usd_conversion_rate,
      cloudinary_sprintf_format,
      cloudinary_prefix_emoji_image_url,
      raised_verb,
      i18n,
    } = data;

    return this.services.baseAPIv2.post('/admin/currencies', {
      name,
      iso_4217_currency_code,
      sprintf_format,
      usd_conversion_rate,
      cloudinary_sprintf_format,
      cloudinary_prefix_emoji_image_url,
      raised_verb,
      i18n,
    });
  }

  update(id, data) {
    const {
      name,
      iso_4217_currency_code,
      sprintf_format,
      usd_conversion_rate,
      cloudinary_sprintf_format,
      cloudinary_prefix_emoji_image_url,
      raised_verb,
      i18n,
    } = data;

    return this.services.baseAPIv2.put(`/admin/currencies/${id}`, {
      name,
      iso_4217_currency_code,
      sprintf_format,
      usd_conversion_rate,
      cloudinary_sprintf_format,
      cloudinary_prefix_emoji_image_url,
      raised_verb,
      i18n,
    });
  }

  // archive(id) {
  //   return this.services.baseAPIv2.patch(`/admin/activity-types/${id}`);
  // }
  //
}
