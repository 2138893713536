export default class rolesAPI {
  constructor(services) {
    this.services = services;
    services.afterLoad(() => {});
  }

  list() {
    return this.services.baseAPIv2.get('/admin/roles', false);
  }

  create(data) {
    return this.services.baseAPIv2.post('/admin/roles', data);
  }

  read({ roleID }) {
    return this.services.baseAPIv2.get(`/admin/roles/${roleID}`);
  }

  update(roleID, data) {
    return this.services.baseAPIv2.put(`/admin/roles/${roleID}`, data);
  }

  delete(roleID) {
    return this.services.baseAPIv2.delete(`/admin/roles/${roleID}`);
  }
}
