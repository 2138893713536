export default class AchievementActivitiesAdminAPI {
  constructor(services) {
    this.services = services;
    services.afterLoad(() => {
      // after load
    });
  }

  list({ query, achievementID }) {
    return this.services.baseAPIv2.get('/admin/achievement-activities/', false, {
      match: query,
      achievement_id: achievementID,
    });
  }

  batchUpdate({ achievementID, removedAchievementActivities, newAchievementActivities }) {
    return this.services.baseAPIv2.post(
      `/admin/achievement-activities/batch-update/${achievementID}`,
      {
        removedAchievementActivities,
        newAchievementActivities,
      },
    );
  }
}
