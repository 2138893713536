export default class ChallengeIntegrationsAdminAPI {
  constructor(services) {
    this.services = services;

    services.afterLoad(() => {});
  }

  list() {
    return this.services.baseAPIv2.get('/admin/challenge-integrations');
  }
}
