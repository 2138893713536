export default class VideosAPI {
  constructor(services) {
    this.services = services;
    services.afterLoad(() => {
      // after load
    });
  }

  getChallengeVideo({ challengeVideoID }) {
    return this.services.baseAPIv2.get(`/videos/${challengeVideoID}`, false);
  }

  getVideoWatchState({ userID, challengeVideoID }) {
    return this.services.baseAPIv2.get(`/videos/${userID}/${challengeVideoID}`, false);
  }

  storeVideoWatchState({ userID, challengeVideoID, playheadPosition, increment }) {
    return this.services.baseAPIv2.put(`/videos/${userID}/${challengeVideoID}`, {
      playhead_position: playheadPosition,
      increment,
    });
  }
}
