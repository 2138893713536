export default class UploadApi {
  constructor(services) {
    this.services = services;
    services.afterLoad(() => {});
  }

  imageWithOverlay({ image, overlayUrl }) {
    return this.services.baseAPI.postMultiPartWithImage(
      'upload/image_with_overlay',
      {
        overlay_url: overlayUrl,
      },
      image,
    );
  }

  image({ image }) {
    return this.services.baseAPI.postMultiPartWithImage('upload/image', {}, image);
  }
}
