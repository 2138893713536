export default class ChallengeActivitiesAdminAPI {
  constructor(services) {
    this.services = services;
    services.afterLoad(() => {
      // after load
    });
  }

  list({ query, challengeID }) {
    return this.services.baseAPIv2.get('/admin/challenge-activities/', false, {
      match: query,
      challenge_id: challengeID,
    });
  }

  batchUpdate({
    challengeID,
    removedChallengeActivities,
    newChallengeActivities,
    updatedChallengeActivities,
  }) {
    return this.services.baseAPIv2.post(`/admin/challenge-activities/batch-update/${challengeID}`, {
      removedChallengeActivities,
      newChallengeActivities,
      updatedChallengeActivities,
    });
  }
}
