import produce from 'immer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { createActions, createReducer } from 'reduxsauce';
import { createSelector } from 'reselect';

import { APP_SECRET } from 'config/constants';
import { can, canUpdateChallengeWysiwyg } from 'utils';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions(
  {
    fetchProfileRequest: null,
    fetchProfileSuccess: ['user'],
    fetchProfileFailure: ['error'],

    loginRequest: ['identity', 'password', 'challengeID', 'groupIDs', 'ticketID', 'getProfile'],
    loginSuccess: ['sessionKey'],
    loginFailure: ['error'],

    facebookLoginRequest: ['accessToken', 'challengeID', 'groupIDs', 'ticketID'],
    facebookLoginSuccess: ['partialUserProfile', 'sessionKey'],
    facebookLoginFailure: ['error'],

    stravaLoginRequest: ['authCode', 'challengeID', 'groupIDs', 'ticketID'],
    stravaLoginSuccess: ['partialUserProfile', 'sessionKey'],
    stravaLoginFailure: ['error'],

    confirmProfileRequest: ['profile'],
    confirmProfileSuccess: null,
    confirmProfileFailure: ['error'],

    signupRequest: [
      'profile',
      'challengeID',
      'groupIDs',
      'ticketID',
      'marketingAccepted',
      'getProfile',
    ],
    signupSuccess: ['sessionKey'],
    signupFailure: ['error'],

    passwordResetRequest: ['identity'],
    passwordResetSuccess: null,
    passwordResetFailure: ['error'],

    updatePasswordRequest: ['token', 'password'],
    updatePasswordSuccess: ['user', 'sessionKey'],
    updatePasswordFailure: ['error'],

    logout: null,
  },
  { prefix: 'SESSION/' },
);

export const SessionTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  user: null,
  sessionKey: '',

  error: null,
  fetching: false,

  partialUserProfile: null,
};

/* ------------- Reducers ------------- */

// we're attempting to login/register the user
export const request = state =>
  produce(state, draft => {
    draft.fetching = true;
  });

// we've successfully logged/registered in the user
export const successSessionKey = (state, action) =>
  produce(state, draft => {
    const { sessionKey } = action;

    draft.fetching = false;
    draft.error = null;
    draft.sessionKey = sessionKey || '';
  });

export const successProfile = (state, action) =>
  produce(state, draft => {
    const { user } = action;

    if (draft.fetching === true) {
      draft.user = user || null;
    }
    draft.fetching = false;
    draft.error = null;
  });

export const successFB = (state, action) =>
  produce(state, draft => {
    const { partialUserProfile, sessionKey } = action;

    draft.fetching = false;
    draft.error = null;
    draft.sessionKey = sessionKey || '';
    draft.partialUserProfile = partialUserProfile || null;
  });

export const successStrava = (state, action) =>
  produce(state, draft => {
    const { partialUserProfile, sessionKey } = action;

    draft.fetching = false;
    draft.error = null;
    draft.sessionKey = sessionKey || '';
    draft.partialUserProfile = partialUserProfile || null;
  });

// we've had a problem authenticating the user
export const failure = (state, { error }) =>
  produce(state, draft => {
    draft.fetching = false;
    draft.error = error;
    // is this a string? (not an object)
    // if so we'll need a new reducer, ie: failureWithErrorFields
  });

// logout the user
export const logout = state =>
  produce(state, draft => {
    draft.user = null;
    draft.sessionKey = '';
    draft.error = null;
    draft.fetching = false;
    draft.partialUserProfile = null;
  });

/* ------------- Hookup Reducers To Types ------------- */

const encryptor = encryptTransform({ secretKey: APP_SECRET });
const persistConfig = {
  storage,
  key: 'session',
  transforms: [encryptor],
  whitelist: ['user', 'sessionKey'],
};

export const sessionReducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_PROFILE_REQUEST]: request,
  [Types.FETCH_PROFILE_SUCCESS]: successProfile,
  [Types.FETCH_PROFILE_FAILURE]: failure,

  [Types.LOGIN_REQUEST]: request,
  [Types.LOGIN_SUCCESS]: successSessionKey,
  [Types.LOGIN_FAILURE]: failure,

  [Types.FACEBOOK_LOGIN_REQUEST]: request,
  [Types.FACEBOOK_LOGIN_SUCCESS]: successFB,
  [Types.FACEBOOK_LOGIN_FAILURE]: failure,

  [Types.STRAVA_LOGIN_REQUEST]: request,
  [Types.STRAVA_LOGIN_SUCCESS]: successStrava,
  [Types.STRAVA_LOGIN_FAILURE]: failure,

  [Types.CONFIRM_PROFILE_REQUEST]: request,
  [Types.CONFIRM_PROFILE_FAILURE]: failure,

  [Types.SIGNUP_REQUEST]: request,
  [Types.SIGNUP_SUCCESS]: successSessionKey,
  [Types.SIGNUP_FAILURE]: failure,

  [Types.PASSWORD_RESET_REQUEST]: request,
  [Types.PASSWORD_RESET_SUCCESS]: successSessionKey,
  [Types.PASSWORD_RESET_FAILURE]: failure,

  [Types.UPDATE_PASSWORD_REQUEST]: request,
  [Types.UPDATE_PASSWORD_SUCCESS]: successSessionKey,
  [Types.UPDATE_PASSWORD_FAILURE]: failure,

  [Types.LOGOUT]: logout,
});

export const reducer = persistReducer(persistConfig, sessionReducer);

/* ------------- Selectors ------------- */

const session = state => state.session;
const getUser = createSelector(session, state => {
  const { user } = state;

  // attach permissions "can" utility helper
  if (user) {
    user.can = can;
    user.canUpdateChallengeWysiwyg = canUpdateChallengeWysiwyg;
  }

  return user;
});
const getSessionKey = createSelector(session, s => s.sessionKey);
const isSessionLoading = createSelector(session, s => s.fetching);
const getSessionError = createSelector(session, s => s.error);
const getPartialUserProfile = createSelector(session, s => s.partialUserProfile);

export const selectors = state => {
  return {
    getUser: getUser(state),
    getSessionKey: getSessionKey(state),
    getSessionError: getSessionError(state),
    isSessionLoading: isSessionLoading(state),
    getPartialUserProfile: getPartialUserProfile(state),
  };
};
