import * as app from './app';
import * as challenges from './challenges';
import * as dashboard from './dashboard';
import * as leaderboard from './leaderboard';
import * as p2pLeaderboard from './p2pLeaderboard';
import * as session from './session';
/* PLOP_INJECT_IMPORT */
import * as toasts from './toasts';

export default {
  ...app,
  ...challenges,
  ...dashboard,
  ...leaderboard,
  ...p2pLeaderboard,
  /* PLOP_INJECT_EXPORT */
  ...toasts,
  ...session,
};
