import { CLOUDINARY_CLOUD_NAME } from 'config/constants';

export function matchesCloudinary(uri) {
  if (!uri) return null;

  // try to match the URI as a cloudinary URI
  return uri.match(/https?:\/\/res.cloudinary.com\/(.*)\/image\/upload\/(.*)$/);
}

function generateNewCloudinaryURI({ uri, width, height, extraParams, transparency = true }) {
  if (matchesCloudinary(uri) == null) {
    /* eslint-disable-next-line */
    // console.log(`imageUrIWithSize: Couldn't parse ${uri} to set it's size for cloudinary. Are you sure this is a cloudinary url?`);
    return uri;
  }

  const bucketId = matchesCloudinary(uri)[1];
  const imageId = matchesCloudinary(uri)[2];

  const calcWidth = parseInt(width, 10);
  const calcHeight = parseInt(height, 10);

  let transform = `/${extraParams},w_${calcWidth}`;
  if (calcHeight && calcHeight > 0) transform += `,h_${calcHeight}`;
  transform += transparency ? ',f_png' : ',f_jpg';

  const cUri = `https://res.cloudinary.com/${bucketId}/image/upload${transform}/${imageId}`;

  return cUri;
}

export function imageUriWithSize({ uri, width, height }) {
  return generateNewCloudinaryURI({
    uri,
    width,
    height,
    extraParams: 'c_fill,g_face:center',
  });
}

export function optimizeImage({ uri, width, transparency }) {
  return generateNewCloudinaryURI({
    uri,
    width,
    extraParams: 'q_auto,c_scale,dpr_auto',
    transparency,
  });
}

export function optimizePageEditorImage(uri, transparency) {
  return optimizeImage({ uri, width: 1000, transparency });
}

export async function uploadImageToCloudinary({ file }) {
  const formData = new FormData();

  formData.append('file', file);
  formData.append('upload_preset', 'page_editor');

  const options = {
    method: 'POST',
    body: formData,
  };

  try {
    const response = await fetch(
      `https://api.Cloudinary.com/v1_1/${CLOUDINARY_CLOUD_NAME}/image/upload`,
      options,
    ).then(res => res.json());

    return response.secure_url;
  } catch (error) {
    throw new Error('There was a problem uploading image to cloudinary');
  }
}
