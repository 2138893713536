import { createGlobalStyle } from 'styled-components';

import theme from '../base';

const { primary } = theme.colors;

export const GlobalStyle = createGlobalStyle`
  /* Global Styles Here */
  body {
    font-family: "Lato", sans-serif !important;
    font-weight: 400 !important;
  }

  .ory-toolbar-drawer {
    font-size: 22px;

    ul li, input, ul li div span {
      font-size: 14px;
    }

    ul li div p {
      font-size: 12px;
    }
  }

  .ory-toolbar-item svg {
    min-width: 22px;
    min-height: 22px;
  }

  .react-page-plugin-drawer {
    font-size: 22px;

    ul li, input, ul li div span {
      font-size: 14px;
    }

    ul li div p {
      font-size: 12px;
    }
  }

  .react-page-plugin-drawer-item svg {
    min-width: 22px;
    min-height: 22px;
  }


  /* Flatpickr Overrides */
  .flatpickr-calendar.arrowTop:after {
    border-bottom-color: ${primary};
  }
  .flatpickr-calendar.arrowBottom:after {
    border-top-color: ${primary};
  }
  .flatpickr-months .flatpickr-month {
    background: ${primary};
  }
  .flatpickr-current-month .flatpickr-monthDropdown-months {
    background: ${primary};
  }
  .flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
    background-color: ${primary};
  }
  .flatpickr-weekdays {
    background: ${primary};
  }
  span.flatpickr-weekday {
    background: ${primary};
  }
  .flatpickr-day.selected,
  .flatpickr-day.startRange,
  .flatpickr-day.endRange,
  .flatpickr-day.selected.inRange,
  .flatpickr-day.startRange.inRange,
  .flatpickr-day.endRange.inRange,
  .flatpickr-day.selected:focus,
  .flatpickr-day.startRange:focus,
  .flatpickr-day.endRange:focus,
  .flatpickr-day.selected:hover,
  .flatpickr-day.startRange:hover,
  .flatpickr-day.endRange:hover,
  .flatpickr-day.selected.prevMonthDay,
  .flatpickr-day.startRange.prevMonthDay,
  .flatpickr-day.endRange.prevMonthDay,
  .flatpickr-day.selected.nextMonthDay,
  .flatpickr-day.startRange.nextMonthDay,
  .flatpickr-day.endRange.nextMonthDay {
    background: ${primary};
    border-color: ${primary};
  }
  .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
  .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
  .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
    -webkit-box-shadow: -10px 0 0 ${primary};
            box-shadow: -10px 0 0 ${primary};
  }
  .flatpickr-day.week.selected {
    -webkit-box-shadow: -5px 0 0 ${primary};, 5px 0 0 ${primary};
            box-shadow: -5px 0 0 ${primary};, 5px 0 0 ${primary};
  }

  /* Primereact Overrides */
  .p-component, .p-component * {
    box-sizing: inherit !important;
  }
`;
