import { ApolloClient, ApolloLink, concat, HttpLink } from '@apollo/client';
import i18n from 'i18next';

import { getBaseURLs } from 'utils';

import { cache, queries as graphQLQueries } from './graphql';

export default class BaseAPI {
  constructor(services) {
    services.afterLoad(() => {});

    this.services = services;
    this.graphql = {};
    this.graphql.queries = graphQLQueries;
    this.graphql.client = this.getClient();
  }

  get sessionKey() {
    return this.services.sessionKey;
  }

  getClient() {
    const baseURL = getBaseURLs().http;
    const httpLink = new HttpLink({ uri: `${baseURL}/graphql` });
    const getCurrentLng = i18n.language || window.localStorage.i18nextLng || 'en';

    const authMiddleware = new ApolloLink((operation, forward) => {
      operation.setContext({
        headers: {
          'Accept-Language': getCurrentLng,
          'X-Atlas-Session-Key': `Bearer ${this.sessionKey}`,
        },
      });

      return forward(operation);
    });

    const graphqlClient = new ApolloClient({
      cache,
      link: concat(authMiddleware, httpLink),
    });

    return graphqlClient;
  }
}
