import base from './base';

const theme = {
  ...base,
};

theme.colors = {
  ...theme.colors,

  // we should name the variables below correctly in the base theme :)
  black: '#ffffff',
  white: '#000000',
};

export default theme;
