import { SLACK_APP_REDIRECT_URI_HOST } from 'config/constants';

const baseURL = `${SLACK_APP_REDIRECT_URI_HOST}`;

export default class SlackAdminAPI {
  constructor(services) {
    this.services = services;

    services.afterLoad(() => {});
  }

  disconnectChallenge({ challengeID }) {
    return this.services.baseAPIv2.get(
      `/slack/challenges/${challengeID}/disconnect`,
      false,
      undefined,
      baseURL,
    );
  }

  listChallengeChannels({ challengeID }) {
    return this.services.baseAPIv2.get(
      `/slack/challenges/${challengeID}/conversations`,
      false,
      undefined,
      baseURL,
    );
  }

  triggerChallengeJobNow({ challengeID, job, channel, style }) {
    return this.services.baseAPIv2.get(
      `/slack/challenges/${challengeID}/jobs/${style || job}/${channel}`,
      false,
      undefined,
      baseURL,
    );
  }

  saveChallengeSettings({ challengeID, channel, features, timezone }) {
    return this.services.baseAPIv2.post(
      `/slack/challenges/${challengeID}/jobs`,
      { channel, features, challengeID, timezone },
      false,
      {},
      baseURL,
    );
  }
}
