import { decrypt, encrypt } from './AES';

/**
 * encrypt query string data given an object
 * @param  {object} data - the object to encrypt
 * @return {string}      - the string to be used in the url
 */
export function encryptQS(data) {
  // Encrypt
  return encrypt(JSON.stringify(data));
}

/**
 * decrypt query string
 * @param  {string} qs - the encrypted query string
 * @return {object}    - the original object
 */
export function decryptQS(qs) {
  // Decrypt
  return JSON.parse(decrypt(qs));
}
