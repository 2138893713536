import { call, cancel, delay, getContext, put, race, select, take } from 'redux-saga/effects';

import { AppTypes, selectors as appSelectors } from '../reducers/app';
import ChallengesActions from '../reducers/challenges';
import SessionActions from '../reducers/session';
import ToastsActions from '../reducers/toasts';

export function* fetchChallengeInfo(action) {
  const { challengeID, distanceUnits } = action;
  const services = yield getContext('services');
  const response = yield services.goBoardAPI.info({ challengeID, distanceUnits });

  // success?
  if (response.ok) {
    yield put(ChallengesActions.fetchChallengeInfoSuccess(response.data.data));
  } else {
    yield put(ChallengesActions.fetchChallengeInfoFailure(response.originalError));

    // if this saga fails, cancel so that onComponentDidMount saga fails
    yield cancel(action);
  }
}

export function* updateChallengeInfoPeriodically(action) {
  const { challengeID } = action;

  while (true) {
    // update every 30 seconds or if the distance units get changed
    yield race({
      updated: take(AppTypes.SET_DISTANCE_UNITS),
      timeout: delay(30000),
    });

    yield call(updateChallengeInfo, { challengeID });
  }
}

export function* updateChallengeInfo(action) {
  const { challengeID } = action;
  const { getDistanceUnits: distanceUnits } = appSelectors(yield select());

  yield call(fetchChallengeInfo, { challengeID, distanceUnits });
}

export function* fetchChallengeStories({ challengeID }) {
  const services = yield getContext('services');
  const {
    data: { data: entries },
  } = yield call([services.goBoardAPI, 'challengesStoriesList'], { challengeID });

  yield put(ChallengesActions.fetchChallengeStoriesSuccess(challengeID, entries));
}

export function* fetchChallengePartial(action) {
  const { challengeID, distanceUnits } = action;
  const services = yield getContext('services');
  const response = yield services.challengesAPIv2.partial({ challengeID, distanceUnits });

  if (response.ok) {
    yield put(ChallengesActions.fetchChallengeInfoSuccess(response.data.data));
  } else {
    yield put(ChallengesActions.fetchChallengeInfoFailure(response.originalError));
  }
}

export function* joinChallenge(action) {
  const { challengeID, groupIDs } = action;
  const services = yield getContext('services');
  const response = yield services.challengesAPI.join2({ challengeID, groupIDs });

  if (response.ok) {
    if (response.data.success) {
      yield put(ChallengesActions.joinChallengeSuccess('joined'));
      yield put(SessionActions.fetchProfileRequest());
    } else {
      yield put(ChallengesActions.joinChallengeSuccess('failed'));
    }
  } else {
    yield put(ChallengesActions.joinChallengeFailure(response.originalError));
  }
}

export function* createChallengeGroup(action) {
  const { challengeID, name, imageURL, groupListID, ownerID } = action;
  const services = yield getContext('services');
  const response = yield services.challengesAPIv2.createGroup({
    challengeID,
    name,
    imageURL,
    groupListID,
    ownerID,
  });

  if (response.ok) {
    yield put(ChallengesActions.createChallengeGroupSuccess());
    yield put(
      ToastsActions.addToast({
        type: 'success',
        text: `${name} has been created`,
      }),
    );
    yield put(ChallengesActions.fetchChallengeGroupListsRequest(challengeID, ownerID));
  } else {
    yield put(ChallengesActions.createChallengeGroupFailure(response.originalError));
  }
}

export function* deleteChallengeGroup(action) {
  const { challengeID, groupID, ownerID, name } = action;
  const services = yield getContext('services');
  const response = yield services.challengesAPIv2.deleteGroup({ challengeID, groupID, ownerID });

  if (response.ok) {
    yield put(ChallengesActions.deleteChallengeGroupSuccess());
    yield put(
      ToastsActions.addToast({
        type: 'success',
        text: `${name} has been deleted`,
      }),
    );
    yield put(ChallengesActions.fetchChallengeGroupListsRequest(challengeID, ownerID));
  } else {
    yield put(ChallengesActions.deleteChallengeGroupFailure(response.originalError));
  }
}

export function* fetchChallengeGroupLists(action) {
  const { challengeID, distanceUnits, ownerID } = action;
  const services = yield getContext('services');
  const response = yield services.challengesAPIv2.groupLists({
    challengeID,
    distanceUnits,
    ownerID,
  });

  if (response.ok) {
    yield put(ChallengesActions.fetchChallengeGroupListsSuccess(response.data.data));
  } else {
    yield put(ChallengesActions.fetchChallengeGroupListsFailure(response.originalError));
  }
}

export function* fetchChallengeWysiwygContent(action) {
  const { challengeID } = action;
  const services = yield getContext('services');
  const response = yield services.challengesAPIv2.getWysiwygContent({ challengeID });

  if (response.ok) {
    yield put(ChallengesActions.fetchChallengeWysiwygContentSuccess(response.data.data));
  } else {
    // suppress wysiwyg fetch errors. a new challenge does not always have wysiwyg content.
    // yield put(ChallengesActions.fetchChallengeWysiwygContentFailure(response.originalError));
  }
}

export function* saveChallengeWysiwygContent(action) {
  const { challengeID, content } = action;
  const services = yield getContext('services');
  const response = yield services.challengesAPIv2.updateWysiwygContent({ challengeID, content });

  if (response.ok) {
    yield put(ChallengesActions.saveChallengeWysiwygContentSuccess(response.data.data));
    yield put(
      ToastsActions.addToast({
        type: 'success',
        text: 'Your work has been saved',
      }),
    );
  } else {
    const res = yield services.challengesAPIv2.createWysiwygContent({ challengeID, content });

    if (res.ok) {
      yield put(ChallengesActions.saveChallengeWysiwygContentSuccess(res.data.data));
      yield put(
        ToastsActions.addToast({
          type: 'success',
          text: 'Your work has been saved',
        }),
      );
    } else {
      yield put(ChallengesActions.saveChallengeWysiwygContentFailure(res.originalError));
    }
  }
}

export function* fetchChallengeTickets(action) {
  const { challengeID } = action;
  const services = yield getContext('services');
  const response = yield services.challengesAPIv2.tickets({ challengeID });

  if (response.ok) {
    yield put(
      ChallengesActions.fetchChallengeTicketsSuccess(response.data.data, response.data.meta),
    );
  } else {
    yield put(ChallengesActions.fetchChallengeTicketsFailure(response.originalError));
  }
}

export function* buyChallengeTickets(action) {
  const { challengeID, data } = action;
  const services = yield getContext('services');
  const response = yield services.challengesAPIv2.buyTickets({ challengeID, data });

  if (response.ok) {
    yield put(
      ChallengesActions.buyChallengeTicketsSuccess(
        response.data.data,
        response.data.meta.skip_payment,
      ),
    );
  } else {
    yield put(ChallengesActions.buyChallengeTicketsFailure(response.originalError));
  }
}

export function* validateChallengeTicket(action) {
  const { challengeID, ticketID, unlockCode } = action;
  const services = yield getContext('services');
  const endpoint = ticketID ? 'challengeTicket' : 'verifyUnlockCode';
  const response = yield services.challengesAPIv2[endpoint]({
    ticketID,
    challengeID,
    unlockCode,
  });

  if (response.ok) {
    yield put(ChallengesActions.validateChallengeTicketSuccess(response.data.data));
  } else {
    yield put(ChallengesActions.validateChallengeTicketSuccess({ valid: false, claimed: false }));
  }
}

export function* fetchChallengeFeaturedActivityTypes(action) {
  const { challengeID } = action;
  const services = yield getContext('services');
  const response = yield services.challengesAPIv2.featuredActivityTypes({ challengeID });

  if (response.ok) {
    yield put(
      ChallengesActions.fetchChallengeFeaturedActivityTypesSuccess({
        ...response.data.data,
        token: response.headers['x-atlas-csrf-token'],
      }),
    );
  } else {
    yield put(ChallengesActions.fetchChallengeFeaturedActivityTypesFailure(response.originalError));
  }
}

export function* submitChallengeActivity(action) {
  const { meters, seconds, units, activityType, token } = action;
  const services = yield getContext('services');
  const response = yield services.activityAPI.submit({
    meters,
    seconds,
    units,
    activityType,
    token,
  });

  if (response.ok) {
    if (response.status === 207) {
      response.data.error.message = 'You are over the manual daily entry limit';
      yield put(ChallengesActions.submitChallengeActivityFailure(response.data));
    } else {
      yield put(ChallengesActions.submitChallengeActivitySuccess());
    }
  } else {
    yield put(ChallengesActions.submitChallengeActivityFailure(response.originalError));
  }
}

export function* verifyChallengeCoupon(action) {
  const { challengeID, couponCode } = action;
  const services = yield getContext('services');
  const response = yield services.challengesAPIv2.verifyCoupon({ challengeID, couponCode });

  if (response.ok) {
    yield put(ChallengesActions.verifyChallengeCouponSuccess(response.data.data));
  } else {
    yield put(ChallengesActions.verifyChallengeCouponFailure(response.originalError));
  }
}

export function* makeChallengeDonation(action) {
  const { challengeID, data } = action;
  const services = yield getContext('services');
  const response = yield services.challengesAPIv2.makeDonation({ challengeID, data });

  if (response.ok) {
    yield put(ChallengesActions.makeChallengeDonationSuccess(response.data.data));
  } else {
    yield put(ChallengesActions.makeChallengeDonationFailure(response.originalError));
  }
}
