import { USE_REACTOTRON, USE_REDUX_DEV_TOOLS, USE_REDUX_LOGGER } from './constants';

export const __DEV__ = process.env.NODE_ENV === 'development';

export default {
  useStagingAPIBase: true,
  useLocalhostV1APIBase: false,
  useLocalhostV2APIBase: false,
  useReactotron: !!Number(USE_REACTOTRON) || false,
  useReduxLogger: !!Number(USE_REDUX_LOGGER) || false,
  useReduxDevTools: !!Number(USE_REDUX_DEV_TOOLS) || __DEV__,
  useLogRocket: false,
  debugSentry: __DEV__,
};
