export default class ticketsAPI {
  constructor(services) {
    this.services = services;
    services.afterLoad(() => {
      // after load
    });
  }

  list() {
    return this.services.baseAPIv2.get('/admin/tickets/');
  }

  create(data) {
    const {
      title,
      challenge_id,
      max_quantity,
      currency_id,
      value,
      hidden,
      sold_out,
      description,
      i18n,
      sub_tickets,
      params,
    } = data;

    return this.services.baseAPIv2.post('/admin/tickets/', {
      title,
      challenge_id,
      max_quantity,
      currency_id,
      value,
      hidden,
      sold_out,
      description,
      i18n,
      sub_tickets,
      params,
    });
  }

  update(id, data) {
    const {
      title,
      challenge_id,
      max_quantity,
      currency_id,
      value,
      hidden,
      sold_out,
      description,
      i18n,
      sub_tickets,
      params,
    } = data;

    return this.services.baseAPIv2.put(`/admin/tickets/${id}`, {
      title,
      challenge_id,
      max_quantity,
      currency_id,
      value,
      hidden,
      sold_out,
      description,
      i18n,
      sub_tickets,
      params,
    });
  }

  // archive(id) {
  //   return this.services.baseAPIv2.patch(`/admin/activity-types/${id}`);
  // }

  get(id) {
    return this.services.baseAPIv2.get(`/admin/tickets/${id}`, false);
  }

  getByChallenge(id) {
    return this.services.baseAPIv2.get(`/admin/tickets/by-challenge/${id}`, false);
  }
}
