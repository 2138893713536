import React from 'react';

// https://github.com/getsentry/sentry-javascript/issues/4439
import './sentry';
import './i18n';
// import './log-rocket';
import './reactotron';
import './analytics';
import './datadogRUM';

import { __DEV__ } from './debug';

// this is a function that monkey patches React and notifies us
// in the console when potentially unnecessary re-renders occur
if (__DEV__) {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');

  whyDidYouRender(React, {
    // onlyLogs: true,
    titleColor: 'green',
    diffNameColor: 'aqua',
    trackAllPureComponents: false, // switching this off. can get annoying at times
  });
}
