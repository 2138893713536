export default class ActivityTypesAPI {
  constructor(services) {
    this.services = services;
    services.afterLoad(() => {
      // after load
    });
  }

  list({ query, challengeID }) {
    return this.services.baseAPIv2.get('/admin/activity-types/', false, {
      match: query,
      challenge_id: challengeID,
    });
  }

  create(data) {
    const {
      name,
      image_url,
      raise_with,
      max_gps_speed,
      meters,
      raise_rate,
      activityClass,
      manual_entry_limit,
      seconds,
      supports_manual_logging,
      supports_atlas_tracking,
      i18n,
      importable_as_activity_tag,
      show_on_client_list,
      category_tags,
    } = data;

    return this.services.baseAPIv2.post('/admin/activity-types', {
      name,
      class: activityClass,
      image_url,
      seconds,
      meters,
      raise_with,
      manual_entry_limit,
      max_gps_speed,
      raise_rate,
      supports_manual_logging,
      supports_atlas_tracking,
      i18n,
      importable_as_activity_tag,
      show_on_client_list,
      category_tags,
    });
  }

  update(id, data) {
    const {
      activityClass,
      image_url,
      manual_entry_limit,
      max_gps_speed,
      raise_rate,
      supports_manual_logging,
      supports_atlas_tracking,
      name,
      i18n,
      importable_as_activity_tag,
      show_on_client_list,
      category_tags,
    } = data;

    return this.services.baseAPIv2.put(`/admin/activity-types/${id}`, {
      class: activityClass,
      name,
      image_url,
      raise_rate,
      manual_entry_limit,
      max_gps_speed,
      supports_manual_logging,
      supports_atlas_tracking,
      i18n,
      importable_as_activity_tag,
      show_on_client_list,
      category_tags,
    });
  }

  archive(id) {
    return this.services.baseAPIv2.patch(`/admin/activity-types/${id}`);
  }

  get(id) {
    return this.services.baseAPIv2.get(`/admin/activity-types/${id}`, false);
  }
}
