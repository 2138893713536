import { InMemoryCache } from '@apollo/client';

import notifications from './notificationsPolicy';

export default new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        notifications: {
          keyArgs: false,
        },
      },
    },
    User: {
      fields: {
        notifications: {
          keyArgs: false,
        },
      },
    },
    NotificationList: notifications,
  },
});
