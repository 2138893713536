import converter from 'length-distance-converter';
import v from 'voca';

export function milesToKm(val) {
  // return val * 1.60934;
  // return length(val, 'mi').to('km').toPrecision(4).getValue();
  return converter.milesToKm(val).toFixed(4);
}

export function kmToMiles(val) {
  // return val * 0.621371;
  // return length(val, 'km').to('mi').toPrecision(4).getValue();
  return converter.kmToMiles(val).toFixed(4);
}

export function metersToMiles(val) {
  // return val * 0.000621371;
  // return length(val, 'm').to('mi').toPrecision(4).getValue();
  return converter.kmToMiles(val / 1000.0).toFixed(4);
}

export function milesToMeters(val) {
  // return val * 1609.34;
  // return length(val, 'mi').to('m').toPrecision(4).getValue();
  return (converter.milesToKm(val) * 1000.0).toFixed(4);
}

export function kmToMeters(val) {
  // return val * 1000.0;
  // return length(val, 'km').to('m').toPrecision(4).getValue();
  return (val * 1000.0).toFixed(4);
}

export function metersToKm(val) {
  // return val / 1000.0;
  // return length(val, 'm').to('km').toPrecision(4).getValue();
  return (val / 1000.0).toFixed(4);
}

// distance units is either 'miles' or 'km'. Returns
// a sane distance display like 2.21km
export const formatDistance = (meters, units = 'miles') => {
  let outDist = meters;

  if (units === 'km') {
    outDist = metersToKm(meters);
  } else {
    outDist = metersToMiles(meters);
  }

  return v.sprintf('%.2f', outDist);
};

// https://stackoverflow.com/a/31615643/5400873
export const getNumberWithOrdinal = n => {
  const s = ['th', 'st', 'nd', 'rd'];
  const val = n % 100;

  return n + (s[(val - 20) % 10] || s[val] || s[0]);
};
