import mapHelpers from 'redux/mapHelpers';

// fixtures API for mocking and testing
import fixturesAPI from './API/fixtures';
// version 1 API
import AuthAPI from './API/v1/Auth';
import BaseAPI from './API/v1/Base';
import ChallengesAPI from './API/v1/Challenges';
import DashAPI from './API/v1/Dash';
import ProfileAPI from './API/v1/Profile';
import UploadAPI from './API/v1/Upload';
// version 2 API
import ActivityAPI from './API/v2/Activity';
import ActivityTypesAPI from './API/v2/ActivityTypes';
// version 2 API (admin)
import AchievementActivitiesAdminAPI from './API/v2/admin/AchievementActivities';
import AchievementsAdminAPI from './API/v2/admin/Achievements';
import ActivityTypesAdminAPI from './API/v2/admin/ActivityTypes';
import ActivityVideosAdminAPI from './API/v2/admin/ActivityVideos';
import ChallengeActivitiesAdminAPI from './API/v2/admin/ChallengeActivities';
import ChallengeIntegrationsAdminAPI from './API/v2/admin/ChallengeIntegrations';
import ChallengeMapsAPI from './API/v2/admin/ChallengeMaps';
import ChallengesAdminAPI from './API/v2/admin/Challenges';
import ChallengeVideosAdminAPI from './API/v2/admin/ChallengeVideos';
import CouponsAPI from './API/v2/admin/Coupons';
import CurrenciesAdminAPI from './API/v2/admin/Currencies';
import FeedStoriesAPI from './API/v2/admin/FeedStories';
import GroupListsAdminAPI from './API/v2/admin/GroupLists';
import GroupsAdminAPI from './API/v2/admin/Groups';
import IconsAdminAPI from './API/v2/admin/Icons';
import InfraAdminAPI from './API/v2/admin/Infra';
import PartnersAPI from './API/v2/admin/Partners';
import ReportsAPI from './API/v2/admin/Reports';
import RolesAPI from './API/v2/admin/Roles';
import SlackAdminAPI from './API/v2/admin/Slack';
import SurveysAdminAPI from './API/v2/admin/Surveys';
import TicketsAdminAPI from './API/v2/admin/Tickets';
import TrackingAPI from './API/v2/admin/Tracking';
import UsersAdminAPI from './API/v2/admin/Users';
import AuthAPIv2 from './API/v2/Auth';
import BaseAPIv2 from './API/v2/Base';
import ChallengesAPIv2 from './API/v2/Challenges';
import CurrencyAPI from './API/v2/Currency';
import GoBoardAPI from './API/v2/GoBoard';
import GroupsAPI from './API/v2/Groups';
import PermissionsAPI from './API/v2/Permissions';
import TicketsAPI from './API/v2/Tickets';
import VideosAPI from './API/v2/Videos';
// version 3 API
import BaseAPIv3 from './API/v3';

const LoadState = {
  NotLoaded: 'not_loaded',
  Loaded: 'loaded',
};

export default class Services {
  afterLoad(cb) {
    // We're already loaded, execute
    if (this.state === LoadState.Loaded) {
      cb(this);
      return;
    }

    // Add to queue
    this.afterLoadHooks.push(cb);
  }

  constructor(context) {
    this.state = LoadState.NotLoaded;
    this.afterLoadHooks = [];

    this.context = context;

    // v1 API
    this.baseAPI = new BaseAPI(this);
    this.dashAPI = new DashAPI(this);
    this.authAPI = new AuthAPI(this);
    this.profileAPI = new ProfileAPI(this);
    this.uploadAPI = new UploadAPI(this);
    this.challengesAPI = new ChallengesAPI(this);

    // fixtures API
    this.fixturesAPI = fixturesAPI;

    // v2 API
    this.activityAPI = new ActivityAPI(this);
    this.activityTypesAPI = new ActivityTypesAPI(this);
    this.authAPIv2 = new AuthAPIv2(this);
    this.baseAPIv2 = new BaseAPIv2(this);
    this.challengesAPIv2 = new ChallengesAPIv2(this);
    this.currencyAPI = new CurrencyAPI(this);
    this.goBoardAPI = new GoBoardAPI(this);
    this.groupsAPI = new GroupsAPI(this);
    this.permissionsAPI = new PermissionsAPI(this);
    this.ticketsAPI = new TicketsAPI(this);
    // v2 API (admin)
    this.achievementActivitiesAdminAPI = new AchievementActivitiesAdminAPI(this);
    this.achievementsAdminAPI = new AchievementsAdminAPI(this);
    this.activityTypesAdminAPI = new ActivityTypesAdminAPI(this);
    this.activityVideosAdminAPI = new ActivityVideosAdminAPI(this);
    this.challengeActivitiesAdminAPI = new ChallengeActivitiesAdminAPI(this);
    this.challengesAdminAPI = new ChallengesAdminAPI(this);
    this.challengeMapsAPI = new ChallengeMapsAPI(this);
    this.challengeIntegrationsAdminAPI = new ChallengeIntegrationsAdminAPI(this);
    this.challengeVideosAdminAPI = new ChallengeVideosAdminAPI(this);
    this.couponsAPI = new CouponsAPI(this);
    this.currenciesAdminAPI = new CurrenciesAdminAPI(this);
    this.feedStoriesAPI = new FeedStoriesAPI(this);
    this.groupListsAdminAPI = new GroupListsAdminAPI(this);
    this.groupsAdminAPI = new GroupsAdminAPI(this);
    this.iconsAdminAPI = new IconsAdminAPI(this);
    this.infraAdminAPI = new InfraAdminAPI(this);
    this.partnersAPI = new PartnersAPI(this);
    this.reportsAPI = new ReportsAPI(this);
    this.rolesAPI = new RolesAPI(this);
    this.surveysAdminAPI = new SurveysAdminAPI(this);
    this.ticketsAdminAPI = new TicketsAdminAPI(this);
    this.trackingAPI = new TrackingAPI(this);
    this.usersAdminAPI = new UsersAdminAPI(this);
    this.videosAPI = new VideosAPI(this);
    this.slackAdminAPI = new SlackAdminAPI(this);

    // v3 API
    this.baseAPIv3 = new BaseAPIv3(this);

    this.state = LoadState.Loaded;
    this.afterLoadHooks.forEach(e => e());
  }

  get store() {
    return this.context.store;
  }

  dispatch(action) {
    this.store.dispatch(action);
  }

  get sessionKey() {
    return mapHelpers.selectors(this.store.getState()).getSessionKey;
  }
}
