import produce from 'immer';
import isEqual from 'lodash/isEqual';
import { createActions, createReducer } from 'reduxsauce';
import { createSelector } from 'reselect';

import createToast from 'utils/factories/createToast';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions(
  {
    addToast: ['options'],
    removeToast: ['id'],
  },
  { prefix: 'TOASTS/' },
);

export const ToastsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  queue: [],
};

/* ------------- Reducers ------------- */

// add a toast to our queue
export const add = (state, action) =>
  produce(state, draft => {
    const { options } = action;
    let addToQueue = true;

    // if the toast already exists, don't add
    draft.queue.forEach(toast => {
      if (isEqual({ type: toast.type, text: toast.text }, options)) addToQueue = false;
    });

    if (addToQueue) draft.queue = [...draft.queue, createToast(options)];
  });

// remove a toast from our queue
export const remove = (state, action) =>
  produce(state, draft => {
    const { id } = action;

    draft.queue = draft.queue.filter(toast => toast.id !== id);
  });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD_TOAST]: add,
  [Types.REMOVE_TOAST]: remove,
});

/* ------------- Selectors ------------- */

const toasts = state => state.toasts;
const getToasts = createSelector(toasts, t => t.queue);

export const selectors = state => {
  return {
    getToasts: getToasts(state),
  };
};
