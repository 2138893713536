import produce from 'immer';
import { createActions, createReducer } from 'reduxsauce';
import { createSelector } from 'reselect';

import { dark, light } from 'theme';

const THEMES = { light, dark };

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions(
  {
    changeActive: ['type'],
  },
  { prefix: 'THEME/' },
);

export const ThemeTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  active: light,
};

/* ------------- Reducers ------------- */

// we're attempting to change the current avtive theme
export const changeTheme = (state, type) => {
  return produce(state, draft => {
    draft.active = THEMES[type] || state.active;
  });
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  'THEME/CHANGE_ACTIVE': changeTheme,
});

/* ------------- Selectors ------------- */

const theme = state => state.theme;
const getActiveTheme = createSelector(theme, t => t.active);

export const selectors = state => {
  return {
    getActiveTheme: getActiveTheme(state),
  };
};
