import { getContext, put } from 'redux-saga/effects';

import P2PLeaderboardActions from '../reducers/p2pLeaderboard';

export function* fetchP2PLeaderboard(action) {
  const { challengeID } = action;
  const services = yield getContext('services');
  const response = yield services.challengesAPIv2.P2PIndividualsLeaderboard({ challengeID });

  if (response.ok) {
    const {
      data: { data },
    } = response;
    const adjustedEntries = data.map(e => {
      return {
        image_url: e.avatar_url.thumbnail,
        display_name: e.username,
        raised_description: e.raised_description,
        username: e.username,
        id: e.id,
      };
    });

    yield put(P2PLeaderboardActions.fetchP2PLeaderboardSuccess('individuals', adjustedEntries));
  } else {
    yield put(P2PLeaderboardActions.fetchP2PLeaderboardFailure(response));
  }
}

export function* fetchP2PTeamsLeaderboard(action) {
  const { challengeID } = action;
  const services = yield getContext('services');
  const response = yield services.challengesAPIv2.P2PTeamsLeaderboard({ challengeID });

  if (response.ok) {
    const {
      data: { data },
    } = response;
    const adjustedEntries = data.map(e => {
      return {
        image_url: e.image_url.thumbnail,
        display_name: e.group_name,
        raised_description: e.raised_description,
        name: e.group_name,
        id: e.id,
        supports_share_page: e.supports_share_page,
      };
    });

    yield put(P2PLeaderboardActions.fetchP2PLeaderboardSuccess('teams', adjustedEntries));
  } else {
    yield put(P2PLeaderboardActions.fetchP2PLeaderboardFailure(response));
  }
}
