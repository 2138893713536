export default class reportsAPI {
  constructor(services) {
    this.services = services;
    services.afterLoad(() => {});
  }

  types() {
    return this.services.baseAPIv2.get('/admin/reports/types', false);
  }

  list({ type, search, userId }) {
    const qs = {};
    const route = userId ? `/admin/users/${userId}/reports` : '/admin/reports';

    if (type) qs.type = type;
    if (search) qs.search = search;

    return this.services.baseAPIv2.get(route, false, qs);
  }

  create(data) {
    return this.services.baseAPIv2.post('/admin/reports', data);
  }

  read({ reportId }) {
    return this.services.baseAPIv2.get(`/admin/reports/${reportId}`, false);
  }

  update(reportId, data) {
    return this.services.baseAPIv2.put(`/admin/reports/${reportId}`, data);
  }

  delete(reportId) {
    return this.services.baseAPIv2.delete(`/admin/reports/${reportId}`);
  }

  refresh(reportId) {
    return this.services.baseAPIv2.put(`/admin/reports/${reportId}/refresh`);
  }
}
