import DebugConfig from './debug';

let init = null;

if (DebugConfig.useReactotron) {
  const sagaPlugin = require('reactotron-redux-saga');
  const apisaucePlugin = require('reactotron-apisauce');
  const { reactotronRedux: reduxPlugin } = require('reactotron-redux');
  const Reactotron = require('reactotron-react-js');
  const { trackGlobalErrors } = require('reactotron-react-js');

  // https://github.com/infinitered/reactotron for more options!
  init = Reactotron.configure({ name: 'atlasGO 🏃 Dashboard' }) // controls connection & communication settings
    .use(reduxPlugin())
    .use(sagaPlugin())
    .use(
      apisaucePlugin({
        // ignoreContentTypes: /^(image)\/.*$/i   <--- a way to skip printing the
        // body of some requests (default is any image)
      }),
    ) // <-- here we go!!! https://github.com/infinitered/reactotron/blob/master/docs/plugin-apisauce.md
    .use(trackGlobalErrors())
    .connect(); // let's connect!

  // Let's clear Reactotron every time we load the app
  Reactotron.clear();

  // Totally hacky, but this allows us not to be importing reactotron-react-js
  // on every file.  This is just DEV mode, so no big deal.
  console.tron = Reactotron;
}

const reactotron = init;

export default reactotron;
