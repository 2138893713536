import isEmpty from 'lodash/isEmpty';

export function can(permissions, user) {
  user = this || user;

  if (!user || isEmpty(user)) return false;
  if (!user.permissions) return false;
  if (typeof permissions === 'string') return user.permissions.includes(permissions);

  if (Array.isArray(permissions)) {
    return permissions.some(permission => user.permissions.includes(permission));
  }
}

export function canUpdateWysiwyg(permission, user) {
  user = this || user;

  return user.can(permission);
}

export function canUpdateChallengeWysiwyg(challenge, user) {
  user = this || user;

  // if the user is an admin, allow
  if (user.is_admin) return true;

  // check if user is one of the challenge owners
  if (!challenge.owner_ids.includes(user.id)) return false;

  return canUpdateWysiwyg('challenge:update_wysiwyg', user);
}
