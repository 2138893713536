import produce from 'immer';
import { createActions, createReducer } from 'reduxsauce';
import { createSelector } from 'reselect';

import { cleanColor } from 'utils';
import { getLanguage } from 'utils/locale';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions(
  {
    fetchChallengesRequest: ['challengeID'],
    fetchChallengesSuccess: ['challenges'],
    fetchChallengesFailure: ['error'],

    fetchChallengePartialRequest: ['challengeID', 'distanceUnits'],
    fetchChallengeInfoRequest: ['challengeID', 'distanceUnits'],
    fetchChallengeInfoSuccess: ['info'],
    fetchChallengeInfoFailure: ['error'],

    fetchChallengeStoriesRequest: ['challengeID'],
    fetchChallengeStoriesSuccess: ['challengeID', 'entries'],
    fetchChallengeStoriesFailure: ['error'],

    joinChallengeRequest: ['challengeID', 'groupIDs'],
    joinChallengeSuccess: ['status'],
    joinChallengeFailure: ['error'],

    fetchChallengeGroupListsRequest: ['challengeID', 'ownerID', 'distanceUnits'],
    fetchChallengeGroupListsSuccess: ['groupLists'],
    fetchChallengeGroupListsFailure: ['error'],

    createChallengeGroupRequest: ['challengeID', 'name', 'groupListID', 'ownerID', 'imageURL'],
    createChallengeGroupSuccess: null,
    createChallengeGroupFailure: ['error'],

    deleteChallengeGroupRequest: ['challengeID', 'groupID', 'ownerID', 'name'],
    deleteChallengeGroupSuccess: null,
    deleteChallengeGroupFailure: ['error'],

    fetchChallengeWysiwygContentRequest: ['challengeID'],
    fetchChallengeWysiwygContentSuccess: ['content'],
    fetchChallengeWysiwygContentFailure: ['error'],

    saveChallengeWysiwygContentRequest: ['challengeID', 'content'],
    saveChallengeWysiwygContentSuccess: ['content'],
    saveChallengeWysiwygContentFailure: ['error'],

    fetchChallengeTicketsRequest: ['challengeID'],
    fetchChallengeTicketsSuccess: ['tickets', 'meta'],
    fetchChallengeTicketsFailure: ['error'],

    buyChallengeTicketsRequest: ['challengeID', 'data'],
    buyChallengeTicketsSuccess: ['checkoutSession', 'skipPayment'],
    buyChallengeTicketsFailure: ['error'],

    validateChallengeTicketRequest: ['challengeID', 'ticketID', 'unlockCode'],
    validateChallengeTicketSuccess: ['ticket'],
    validateChallengeTicketFailure: ['error'],

    fetchChallengeFeaturedActivityTypesRequest: ['challengeID'],
    fetchChallengeFeaturedActivityTypesSuccess: ['data'],
    fetchChallengeFeaturedActivityTypesFailure: ['error'],

    submitChallengeActivityRequest: ['meters', 'seconds', 'units', 'activityType', 'token'],
    submitChallengeActivitySuccess: ['status'],
    submitChallengeActivityFailure: ['error', 'status'],

    verifyChallengeCouponRequest: ['challengeID', 'couponCode'],
    verifyChallengeCouponSuccess: ['coupon'],
    verifyChallengeCouponFailure: ['error'],

    makeChallengeDonationRequest: ['challengeID', 'data'],
    makeChallengeDonationSuccess: ['checkoutSession'],
    makeChallengeDonationFailure: ['error'],
  },
  { prefix: 'CHALLENGES/' },
);

export const ChallengesTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  error: null,
  fetching: false,
  challenges: [],
  challengeInfo: null,
  challengeStories: {},
  groupLists: [],
  wysiwygContent: null,
  tickets: {},
  ticketsCurrencies: [],
  checkoutSession: null,
  skipPaymentCheckout: false,
  challengeTicketStatus: null,
  coupon: {},

  // since the old API only returns { "success": true },
  // we need a way to tell the app if the join is successful or not
  // so the following status will enum ['joined', 'failed']
  joinStatus: '',
  featuredActivities: [],
  availableActivities: [],
  token: '',
  submitActivityStatus: 'starting', // enum ['starting', 'submitted', 'not_submitted']
};

/* ------------- Reducers ------------- */

// we're attempting to fetch something
export const request = state =>
  produce(state, draft => {
    draft.fetching = true;
    draft.error = null;
  });

// we've successfully fetched challenges
export const successChallenges = (state, action) =>
  produce(state, draft => {
    const { challenges: data } = action;

    draft.fetching = false;
    draft.error = null;
    draft.joinStatus = '';
    draft.challenges = data;
  });

// we've had a problem fetching
export const failure = (state, action) =>
  produce(state, draft => {
    const { error } = action;

    draft.fetching = false;
    draft.joinStatus = '';
    draft.error = error;
  });

// we've successfully fetched the challenge info
export const successChallengeInfo = (state, action) =>
  produce(state, draft => {
    const { info } = action;

    // FIXME: remove me when it's safe do do so 😅
    if (!info.corporate_partner) info.corporate_partner = info.corperate_partner;
    if (info.dash_color_main) info.dash_color_main = cleanColor(info.dash_color_main);
    if (!info.owner_ids) info.owner_ids = [];

    const langAbbrev = getLanguage()
      .toLowerCase()
      .slice(0, 2);
    const { i18n } = info;
    if (i18n && i18n[langAbbrev] && i18n[langAbbrev].name) {
      info.name = i18n[langAbbrev].name;
    }
    if (i18n && i18n[langAbbrev] && i18n[langAbbrev].summary) {
      info.summary = info.i18n[langAbbrev].summary;
    }

    draft.fetching = false;
    draft.error = null;
    draft.joinStatus = '';
    draft.challengeInfo = info;
  });

// we've successfully fetched challenge stories
/* eslint-disable no-plusplus */
export const successChallengeStories = (state, action) => {
  const { challengeID, entries } = action;

  return produce(state, draft => {
    const stories = {};
    for (let i = 0; i < entries.length; ++i) {
      stories[entries[i].id] = entries[i];
    }

    draft.fetching = false;
    draft.error = null;
    draft.joinStatus = '';
    draft.challengeStories[challengeID] = stories;
  });
};

export const successJoinChallenge = (state, action) =>
  produce(state, draft => {
    const { status } = action;

    draft.fetching = false;
    draft.error = null;
    draft.groupLists = [];
    draft.joinStatus = status;
  });

export const successChallengeGroupLists = (state, action) =>
  produce(state, draft => {
    const { groupLists } = action;

    const sortedGroupLists = [
      ...groupLists.sort((groupList, nextGroupList) => {
        return groupList.order_priority - nextGroupList.order_priority;
      }),
    ];

    draft.fetching = false;
    draft.error = null;
    draft.groupLists = sortedGroupLists;
  });

export const successChallengeWysiwygContent = (state, action) =>
  produce(state, draft => {
    const { content } = action;

    draft.fetching = false;
    draft.error = null;
    draft.wysiwygContent = JSON.parse(content.content);
  });

export const successChallengeTickets = (state, action) =>
  produce(state, draft => {
    const { tickets, meta } = action;

    const sortedTickets = {};

    meta.supported_currencies.forEach(currency => {
      sortedTickets[currency] = tickets.filter(t => t.currency.iso_4217_currency_code === currency);
    });

    draft.fetching = false;
    draft.error = null;
    draft.tickets = sortedTickets;
    draft.ticketsCurrencies = meta.supported_currencies;
  });

export const successBuyChallengeTickets = (state, action) =>
  produce(state, draft => {
    const { checkoutSession, skipPayment } = action;

    draft.fetching = false;
    draft.error = null;
    draft.checkoutSession = checkoutSession;
    draft.skipPaymentCheckout = skipPayment;
  });

export const successValidateChallengeTicket = (state, action) =>
  produce(state, draft => {
    const { ticket } = action;
    const status = ticket.valid && !ticket.claimed ? 'valid' : 'invalid';

    draft.fetching = false;
    draft.error = null;
    draft.challengeTicketStatus = status;
  });

export const successChallengeFeaturedActivityTypes = (state, action) =>
  produce(state, draft => {
    const { data } = action;
    const { featuredActivities, availableActivities, token } = data;

    draft.fetching = false;
    draft.error = null;
    draft.token = token;
    draft.featuredActivities = featuredActivities;
    draft.availableActivities = availableActivities;
  });

export const successSubmitActivity = (state, action) =>
  produce(state, draft => {
    const { status } = action;

    draft.fetching = false;
    draft.error = null;
    draft.submitActivityStatus = status || 'submitted';
  });

export const failureSubmitActivity = (state, action) =>
  produce(state, draft => {
    draft.fetching = false;
    draft.error = null;
    draft.submitActivityStatus = 'not_submitted';
  });

export const successVerifyChallengeCoupon = (state, action) =>
  produce(state, draft => {
    const { coupon } = action;

    draft.fetching = false;
    draft.error = null;
    draft.coupon = coupon;
  });

export const successMakeChallengeDonation = (state, action) =>
  produce(state, draft => {
    const { checkoutSession } = action;

    draft.fetching = false;
    draft.error = null;
    draft.checkoutSession = checkoutSession;
  });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_CHALLENGES_REQUEST]: request,
  [Types.FETCH_CHALLENGES_SUCCESS]: successChallenges,
  [Types.FETCH_CHALLENGES_FAILURE]: failure,

  [Types.FETCH_CHALLENGE_PARTIAL_REQUEST]: request,
  [Types.FETCH_CHALLENGE_INFO_REQUEST]: request,
  [Types.FETCH_CHALLENGE_INFO_SUCCESS]: successChallengeInfo,
  [Types.FETCH_CHALLENGE_INFO_FAILURE]: failure,

  [Types.FETCH_CHALLENGE_STORIES_REQUEST]: request,
  [Types.FETCH_CHALLENGE_STORIES_SUCCESS]: successChallengeStories,
  [Types.FETCH_CHALLENGE_STORIES_FAILURE]: failure,

  [Types.JOIN_CHALLENGE_REQUEST]: request,
  [Types.JOIN_CHALLENGE_SUCCESS]: successJoinChallenge,
  [Types.JOIN_CHALLENGE_FAILURE]: failure,

  [Types.FETCH_CHALLENGE_GROUP_LISTS_REQUEST]: request,
  [Types.FETCH_CHALLENGE_GROUP_LISTS_SUCCESS]: successChallengeGroupLists,
  [Types.FETCH_CHALLENGE_GROUP_LISTS_FAILURE]: failure,

  [Types.CREATE_CHALLENGE_GROUP_REQUEST]: request,
  [Types.CREATE_CHALLENGE_GROUP_SUCCESS]: null,
  [Types.CREATE_CHALLENGE_GROUP_FAILURE]: failure,

  [Types.DELETE_CHALLENGE_GROUP_REQUEST]: request,
  [Types.DELETE_CHALLENGE_GROUP_SUCCESS]: null,
  [Types.DELETE_CHALLENGE_GROUP_FAILURE]: failure,

  [Types.FETCH_CHALLENGE_WYSIWYG_CONTENT_REQUEST]: request,
  [Types.FETCH_CHALLENGE_WYSIWYG_CONTENT_SUCCESS]: successChallengeWysiwygContent,
  [Types.FETCH_CHALLENGE_WYSIWYG_CONTENT_FAILURE]: failure,

  [Types.SAVE_CHALLENGE_WYSIWYG_CONTENT_REQUEST]: request,
  [Types.SAVE_CHALLENGE_WYSIWYG_CONTENT_SUCCESS]: successChallengeWysiwygContent,
  [Types.SAVE_CHALLENGE_WYSIWYG_CONTENT_FAILURE]: failure,

  [Types.FETCH_CHALLENGE_TICKETS_REQUEST]: request,
  [Types.FETCH_CHALLENGE_TICKETS_SUCCESS]: successChallengeTickets,
  [Types.FETCH_CHALLENGE_TICKETS_FAILURE]: failure,

  [Types.BUY_CHALLENGE_TICKETS_REQUEST]: request,
  [Types.BUY_CHALLENGE_TICKETS_SUCCESS]: successBuyChallengeTickets,
  [Types.BUY_CHALLENGE_TICKETS_FAILURE]: failure,

  [Types.VALIDATE_CHALLENGE_TICKET_REQUEST]: request,
  [Types.VALIDATE_CHALLENGE_TICKET_SUCCESS]: successValidateChallengeTicket,
  [Types.VALIDATE_CHALLENGE_TICKET_FAILURE]: failure,

  [Types.FETCH_CHALLENGE_FEATURED_ACTIVITY_TYPES_REQUEST]: request,
  [Types.FETCH_CHALLENGE_FEATURED_ACTIVITY_TYPES_SUCCESS]: successChallengeFeaturedActivityTypes,
  [Types.FETCH_CHALLENGE_FEATURED_ACTIVITY_TYPES_FAILURE]: failure,

  [Types.SUBMIT_CHALLENGE_ACTIVITY_REQUEST]: request,
  [Types.SUBMIT_CHALLENGE_ACTIVITY_SUCCESS]: successSubmitActivity,
  [Types.SUBMIT_CHALLENGE_ACTIVITY_FAILURE]: failureSubmitActivity,

  [Types.VERIFY_CHALLENGE_COUPON_REQUEST]: request,
  [Types.VERIFY_CHALLENGE_COUPON_SUCCESS]: successVerifyChallengeCoupon,
  [Types.VERIFY_CHALLENGE_COUPON_FAILURE]: failure,

  [Types.MAKE_CHALLENGE_DONATION_REQUEST]: request,
  [Types.MAKE_CHALLENGE_DONATION_SUCCESS]: successMakeChallengeDonation,
  [Types.MAKE_CHALLENGE_DONATION_FAILURE]: failure,
});

/* ------------- Selectors ------------- */

const challenges = state => state.challenges;
const getChallengesError = createSelector(challenges, c => c.error);
const getChallenges = createSelector(challenges, c => c.challenges);
const getChallengeInfo = createSelector(challenges, c => c.challengeInfo);
const isChallengeInfoLoaded = createSelector(challenges, c => c.challengeInfo !== null);
const getChallengeJoinStatus = createSelector(challenges, c => c.joinStatus);
const isJoiningChallenge = createSelector(challenges, c => c.fetching);
const getChallengeGroupLists = createSelector(challenges, c => c.groupLists);
const getChallengeWysiwygContent = createSelector(challenges, c => c.wysiwygContent);
const isChallengesLoading = createSelector(challenges, c => c.fetching);
const getChallengeTickets = createSelector(challenges, c => c.tickets);
const getChallengeTicketsCurrencies = createSelector(challenges, c => c.ticketsCurrencies);
const getChallengeStripeSession = createSelector(challenges, c => c.checkoutSession);
const getChallengeTicketStatus = createSelector(challenges, c => c.challengeTicketStatus);
const getChallengeFeaturedActivities = createSelector(challenges, c => c.featuredActivities);
const getChallengeAvailableActivities = createSelector(challenges, c => c.availableActivities);
const getToken = createSelector(challenges, c => c.token);
const getChallengeSubmitActivityStatus = createSelector(challenges, c => c.submitActivityStatus);
const getChallengeCoupon = createSelector(challenges, c => c.coupon);
const skipPaymentCheckout = createSelector(challenges, c => c.skipPaymentCheckout);

const getChallengeStories = state => ({ challengeID }) => {
  return createSelector(challenges, c => {
    const storiesHash = c.challengeStories[challengeID];
    if (storiesHash == null) return null;
    return Object.values(storiesHash);
  })(state);
};

const getChallengeStory = state => ({ challengeID, storyId }) => {
  return createSelector(challenges, c => {
    const storiesHash = c.challengeStories[challengeID];
    if (storiesHash == null) {
      return null;
    }

    return storiesHash[storyId];
  })(state);
};

export const selectors = state => {
  return {
    getChallengesError: getChallengesError(state),
    getChallenges: getChallenges(state),
    getChallengeInfo: getChallengeInfo(state),
    isChallengeInfoLoaded: isChallengeInfoLoaded(state),
    getChallengeStories: getChallengeStories(state),
    getChallengeStory: getChallengeStory(state),
    getChallengeJoinStatus: getChallengeJoinStatus(state),
    isJoiningChallenge: isJoiningChallenge(state),
    getChallengeGroupLists: getChallengeGroupLists(state),
    getChallengeWysiwygContent: getChallengeWysiwygContent(state),
    isChallengesLoading: isChallengesLoading(state),
    getChallengeTickets: getChallengeTickets(state),
    getChallengeTicketsCurrencies: getChallengeTicketsCurrencies(state),
    getChallengeStripeSession: getChallengeStripeSession(state),
    getChallengeTicketStatus: getChallengeTicketStatus(state),
    getChallengeFeaturedActivities: getChallengeFeaturedActivities(state),
    getChallengeAvailableActivities: getChallengeAvailableActivities(state),
    getChallengeSubmitActivityStatus: getChallengeSubmitActivityStatus(state),
    getChallengeCoupon: getChallengeCoupon(state),
    skipPaymentCheckout: skipPaymentCheckout(state),
    getToken: getToken(state),
  };
};
