import { datadogRum } from '@datadog/browser-rum';

import { DATADOG_APP_ID, DATADOG_CLIENT_TOKEN, ENV } from './constants';
import { __DEV__ } from './debug';

if (!__DEV__) {
  datadogRum.init({
    applicationId: DATADOG_APP_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'web-client',
    env: ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: require('../../package.json').version,
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();
}
