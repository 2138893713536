// i18nInfo is a payload that usually looks something like
// {
//   en: {
//     foo: 'foo',
//     bar: 'bar',
//   },
//   fr: {
//     foo: 'le foo',
//     bar: 'le bar',
//   },
// }
export function i18nGracefulGet(lang, fallbackValue, i18nInfo, key) {
  const currentLanguage = lang ? lang.slice(0, 2) : 'en';

  if (currentLanguage === 'en') {
    return fallbackValue;
  }

  const translations = i18nInfo[currentLanguage];
  if (translations == null) {
    return fallbackValue;
  }

  const translation = translations[key];
  if (translation == null) {
    return fallbackValue;
  }

  return translation;
}

export function currentPageEditorLanguage() {
  return localStorage.getItem('PageEditor:language') || 'en';
}

export const languageAbbrevsMap = [
  { abbrev: 'en', name: 'English' },
  { abbrev: 'fr', name: 'French' },
  { abbrev: 'nl', name: 'Dutch' },
  { abbrev: 'de', name: 'German' },
  { abbrev: 'es', name: 'Spanish' },
  { abbrev: 'it', name: 'Italian' },
  { abbrev: 'pt', name: 'Portuguese' },
  { abbrev: 'bg', name: 'Bulgarian' },
];
