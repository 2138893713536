export default class groupListsAPI {
  constructor(services) {
    this.services = services;
    services.afterLoad(() => {
      // after load
    });
  }

  getGroupLists(challengeID) {
    return this.services.baseAPIv2.get(`/admin/group-lists/challenge/${challengeID}`, false);
  }

  readGroupList(groupListID) {
    return this.services.baseAPIv2.get(`/admin/group-lists/${groupListID}`, false);
  }

  addGroupList(data) {
    const {
      challengeID,
      name,
      question,
      search_sort_method,
      hidden_from_leaderboards,
      supports_share_page,
      users_must_select_group,
      allows_user_created_groups,
      max_users_per_group,
      i18n,
    } = data;

    return this.services.baseAPIv2.post('/admin/group-lists', {
      challenge_id: challengeID,
      name,
      question,
      search_sort_method,
      hidden_from_leaderboards,
      supports_share_page,
      users_must_select_group,
      allows_user_created_groups,
      max_users_per_group,
      i18n,
    });
  }

  updateGroupList(groupListID, data) {
    const {
      name,
      question,
      search_sort_method,
      hidden_from_leaderboards,
      supports_share_page,
      users_must_select_group,
      allows_user_created_groups,
      max_users_per_group,
      i18n,
    } = data;

    return this.services.baseAPIv2.put(`/admin/group-lists/${groupListID}`, {
      name,
      question,
      search_sort_method,
      hidden_from_leaderboards,
      supports_share_page,
      users_must_select_group,
      allows_user_created_groups,
      max_users_per_group,
      i18n,
    });
  }

  deleteGroupList(groupListID) {
    return this.services.baseAPIv2.put(`/admin/group-lists/delete/${groupListID}`);
  }

  reorderGroupLists({ challengeID, groupListIDs }) {
    return this.services.baseAPIv2.put(`/admin/group-lists/reorder/${challengeID}`, {
      groupListIDs,
    });
  }
}
