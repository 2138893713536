import { call, delay, fork, getContext, put, select } from 'redux-saga/effects';

import { selectors as challengesSelectors } from '../reducers/challenges';
import DashboardActions from '../reducers/dashboard';

import { updateChallengeInfo, updateChallengeInfoPeriodically } from './challenges';
import { fetchGroupListLeaderboard, fetchLeaderboard } from './leaderboard';

export function* fetchFeed(action) {
  const { challengeID } = action;
  const services = yield getContext('services');
  const response = yield services.goBoardAPI.feed({ challengeID });

  // success?
  if (response.ok) {
    yield put(DashboardActions.fetchFeedSuccess(response.data.data));
  } else {
    yield put(DashboardActions.fetchFeedFailure(response.originalError));
  }
}

export function* onDashMounted(action) {
  const { challengeID } = action;

  yield call(updateChallengeInfo, { challengeID });

  // Grab latest info periodically
  yield fork(updateChallengeInfoPeriodically, { challengeID });

  while (true) {
    // Get a list of all the group list leaderboards
    const { getChallengeInfo: challengeInfo } = challengesSelectors(yield select());
    const { group_list_leaderboards: groupListLeaderboards } = challengeInfo;

    yield call(fetchLeaderboard, { name: 'individuals', challengeID });
    yield call(fetchFeed, { challengeID });

    /* eslint-disable no-plusplus */
    for (let i = 0; i < groupListLeaderboards.length; ++i) {
      yield fork(fetchGroupListLeaderboard, { groupListID: groupListLeaderboards[i].id });
    }

    yield delay(30000);
  }
}
