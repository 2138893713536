import forOwn from 'lodash/forOwn';
import has from 'lodash/has';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import isPlainObject from 'lodash/isPlainObject';
import _merge from 'lodash/merge';
import transform from 'lodash/transform';
import uniqBy from 'lodash/uniqBy';

/**
 * Find difference between two objects
 * @param  {object} originalObject - Source object to compare newObject against
 * @param  {object} newObject  - New object with potential changes
 * @return {object} differences
 */
export function difference(originalObject, newObject) {
  function changes(newObj, origObj) {
    let arrayIndexCounter = 0;

    return transform(newObj, (result, value, key) => {
      if (!isEqual(value, origObj[key])) {
        const resultKey = isArray(origObj) ? (arrayIndexCounter += 1) : key;

        result[resultKey] =
          isObject(value) && isObject(origObj[key]) ? changes(value, origObj[key]) : value;
      }
    });
  }

  return changes(newObject, originalObject);
}

/**
 * merge two objects
 * @param  {object} originalObject source object
 * @param  {object} newObject      new object with potential changes
 * @return {object}                merged object containing new changes
 */
export function merge(originalObject, newObject) {
  return _merge(originalObject, newObject);
}

/**
 * inspect difference
 * @param  {object} diff the difference between two objects
 * @return {void}
 */
export function inspect(diff) {
  console.log(require('util').inspect(diff, { showHidden: false, depth: null, colors: true }));
}

export function deepDiffObj(base, object) {
  if (!object) throw new Error(`The object compared should be an object: ${object}`);
  if (!base) return object;
  const result = transform(object, (res, value, key) => {
    // fix edge case: not defined to explicitly defined as undefined
    if (!has(base, key)) res[key] = value;
    if (!isEqual(value, base[key])) {
      res[key] =
        isPlainObject(value) && isPlainObject(base[key]) ? deepDiffObj(base[key], value) : value;
    }
  });

  // map removed fields to undefined
  forOwn(base, (value, key) => {
    if (!has(object, key)) result[key] = undefined;
  });

  return result;
}

export function arrayUnion(arr1, arr2, identifier) {
  const array = [...arr1, ...arr2];

  return uniqBy(array, identifier);
}

export const omit = (keysToOmit, originalObj = {}) =>
  Object.fromEntries(Object.entries(originalObj).filter(([key]) => !keysToOmit.includes(key)));
