import i18n from 'i18next';
import { put } from 'redux-saga/effects';

import ToastsActions from '../reducers/toasts';

export function* watchErrors(action) {
  let msg = '';
  const { error } = action;

  if (error.response && error.response.data) {
    msg = error.response.data.error ? error.response.data.error.message : '';

    if (error.response.data.error && error.response.data.error.details) {
      if (error.response.data.error.details.length) {
        // let us replace our message with a more detailed one
        msg = error.response.data.error.details[0].message;
      }
    }

    if (msg === 'Group name does not match security regex') {
      msg = i18n.t(
        'Special characters are not allowed. Please use only letters, numbers or spaces.',
      );
    }

    if (msg.includes('['))
      msg = i18n.t('Oops! This is our fault. Please try again or contact support@atlasgo.org.');
  } else if (error.error) {
    msg = error.error.message;
  }

  yield put(
    ToastsActions.addToast({
      type: error.type || 'error',
      text: msg || error.message || i18n.t('An unknown error has occured'),
    }),
  );
}
