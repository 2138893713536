export default class ChallengeMapsAPI {
  constructor(services) {
    this.services = services;
    services.afterLoad(() => {
      // after load
    });
  }

  list() {
    return this.services.baseAPIv2.get('/admin/challenge-maps/', false);
  }

  get(id) {
    return this.services.baseAPIv2.get(`/admin/challenge-maps/${id}`, false);
  }

  create(data) {
    const {
      name,
      challenge_id,
      map_type,
      geojson_data,
      path_color,
      mapbox_url,
      main_progress_marker_url,
    } = data;

    return this.services.baseAPIv2.post('/admin/challenge-maps', {
      name,
      challenge_id,
      map_type,
      geojson_data,
      path_color,
      mapbox_url,
      main_progress_marker_url,
    });
  }

  update(id, data) {
    const {
      name,
      challenge_id,
      map_type,
      geojson_data,
      path_color,
      mapbox_url,
      main_progress_marker_url,
    } = data;

    return this.services.baseAPIv2.put(`/admin/challenge-maps/${id}`, {
      name,
      challenge_id,
      map_type,
      geojson_data,
      path_color,
      mapbox_url,
      main_progress_marker_url,
    });
  }

  archive(id) {
    return this.services.baseAPIv2.put(`/admin/challenge-maps/${id}/archive`);
  }
}
