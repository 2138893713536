import {
  API_BASE_URL,
  LOCALHOST_BASE_URL,
  LOCALHOST_RUBY_BASE_URL,
  LOCALHOST_WS_BASE_URL,
  STAGING_API_BASE_URL,
  STAGING_WS_BASE_URL,
  WS_BASE_URL,
} from 'config/constants';
import DebugConfig, { __DEV__ } from 'config/debug';

export function getBaseURLs(isRubyServer = false) {
  /* eslint-disable no-underscore-dangle */
  let baseURL = DebugConfig.useStagingAPIBase ? STAGING_API_BASE_URL : API_BASE_URL;
  let wsBaseURL = DebugConfig.useStagingAPIBase ? STAGING_WS_BASE_URL : WS_BASE_URL;

  const localhostURL = isRubyServer ? LOCALHOST_RUBY_BASE_URL : LOCALHOST_BASE_URL;

  if (
    __DEV__ &&
    ((isRubyServer && DebugConfig.useLocalhostV1APIBase) ||
      (!isRubyServer && DebugConfig.useLocalhostV2APIBase))
  ) {
    baseURL = localhostURL;
    wsBaseURL = LOCALHOST_WS_BASE_URL;
  }

  return {
    http: baseURL,
    ws: wsBaseURL,
  };
}
