import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { PersistGate } from 'redux-persist/es/integration/react';

import 'config';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import PageLoader from 'components/PageLoader';
import App from 'containers/App';
import RootContext from 'HOCs/RootContext';
import initStore from 'redux/initStore';
import Services from 'services/Services';
import { GlobalStyle } from 'theme/styles';

import * as serviceWorker from './utils/serviceWorker';

class Root extends React.Component {
  rootContext = {};

  constructor(props) {
    super(props);

    const { rootContext } = this;
    rootContext.services = new Services(rootContext);

    const { store, persistor, history } = initStore(rootContext);

    rootContext.store = store;
    rootContext.persistor = persistor;
    rootContext.graphql = rootContext.services.baseAPIv3.graphql;
    this.services = rootContext.services;
    this.store = rootContext.store;
    this.persistor = rootContext.persistor;
    this.history = history;
  }

  render() {
    return (
      <ReduxProvider store={this.store}>
        <Suspense fallback={<PageLoader />}>
          <PersistGate loading={<PageLoader />} persistor={this.persistor} onBeforeLift={null}>
            <ApolloProvider client={this.rootContext.graphql.client}>
              <RootContext.Provider value={this.rootContext}>
                <GlobalStyle />
                <App navHistory={this.history} />
              </RootContext.Provider>
            </ApolloProvider>
          </PersistGate>
        </Suspense>
      </ReduxProvider>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
