// Bundle all actions and selectors into one nice file
import sagas from 'redux/sagas';

import { Actions, selectors, Types } from './reducers';

export default {
  Types,
  Actions,
  selectors,
  sagas,
};
