import { produce } from 'immer';
import { createActions, createReducer } from 'reduxsauce';
import { createSelector } from 'reselect';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions(
  {
    fetchP2PLeaderboardRequest: ['challengeID'],
    fetchP2PLeaderboardSuccess: ['name', 'entries'],
    fetchP2PLeaderboardFailure: ['error'],

    selectP2PLeaderboard: ['payload'],

    fetchP2PTeamsLeaderboardRequest: ['challengeID'],
  },
  { prefix: 'P2P_LEADERBOARD/' },
);

export const P2PLeaderboardTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  P2PLeaderboard: {},
  visibleP2PLeaderboard: 'individuals',

  error: null,
  fetching: false,
};

/* ------------- Reducers ------------- */

// we're attempting to fetch the peer-to-peer leaderboard
export const requestP2PLeaderboard = state => {
  return produce(state, draft => {
    draft.fetching = true;
  });
};

// we've successfully fetched the P2P leaderboard
export const successP2PLeaderboard = (state, action) =>
  produce(state, draft => {
    const { name, entries } = action;

    draft.fetching = false;
    draft.error = null;
    draft.P2PLeaderboard[name] = entries;
  });

// we've had a problem fetching the leaderboard
export const failureP2PLeaderboard = (state, { error }) =>
  produce(state, draft => {
    draft.fetching = false;
    draft.error = error;
  });

// we've selected a leaderboard type
export const selectP2PLeaderboard = (state, action) =>
  produce(state, draft => {
    const id = action.payload;
    if (state.visibleP2PLeaderboard !== id) draft.visibleP2PLeaderboard = id;
    else draft.visibleP2PLeaderboard = null;
  });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_P2_P_LEADERBOARD_REQUEST]: requestP2PLeaderboard,
  [Types.FETCH_P2_P_LEADERBOARD_SUCCESS]: successP2PLeaderboard,
  [Types.FETCH_P2_P_LEADERBOARD_FAILURE]: failureP2PLeaderboard,

  [Types.SELECT_P2_P_LEADERBOARD]: selectP2PLeaderboard,

  [Types.FETCH_P2_P_TEAMS_LEADERBOARD_REQUEST]: requestP2PLeaderboard,
});

/* ------------- Selectors ------------- */

const P2PLeaderboard = state => state.P2PLeaderboard;
const getVisibleP2PLeaderboard = createSelector(P2PLeaderboard, l => l.visibleP2PLeaderboard);

const getP2PLeaderboard = state => name => {
  return createSelector(P2PLeaderboard, l => l.P2PLeaderboard[name])(state);
};

const isP2PLeaderboardLoaded = state => name => {
  return createSelector(P2PLeaderboard, l => l.P2PLeaderboard[name] != null)(state);
};

export const selectors = state => {
  return {
    getP2PLeaderboard: getP2PLeaderboard(state),
    isP2PLeaderboardLoaded: isP2PLeaderboardLoaded(state),
    getVisibleP2PLeaderboard: getVisibleP2PLeaderboard(state),
  };
};
