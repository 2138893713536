export default class ChallengesAPI {
  constructor(services) {
    this.services = services;
    services.afterLoad(() => {});
  }

  join2({ challengeID, groupIDs }) {
    return this.services.baseAPI.post('challenges/join2', {
      challenge_id: challengeID,
      group_ids: groupIDs,
    });
  }

  createGroup({ challengeID, name, imageURL = null }) {
    return this.services.baseAPI.post('challenges/create_group', {
      challenge_id: challengeID,
      name,
      image_url: imageURL,
    });
  }
}
