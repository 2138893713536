import * as Sentry from '@sentry/react';

import { LOG_ROCKET_PROJECT } from './constants';
import DebugConfig from './debug';

let init;

if (DebugConfig.useLogRocket) {
  const LogRocket = require('logrocket');
  const setupLogRocketReact = require('logrocket-react');

  LogRocket.init(LOG_ROCKET_PROJECT);

  setupLogRocketReact(LogRocket);

  LogRocket.getSessionURL(sessionURL => {
    Sentry.configureScope(scope => {
      scope.setExtra('sessionURL', sessionURL);
    });
  });

  init = LogRocket;
}

const logRocket = init;

export default logRocket;
