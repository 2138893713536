export default class surveysAdminAPI {
  constructor(services) {
    this.services = services;
    services.afterLoad(() => {
      // after load
    });
  }

  list() {
    return this.services.baseAPIv2.get('/admin/surveys/');
  }

  get(id) {
    return this.services.baseAPIv2.get(`/admin/surveys/${id}`, false);
  }

  create(data) {
    const { title, description, hidden, trigger_event_type, challenge_id, questions, i18n } = data;

    return this.services.baseAPIv2.post('/admin/surveys/', {
      title,
      description,
      hidden,
      trigger_event_type,
      challenge_id,
      questions,
      i18n,
    });
  }

  update(id, data) {
    const { title, description, hidden, trigger_event_type, challenge_id, questions, i18n } = data;

    return this.services.baseAPIv2.put(`/admin/surveys/${id}`, {
      title,
      description,
      hidden,
      trigger_event_type,
      challenge_id,
      questions,
      i18n,
    });
  }
}
