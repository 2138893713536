export * from './ACLg';
export * from './api';
export * from './cloudinary';
export * from './conversions';
export * from './countries';
export * from './crypto/AES';
export * from './crypto/urlQsHash';
export * from './email';
export * from './hex2cssFilter';
export * from './i18n';
export * from './imageHelpers';
export * from './lifeCycleHelpers';
export * from './objects';
export * from './transformers';

export function genId() {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

  for (let i = 0; i < 15; i += 1) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
}

export function cleanColor(str) {
  if (str) {
    str = `#${str.replace(/[^a-fA-F0-9]/gi, '').substring(0, 6)}`;
  }

  return str;
}

export const formatTime = timer => {
  const getSeconds = timer % 60;
  const minutes = Math.floor(timer / 60);
  const getMinutes = minutes % 60;
  const getHours = Math.floor(timer / 3600);

  let output = `${getSeconds}s`;

  if (timer > 60) output = `${getMinutes}m ${getSeconds}s`;
  if (timer > 3600) output = `${getHours}h ${getMinutes}m ${getSeconds}s`;

  return output;
};

export const addLeadingZeroes = n => (n < 10 ? `0${n}` : n);

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
export const range = (from, to, step = 1) => {
  let i = from;
  const rangeItems = [];

  while (i <= to) {
    rangeItems.push(i);
    i += step;
  }

  return rangeItems;
};

/**
 * Calculate Stripe Fee to pass down to user
 *
 * https://support.stripe.com/questions/passing-the-stripe-fee-on-to-customers
 *
 * F_FIXED = fixed fee after VAT/GST is included
 * F_PERCENT = percent fee after VAT/GST is included
 * P_GOAL = the initial amount minus fees
 * P_CHARGE (the final total charge) = (P_GOAL + F_FIXED) / (1 - F_PERCENT)
 *
 * @param {*} amount
 * @returns number
 */
export const getStripeFees = amount => {
  if (Number.isNaN(amount)) return 0;

  amount = Number(amount);

  if (amount === 0) return 0;

  const P_GOAL = amount;
  const F_FIXED = 0.3;
  const F_PERCENT = 2.9 / 100;
  const P_CHARGE = (P_GOAL + F_FIXED) / (1 - F_PERCENT);

  const newFee = P_CHARGE - P_GOAL;

  return Math.round((newFee + Number.EPSILON) * 100) / 100;
};
