const theme = {};

theme.colors = {
  primary: '#06D691',
  secondary: '#CEFBE3',

  lightGray: '#7F7F7F',
  lessLightGray: '#161616',
  gray: '#B7B7B7',
  borderGray: '#d2d2d2',
  medDkGray: '#5F5F5F',
  medGray0: '#7B7B7B',
  darkGray0: '#4C4C4C',

  white: '#ffffff',
  black: '#000000',

  test: '#62e920', // Test is a bright green just for testing purposes
  themeShadow: '0 2px 4px rgba(0, 0, 0, 0.07)',
  lgThemeShadow: '0 2px 12px rgba(0, 0, 0, 0.07)',

  offWhite: '#F7F7F7',
  medGray: '#BECCC7',
  darkGray: '#A0ACA8',
  darkGreen: '#0A271E',
  blue: '#14abe5',

  warning: '#FB0028',

  // buttons
  buttonPrimaryGradientStart: '#42DDA8',
  buttonPrimaryGradientStop: '#06D691',
  facebook: '#5b5daf',
  strava: '#fc4c02',

  newThemeShadow: '0px 3px 25px rgba(0, 0, 0, 0.08)',
};

theme.fonts = {
  primary: '"Poppins", sans-serif',
  pre: 'Consolas, Liberation Mono, Menlo, Courier, monospace',
  quote: 'Georgia, serif',

  sans: {
    'font-family': '"Poppins", sans-serif',
    'font-weight': '400',
  },
  sansL: {
    'font-family': '"Poppins", sans-serif',
    'font-weight': '300',
  },
  sansM: {
    'font-family': '"Poppins", sans-serif',
    'font-weight': '500',
  },
  sansB: {
    'font-family': '"Poppins", sans-serif',
    'font-weight': '600',
  },

  LatoRegular: {
    'font-family': '"Lato", sans-serif',
    'font-weight': '400',
  },
  LatoBlack: {
    'font-family': '"Lato", sans-serif',
    'font-weight': '900',
  },
  LatoItalic: {
    'font-family': '"Lato", sans-serif',
    'font-weight': '400',
    'font-style': 'italic',
  },
};

theme.sizes = {
  maxWidth: '1100px',

  desktopFull: 1920,
  desktopWide: 1440,
  desktop: 1280,
  tablet: 768,
  mobile: 320,
};

theme.defaults = {
  shadowBox: {
    'border-radius': '3px',
    'box-shadow': theme.colors.themeShadow,
    'background-color': theme.colors.white,
  },
  lgShadowBox: {
    'border-radius': '3px',
    'background-color': '#F9F9F9',
    'box-shadow': theme.colors.lgThemeShadow,
    border: 'solid 1px #E6E6E6',
  },

  btn: {
    'background-color': theme.colors.white,
    border: '0',
    'border-radius': '100px',
    'font-size': '12px',
    'letter-spacing': '1px',
    padding: '10px 30px',
    display: 'inline-block',
    transition: 'all 0.2s ease',
    color: theme.colors.primary,
    'text-decoration': 'none',
    'line-height': '1.6',
    cursor: 'pointer',
  },
  clear: {
    '*zoom': 1,
    '&:before': {
      content: ' ',
      display: 'table',
    },
    '&:after': {
      content: ' ',
      display: 'table',
      clear: 'both',
    },
  },
  newShadowBox: {
    'box-shadow': theme.colors.newThemeShadow,
  },
};

export default theme;
