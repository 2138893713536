export default class FeedStoriesAPI {
  constructor(services) {
    this.services = services;
    services.afterLoad(() => {
      // after load
    });
  }

  list() {
    return this.services.baseAPIv2.get('/admin/feed-stories/', false);
  }

  get(id) {
    return this.services.baseAPIv2.get(`/admin/feed-stories/${id}`, false);
  }

  create(data) {
    const {
      image_url,
      title,
      content,
      url,
      hidden,
      category,
      challenge_id,
      post_on_date,
      post_off_date,
      i18n,
    } = data;

    return this.services.baseAPIv2.post('/admin/feed-stories', {
      image_url,
      title,
      content,
      url,
      hidden,
      category,
      challenge_id,
      post_on_date,
      post_off_date,
      i18n,
    });
  }

  update(id, data) {
    const {
      image_url,
      title,
      content,
      url,
      hidden,
      category,
      challenge_id,
      post_on_date,
      post_off_date,
      i18n,
    } = data;

    return this.services.baseAPIv2.put(`/admin/feed-stories/${id}`, {
      image_url,
      title,
      content,
      url,
      hidden,
      category,
      challenge_id,
      post_on_date,
      post_off_date,
      i18n,
    });
  }
}
