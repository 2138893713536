export default class DashAPI {
  constructor(services) {
    this.services = services;
    services.afterLoad(() => {
      // after load
    });
  }

  leaderboard({ name, challengeID }) {
    return this.services.baseAPI.post('dash/leaderboard', {
      name,
      challenge_id: challengeID,
    });
  }

  groupListLeaderboard({ groupListID }) {
    return this.services.baseAPI.post('dash/group_list_leaderboard', {
      group_list_id: groupListID,
    });
  }
}
