export default class couponsAPI {
  constructor(services) {
    this.services = services;
    services.afterLoad(() => {
      // after load
    });
  }

  list() {
    return this.services.baseAPIv2.get('/admin/coupons/');
  }

  get(id) {
    return this.services.baseAPIv2.get(`/admin/coupons/${id}`, false);
  }

  create(data) {
    const { challenge_id, code, name, tickets } = data;

    return this.services.baseAPIv2.post('/admin/coupons/', {
      challenge_id,
      code,
      name,
      tickets,
    });
  }

  update(id, data) {
    const { challenge_id, code, name, tickets } = data;

    return this.services.baseAPIv2.put(`/admin/coupons/${id}`, {
      challenge_id,
      code,
      name,
      tickets,
    });
  }

  // archive(id) {
  //   return this.services.baseAPIv2.patch(`/admin/activity-types/${id}`);
  // }
}
