import { gql } from '@apollo/client';

export const GET_MY_NOTIFICATIONS = gql`
  query getMyNotifications($page: Int, $limit: Int) {
    me {
      id
      username
      notifications(pageInfo: { page: $page, limit: $limit }) {
        pageInfo {
          page
          limit
          nextPage
          hasNextPage
          totalDocs
          totalPages
        }
        data {
          id
          message
          createdAt
          targetProfile {
            name
            avatarUrl {
              thumbnail
            }
          }
        }
      }
    }
  }
`;

export const GET_ALL_NOTIFICATIONS = gql`
  query getAllNotifications($page: Int, $limit: Int) {
    notifications(pageInfo: { page: $page, limit: $limit }) {
      pageInfo {
        page
        limit
        nextPage
        hasNextPage
        totalDocs
        totalPages
      }
      data {
        id
        message
        createdAt
        targetProfile {
          name
          avatarUrl {
            thumbnail
          }
        }
      }
    }
  }
`;
