import React from 'react';
import PropTypes from 'prop-types';

import ActivitySpinner from 'components/ActivitySpinner';

import { Wrapper } from './PageLoader.styles';

const PageLoader = ({ className }) => {
  return (
    <Wrapper className={className}>
      <ActivitySpinner />
    </Wrapper>
  );
};

PageLoader.propTypes = {
  className: PropTypes.string,
};

export default PageLoader;
