export default class usersAPI {
  constructor(services) {
    this.services = services;
    services.afterLoad(() => {
      // after load
    });
  }

  quickHealthChecks() {
    return this.services.baseAPIv2.get('/status/aggregate', false);
  }

  fullHealthChecks() {
    return this.services.baseAPIv2.get('/status/health', false);
  }

  terraformOutputs({ workspaceId, hardRefresh = true }) {
    const data = {};

    if (hardRefresh) data.refresh = hardRefresh;
    if (workspaceId) data.workspace_id = workspaceId;

    // let us force refresh
    data.refresh = true;

    return this.services.baseAPIv2.get(
      '/infra/terraform/current-state-version/outputs',
      false,
      data,
    );
  }

  deployments({ filter = '' }) {
    return this.services.baseAPIv2.get('/infra/deployments', false, { filter });
  }
}
