export default class GroupsAPI {
  constructor(services) {
    this.services = services;
    services.afterLoad(() => {
      // after load
    });
  }

  updateImage({ imageURL, groupID }) {
    return this.services.baseAPIv2.post(`groups/${groupID}/update-image`, { image_url: imageURL });
  }
}
