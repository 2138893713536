import { ROUTES } from 'config/constants';

export function whenUserIsAuthenticated(props) {
  const { user, history, sessionKey, location } = props;
  const { state } = location;

  if (sessionKey && user) {
    if (state && state.from) {
      history.push(state.from);
    } else if (props.layout === 'modal') {
      // do nothing
      // eslint-disable-next-line no-useless-return
      return;
    } else if (state && state.challengeID && state.layout === 'alternate') {
      history.push(ROUTES.join(state.challengeID));
    } else if (Object.keys(user).length > 6) {
      let activeChallengeID;

      if (user.active_challenge) activeChallengeID = user.active_challenge.id;

      history.push(activeChallengeID ? ROUTES.challenge(activeChallengeID) : ROUTES.home);
    }
  }
}
