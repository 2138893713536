export default class AuthAPI {
  constructor(services) {
    this.services = services;
    services.afterLoad(() => {});
  }

  // Returns a session key by trading a facebook token
  fb({ accessToken }) {
    return this.services.baseAPI.post('auth/fb', { access_token: accessToken });
  }

  verifyUsernameOrEmail({ value }) {
    return this.services.baseAPI.post('auth/verify_username_or_email', { value });
  }

  password({
    email = null,
    username = null,
    firstName = null,
    lastName = null,
    password,
    avatarURL = null,
  }) {
    return this.services.baseAPI.post('auth/password', {
      email,
      username,
      first_name: firstName,
      last_name: lastName,
      password,
      avatar_url: avatarURL,
    });
  }

  test({ sessionKey }) {
    return this.services.baseAPI.post('auth/test', { session_key: sessionKey });
  }

  strava({ accessToken }) {
    return this.services.baseAPI.post('auth/strava', { access_token: accessToken });
  }
}
