export default class GoBoardAPI {
  constructor(services) {
    this.services = services;

    services.afterLoad(() => {});
  }

  feed({ challengeID }) {
    return this.services.baseAPIv2.get(`goboard/feed/${challengeID}`);
  }

  info({ challengeID, distanceUnits }) {
    let url;

    if (distanceUnits) url = `goboard/info/${challengeID}?units=${distanceUnits}`;
    else url = `goboard/info/${challengeID}`;

    return this.services.baseAPIv2.get(url);
  }

  // TODO: Safe to Remove. Not Needed.
  challengesStoriesList({ challengeID }) {
    return this.services.baseAPIv2.get(`goboard/info/${challengeID}/stories`);
  }
}
