import { all, takeEvery, takeLatest } from 'redux-saga/effects';

/* ------------- Types ------------- */
import { AppTypes } from '../reducers/app';
import { ChallengesTypes } from '../reducers/challenges';
import { DashboardTypes } from '../reducers/dashboard';
import { LeaderboardTypes } from '../reducers/leaderboard';
import { P2PLeaderboardTypes } from '../reducers/p2pLeaderboard';
import { SessionTypes } from '../reducers/session';

import { onComponentDidMount } from './app';
import {
  buyChallengeTickets,
  createChallengeGroup,
  deleteChallengeGroup,
  fetchChallengeFeaturedActivityTypes,
  fetchChallengeGroupLists,
  fetchChallengeInfo,
  fetchChallengePartial,
  fetchChallengeStories,
  fetchChallengeTickets,
  fetchChallengeWysiwygContent,
  joinChallenge,
  makeChallengeDonation,
  saveChallengeWysiwygContent,
  submitChallengeActivity,
  validateChallengeTicket,
  verifyChallengeCoupon,
} from './challenges';
import { fetchFeed } from './dashboard';
import { fetchGroupListLeaderboard, fetchLeaderboard } from './leaderboard';
import { fetchP2PLeaderboard, fetchP2PTeamsLeaderboard } from './p2pLeaderboard';
import {
  confirmProfile,
  facebookLogin,
  fetchProfile,
  login,
  resetPassword,
  signup,
  stravaLogin,
  updatePassword,
} from './session';
/* ------------- Sagas ------------- */
import { watchErrors } from './toasts';

export function* rootSaga() {
  yield all([
    // v1 sagas
    takeLatest(AppTypes.DID_MOUNT, onComponentDidMount),
    takeLatest(LeaderboardTypes.FETCH_LEADERBOARD_REQUEST, fetchLeaderboard),
    takeEvery(LeaderboardTypes.FETCH_GROUP_LIST_LEADERBOARD_REQUEST, fetchGroupListLeaderboard),
    takeLatest(P2PLeaderboardTypes.FETCH_P2_P_LEADERBOARD_REQUEST, fetchP2PLeaderboard),
    takeEvery(P2PLeaderboardTypes.FETCH_P2_P_TEAMS_LEADERBOARD_REQUEST, fetchP2PTeamsLeaderboard),
    takeLatest(ChallengesTypes.FETCH_CHALLENGE_INFO_REQUEST, fetchChallengeInfo),
    takeLatest(ChallengesTypes.FETCH_CHALLENGE_STORIES_REQUEST, fetchChallengeStories),
    takeLatest(DashboardTypes.FETCH_FEED_REQUEST, fetchFeed),

    // v2 sagas
    takeLatest(SessionTypes.LOGIN_REQUEST, login),
    takeLatest(SessionTypes.FACEBOOK_LOGIN_REQUEST, facebookLogin),
    takeLatest(SessionTypes.STRAVA_LOGIN_REQUEST, stravaLogin),
    takeLatest(SessionTypes.CONFIRM_PROFILE_REQUEST, confirmProfile),
    takeLatest(SessionTypes.SIGNUP_REQUEST, signup),
    takeLatest(action => /_FAILURE/.test(action.type), watchErrors),
    takeLatest(SessionTypes.PASSWORD_RESET_REQUEST, resetPassword),
    takeLatest(SessionTypes.UPDATE_PASSWORD_REQUEST, updatePassword),
    takeLatest(ChallengesTypes.FETCH_CHALLENGE_PARTIAL_REQUEST, fetchChallengePartial),
    takeLatest(ChallengesTypes.JOIN_CHALLENGE_REQUEST, joinChallenge),
    takeLatest(SessionTypes.FETCH_PROFILE_REQUEST, fetchProfile),
    takeLatest(ChallengesTypes.FETCH_CHALLENGE_GROUP_LISTS_REQUEST, fetchChallengeGroupLists),
    takeLatest(ChallengesTypes.CREATE_CHALLENGE_GROUP_REQUEST, createChallengeGroup),
    takeLatest(ChallengesTypes.DELETE_CHALLENGE_GROUP_REQUEST, deleteChallengeGroup),
    takeLatest(
      ChallengesTypes.FETCH_CHALLENGE_WYSIWYG_CONTENT_REQUEST,
      fetchChallengeWysiwygContent,
    ),
    takeLatest(ChallengesTypes.SAVE_CHALLENGE_WYSIWYG_CONTENT_REQUEST, saveChallengeWysiwygContent),

    takeLatest(ChallengesTypes.FETCH_CHALLENGE_TICKETS_REQUEST, fetchChallengeTickets),
    takeLatest(ChallengesTypes.BUY_CHALLENGE_TICKETS_REQUEST, buyChallengeTickets),
    takeLatest(ChallengesTypes.VALIDATE_CHALLENGE_TICKET_REQUEST, validateChallengeTicket),
    takeLatest(
      ChallengesTypes.FETCH_CHALLENGE_FEATURED_ACTIVITY_TYPES_REQUEST,
      fetchChallengeFeaturedActivityTypes,
    ),
    takeLatest(ChallengesTypes.SUBMIT_CHALLENGE_ACTIVITY_REQUEST, submitChallengeActivity),
    takeLatest(ChallengesTypes.VERIFY_CHALLENGE_COUPON_REQUEST, verifyChallengeCoupon),
    takeLatest(ChallengesTypes.MAKE_CHALLENGE_DONATION_REQUEST, makeChallengeDonation),
  ]);
}
