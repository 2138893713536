import React, { Component } from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import ReactPixel from 'react-facebook-pixel';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router';
import { lazy } from '@loadable/component';
import * as Sentry from '@sentry/react';
import { ConnectedRouter } from 'connected-react-router';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import { ATLASGO_CONSENT_COOKIE_NAME, ROUTES } from 'config/constants';
import bindRouteParamsToProps from 'HOCs/bindRouteParamsToProps';
import mapHelpers from 'redux/mapHelpers';

import 'theme/styles/site.scss';

const Dashboard = lazy(() => import('containers/Dashboard'));
const GoBoardStatic = lazy(() => import('containers/v1/GoBoard/Static'));
const GoBoard = lazy(() => import('containers/v1/GoBoard/Default'));
const Status = lazy(() => import('containers/Status'));
const Toasts = lazy(() => import('containers/SmartWrappers/Toasts'));
const SweatySelfies = lazy(() => import('containers/Embeds/SweatySelfies'));
const Progress = lazy(() => import('containers/Embeds/Progress'));
const Leaderboards = lazy(() => import('containers/Embeds/Leaderboards'));
const FundraisingProgress = lazy(() => import('containers/Embeds/FundraisingProgress'));
const FundraisingLeaderboards = lazy(() => import('containers/Embeds/FundraisingLeaderboards'));
const ActivityTypesList = lazy(() => import('containers/Public/ActivityTypesList'));
const WebexInitiator = lazy(() => import('containers/Public/Webex/Initiator'));
const WebexShared = lazy(() => import('containers/Public/Webex/Shared'));
const Achievements = lazy(() => import('containers/Admin/Achievements'));
const ActivityTypes = lazy(() => import('containers/Admin/ActivityTypes'));
const ActivityVideos = lazy(() => import('containers/Admin/ActivityVideos'));
const ChallengesAdmin = lazy(() => import('containers/Admin/ChallengesAdmin'));
const ChallengeMaps = lazy(() => import('containers/Admin/ChallengeMaps'));
const Currencies = lazy(() => import('containers/Admin/Currencies'));
const FeedStories = lazy(() => import('containers/Admin/FeedStories'));
const Groups = lazy(() => import('containers/Admin/Groups'));
const Infra = lazy(() => import('containers/Admin/Infra'));
const Partners = lazy(() => import('containers/Admin/Partners'));
const Reports = lazy(() => import('containers/Admin/Reports'));
const Roles = lazy(() => import('containers/Admin/Roles'));
const Surveys = lazy(() => import('containers/Admin/Surveys'));
const Tickets = lazy(() => import('containers/Admin/Tickets'));
const Tracking = lazy(() => import('containers/Admin/Tracking'));
const Users = lazy(() => import('containers/Admin/Users'));
const Authenticate = lazy(() => import('containers/Authentication/Authenticate'));
const ResetPass = lazy(() => import('containers/Authentication/ResetPass'));
const Challenge = lazy(() => import('containers/Challenges/Challenge'));
const ChallengeFundraise = lazy(() => import('containers/Challenges/Challenge/Fundraise'));
const ChallengeTeam = lazy(() => import('containers/Challenges/Challenge/Group'));
const JoinChallenge = lazy(() => import('containers/Challenges/Challenge/Join'));
const ChallengeTeamUser = lazy(() => import('containers/Challenges/Challenge/User'));

const AndroidGpsSupport = lazy(() => import('components/AndroidGpsSupport'));
const Error = lazy(() => import('components/Error'));
const ConnectSlack = lazy(() => import('components/ConnectSlack'));
const PrivateRoute = lazy(() => import('components/PrivateRoute'));

export class App extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null, eventId: null };
  }

  componentDidMount() {
    this.l337h3ckz();

    // preload pages that are on the main menu
    Dashboard.preload();
    Partners.preload();
    ActivityTypes.preload();
    ChallengesAdmin.preload();
    ChallengeMaps.preload();
    Tickets.preload();
    Currencies.preload();
    Users.preload();
    Groups.preload();
    FeedStories.preload();
    Reports.preload();
    Tracking.preload();
    Roles.preload();
    Infra.preload();
    // Achievements.preload();
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });

    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  onAcceptCookieConsent = () => {
    Cookies.set(ATLASGO_CONSENT_COOKIE_NAME, true, { expires: 365 });
  };

  l337h3ckz() {
    // FBTargeting pixel for The Main Line Bike Race Virtual Edition. Probably want a
    // more robust way to do this in the future
    if (window.location.href.includes('5f124bd451877d318b1a312c')) {
      const advancedMatching = {};
      const options = {
        autoConfig: true, // set pixel's autoConfig
        debug: false, // enable logs
      };
      ReactPixel.init('297712598214779', advancedMatching, options);
      ReactPixel.pageView(); // For tracking page view
    }

    // FBTargeting pixel for The Main Line Bike Race Virtual Edition. Probably want a
    // more robust way to do this in the future
    if (window.location.href.includes('5efe68141d9ad34bdad57b29')) {
      const advancedMatching = {};
      const options = {
        autoConfig: true, // set pixel's autoConfig
        debug: false, // enable logs
      };
      ReactPixel.init('697482537033987', advancedMatching, options);
      ReactPixel.pageView(); // For tracking page view
    }

    // FBTargeting pixel for GetGutsy. Probably want a
    // more robust way to do this in the future
    if (window.location.href.includes('60a1fea05ccc73506bc000e7')) {
      const advancedMatching = {};
      const options = {
        autoConfig: true, // set pixel's autoConfig
        debug: false, // enable logs
      };
      ReactPixel.init('175180144645344', advancedMatching, options);
      ReactPixel.pageView(); // For tracking page view
    }

    // FBTargeting pixel for Bougeons contre l'arthrose. Probably want a
    // more robust way to do this in the future
    if (window.location.href.includes('628b7ac93f3ef7ee79c76209')) {
      const advancedMatching = {};
      const options = {
        autoConfig: true, // set pixel's autoConfig
        debug: false, // enable logs
      };
      ReactPixel.init('296938245867294', advancedMatching, options);
      ReactPixel.pageView(); // For tracking page view
    }

    // FBTargeting pixel for Sweatin' for Science 2023. Probably want a
    // more robust way to do this in the future
    if (window.location.href.includes('63c9a31d5eed51df62b4b4f2')) {
      const advancedMatching = {};
      const options = {
        autoConfig: true, // set pixel's autoConfig
        debug: false, // enable logs
      };
      ReactPixel.init('1325548674724068', advancedMatching, options);
      ReactPixel.pageView(); // For tracking page view
    }

    if (window.location.href.includes('5efe68141d9ad34bdad57b29')) {
      window.location = 'https://dash.atlasgo.org/challenges/60a1fea05ccc73506bc000e7';
    }
  }

  render() {
    const { error, eventId } = this.state;
    const { theme, navHistory, t } = this.props;
    const isEmbedPage = navHistory.location.pathname.includes('/embed/');
    const isWebexPage = navHistory.location.pathname.includes('/webex/');
    const disableCookieConsent = isEmbedPage || isWebexPage;

    if (error) {
      console.log({ error });
      // render fallback UI in case of an error
      return (
        <ThemeProvider theme={theme}>
          <Error onReport={() => Sentry.showReportDialog({ eventId })} {...this.props} />
        </ThemeProvider>
      );
    }

    // SwissRE CDN hack...replace all cloudinary domains with cloudfront proxied domain
    // for any pages referencing:
    // 'Together for Semilla Nueva'
    // 'Going The Social Distance For Place2Be'
    if (
      window.location.href.includes('5eb4fc6e047a00568e960da1') ||
      window.location.href.includes('5eab7ab73e7779e4c4cbb009') ||
      window.location.href.includes('5f76292a7745b0998c8384ca') ||
      window.location.href.includes('5fff37eb63d98a0418cefcf9') ||
      window.location.href.includes('6000a9d963d98a0418cf4b9e')
    ) {
      setInterval(() => {
        window.$('img').attr('src', (i, val) => {
          if (val.includes('cloudinary.com')) {
            return val.replace('res.cloudinary.com', 'dx7ciut5s6l8v.cloudfront.net');
          }
        });

        window.$('div').css('background-image', (i, val) => {
          if (val.includes('cloudinary.com')) {
            return val.replace('res.cloudinary.com', 'dx7ciut5s6l8v.cloudfront.net');
          }
        });
      }, 2000);
    }

    return (
      <ThemeProvider theme={theme}>
        <ConnectedRouter history={navHistory}>
          <Router history={navHistory}>
            <Switch>
              {/* auth pages */}
              <Route exact path={ROUTES.auth} component={Authenticate} />
              <Route exact path={ROUTES.pwReset} component={ResetPass} />
              <Route exact path={`${ROUTES.pwReset}/:token`} component={ResetPass} />
              <Route
                exact
                path={ROUTES.joinExpires()}
                component={bindRouteParamsToProps()(JoinChallenge)}
              />
              <Redirect
                exact
                from={ROUTES.join()}
                to={`${ROUTES.challenge()}?${window.location.search}`}
              />

              {/* public pages */}
              <Route exact path={ROUTES.challenge()} component={Challenge} />
              <Route exact path={ROUTES.sweatySelfies} component={SweatySelfies} />
              <Route exact path={ROUTES.leaderboards} component={Leaderboards} />
              <Route exact path={ROUTES.progress} component={Progress} />
              <Route
                exact
                path={ROUTES.fundraisingLeaderboards}
                component={FundraisingLeaderboards}
              />
              <Route exact path={ROUTES.fundraisingProgress} component={FundraisingProgress} />
              <Route exact path={ROUTES.challengeFundraise()} component={ChallengeFundraise} />
              <Route exact path={ROUTES.challengeGroup()} component={ChallengeTeam} />
              <Route exact path={ROUTES.challengeGroupUser()} component={ChallengeTeamUser} />
              <Redirect exact from={ROUTES.challengeTicketsRedirect()} to={ROUTES.challenge()} />
              <Redirect
                exact
                from={ROUTES.challengeDonationsRedirect()}
                to={{
                  pathname: ROUTES.challengeFundraise(),
                  search: new URL(window.location.href).search,
                }}
              />
              <Route exact path={ROUTES.status} component={Status} />
              <Route path={ROUTES.androidGpsSupport} component={AndroidGpsSupport} />
              <Route path={ROUTES.activityTypesList} component={ActivityTypesList} />
              <Route path={ROUTES.webexInitiator} component={WebexInitiator} />
              <Route path={ROUTES.webexShared()} component={WebexShared} />

              {/* admin pages */}
              <PrivateRoute exact path={ROUTES.landing} component={Dashboard} />
              <PrivateRoute exact path={ROUTES.home} component={Dashboard} />
              <PrivateRoute
                exact
                path={ROUTES.groups}
                component={Groups}
                permissions={['group:list', 'challenge:list_groups']}
              />
              <PrivateRoute exact path={ROUTES.users} component={Users} permissions="user:list" />
              <PrivateRoute exact path={ROUTES.roles} component={Roles} permissions="role:list" />
              <PrivateRoute exact path={ROUTES.infra} component={Infra} permissions="infra:list" />
              <PrivateRoute
                exact
                path={ROUTES.partners}
                component={Partners}
                permissions="partner:list"
              />
              <PrivateRoute
                exact
                path={ROUTES.activityTypes}
                component={ActivityTypes}
                permissions="activity:list"
              />
              <PrivateRoute
                exact
                path={ROUTES.activityVideos}
                component={ActivityVideos}
                permission="video:list"
              />
              <PrivateRoute
                exact
                path={ROUTES.challenges}
                component={ChallengesAdmin}
                permissions="challenge:list"
              />
              <PrivateRoute
                exact
                path={ROUTES.challengeIntegrations()}
                component={ChallengesAdmin}
                permissions="challenge:list_integrations"
              />
              <PrivateRoute
                exact
                path={ROUTES.challengeMaps}
                component={ChallengeMaps}
                permissions="challenge:list"
              />
              <PrivateRoute
                exact
                path={ROUTES.tickets}
                component={Tickets}
                permissions="ticket:list"
              />
              <PrivateRoute
                exact
                path={ROUTES.tracking}
                component={Tracking}
                permissions="tracking:list"
              />
              <PrivateRoute
                exact
                path={ROUTES.currencies}
                component={Currencies}
                permissions="currency:list"
              />
              <PrivateRoute
                exact
                path={ROUTES.feedStories}
                component={FeedStories}
                permissions="feed_story:list"
              />
              <PrivateRoute
                exact
                path={ROUTES.surveys}
                component={Surveys}
                permissions="survey:list"
              />
              <PrivateRoute
                exact
                path={ROUTES.reports}
                component={Reports}
                permissions="report:list"
              />
              <PrivateRoute exact path={ROUTES.connectSlack} component={ConnectSlack} />
              <PrivateRoute
                exact
                path={ROUTES.achievements}
                component={Achievements}
                permissions="achievement:list"
              />

              {/* version 1 routes */}
              <Route
                exact
                path={`${ROUTES.goBoard}/:challengeID`}
                component={bindRouteParamsToProps()(GoBoard)}
              />
              <Route
                exact
                path={`${ROUTES.goBoard}/:challengeID/static`}
                component={bindRouteParamsToProps()(GoBoardStatic)}
              />
              <Route
                exact
                path={`${ROUTES.goBoard}/:challengeID/:forceWelcomePopup`}
                component={bindRouteParamsToProps()(GoBoard)}
              />

              <Route path="*" component={() => <Error type="400" />} />
            </Switch>
          </Router>
          <Toasts />
          {!disableCookieConsent && (
            <CookieConsent
              expires={365}
              cookieName={ATLASGO_CONSENT_COOKIE_NAME}
              onAccept={this.onAcceptCookieConsent}
            >
              {/* eslint-disable-next-line */}
              {t(
                'This website stores cookies on your computer. These cookies are used to collect information about how you interact with our website and allows us to remember you. We use this information in order to improve and customize your browsing experience and for analytics and metrics about our visitors both on this website and other media. To find out more about the cookies we use, please see our',
              )}{' '}
              <a
                href="https://atlasgo.org/privacy"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'darkseagreen' }}
              >
                {t('Privacy Policy')}
              </a>
              .
            </CookieConsent>
          )}
        </ConnectedRouter>
      </ThemeProvider>
    );
  }
}

App.propTypes = {
  t: PropTypes.func,
  theme: PropTypes.object,
  navHistory: PropTypes.any,
};

const mapStateToProps = state => ({
  theme: state.theme.active,
});

const mapDispatchToProps = mapHelpers.Actions;

export default Sentry.withProfiler(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(App)),
);
