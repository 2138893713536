import i18n from 'i18next';
import { call, getContext, put, select } from 'redux-saga/effects';

import resizeImage from 'utils/resizeImage';

import SessionActions, { selectors as sessionSelectors } from '../reducers/session';
import ToastsActions from '../reducers/toasts';

export function* fetchProfile(action) {
  const services = yield getContext('services');

  try {
    const { getSessionKey: sessionKey } = sessionSelectors(yield select());

    if (!sessionKey) return;

    const profile = yield call([services.profileAPI, 'profile'], { sessionKey });

    if (profile.ok) {
      const perms = yield call([services.permissionsAPI, 'userPerms'], profile.data.id);

      if (perms.ok) {
        if (Array.isArray(perms.data)) {
          profile.data.permissions = perms.data;
          profile.data.can_view_admin = perms.data.length >= 3;
        } else {
          profile.data.permissions = perms.data.data;
          profile.data.is_admin = perms.data.meta.is_admin;
          profile.data.is_super_admin = perms.data.meta.is_super_admin;
          profile.data.can_view_admin = perms.data.data.length >= 3;
        }
      }

      yield put(SessionActions.fetchProfileSuccess(profile.data, sessionKey));
    } else {
      yield put(SessionActions.fetchProfileFailure(profile.originalError));
    }
  } catch (error) {
    yield put(SessionActions.fetchProfileFailure(error));
  }
}

export function* login(action) {
  const { identity, password, challengeID, groupIDs, ticketID, getProfile } = action;
  const services = yield getContext('services');

  try {
    // login to retrieve the session key
    const response = yield services.authAPIv2.login({
      identity,
      password,
      challengeID,
      groupIDs,
      ticketID,
    });

    if (response.ok) {
      const { session_key: sessionKey } = response.data.data;

      yield put(SessionActions.loginSuccess(sessionKey));
      if (getProfile) yield put(SessionActions.fetchProfileRequest());
    } else {
      yield put(SessionActions.loginFailure(response.originalError));
    }
  } catch (error) {
    yield put(SessionActions.loginFailure(error));
    // general error case
  }
}

export function* facebookLogin(action) {
  const { accessToken, challengeID, groupIDs, ticketID } = action;
  const services = yield getContext('services');

  try {
    // trade the fb access token in for a session key
    const response = yield services.authAPIv2.fb({
      accessToken,
      challengeID,
      groupIDs,
      ticketID,
    });

    if (response.ok) {
      const {
        profile,
        session_key: sessionKey,
        profile_confirmed: profileConfirmed,
      } = response.data.data;

      if (profileConfirmed) {
        yield put(SessionActions.facebookLoginSuccess(null, sessionKey));
        yield put(SessionActions.fetchProfileRequest());
      } else {
        yield put(SessionActions.facebookLoginSuccess(profile, sessionKey));
      }
    } else {
      yield put(SessionActions.facebookLoginFailure(response.originalError));
    }
  } catch (error) {
    yield put(SessionActions.facebookLoginFailure(error));
  }
}

export function* stravaLogin(action) {
  const { authCode, challengeID, groupIDs, ticketID } = action;
  const services = yield getContext('services');

  try {
    // trade the strava auth code in for a session key
    const response = yield services.authAPIv2.strava({
      authCode,
      challengeID,
      groupIDs,
      ticketID,
    });

    if (response.ok) {
      const {
        profile,
        session_key: sessionKey,
        profile_confirmed: profileConfirmed,
      } = response.data.data;

      if (profileConfirmed) {
        yield put(SessionActions.stravaLoginSuccess(null, sessionKey));
        yield put(SessionActions.fetchProfileRequest());
      } else {
        yield put(SessionActions.stravaLoginSuccess(profile, sessionKey));
      }
    } else {
      yield put(SessionActions.stravaLoginFailure(response.originalError));
    }
  } catch (error) {
    yield put(SessionActions.stravaLoginFailure(error));
  }
}

export function* confirmProfile(action) {
  const { profile } = action;
  const finalProfile = { ...profile };
  const services = yield getContext('services');

  // since by now we already have the session key, just get it from state
  // https://redux-saga.js.org/docs/api/#selectselector-args
  const { getSessionKey: sessionKey } = sessionSelectors(yield select());

  try {
    if (profile.avatarURL) {
      const image = yield resizeImage({ uri: profile.avatarURL, size: 1500 });

      // should we really be uploading the image before confirming the profile? 🤔
      const savedImage = yield call([services.uploadAPI, 'image'], { image });

      if (savedImage.ok) {
        finalProfile.avatarURL = savedImage.data.image_url;
      } else {
        yield put(SessionActions.confirmProfileFailure(savedImage.originalError));
      }
    }

    // try to update their profile
    const response = yield call([services.profileAPI, 'confirmDetails'], {
      sessionKey,
      ...finalProfile,
    });

    // the second condition is important because of how our current API works
    // TODO: I'd prefer not to do it this way. update the server not to always return 200
    if (response.ok && response.data.success) {
      yield put(SessionActions.confirmProfileSuccess());
      yield put(SessionActions.fetchProfileRequest());
    } else {
      yield put(SessionActions.confirmProfileFailure(response.originalError));
    }
  } catch (error) {
    yield put(SessionActions.confirmProfileFailure(error));
  }
}

export function* signup(action) {
  const { profile, challengeID, groupIDs, ticketID, marketingAccepted, getProfile } = action;
  const finalProfile = { ...profile };
  const services = yield getContext('services');

  try {
    //   if (profile.avatarURL) {
    //     const image = yield resizeImage({ uri: profile.avatarURL, size: 1500 });
    //
    //     const savedImage = yield call([services.uploadAPI, 'image'], { image });
    //
    //     if (savedImage.ok) {
    //       finalProfile.avatarURL = savedImage.data.image_url;
    //     } else {
    //       yield put(SessionActions.signupFailure(savedImage.originalError));
    //     }
    //   }

    // try to register user to retrieve the session key
    const response = yield services.authAPIv2.signup({
      ...finalProfile,
      challengeID,
      groupIDs,
      ticketID,
      marketingAccepted,
    });

    if (response.ok) {
      const { session_key: sessionKey } = response.data.data;

      yield put(SessionActions.signupSuccess(sessionKey));
      if (getProfile) yield put(SessionActions.fetchProfileRequest());
    } else {
      yield put(SessionActions.signupFailure(response.originalError));
    }
  } catch (error) {
    yield put(SessionActions.signupFailure(error));
  }
}

export function* resetPassword(action) {
  const { identity } = action;
  const services = yield getContext('services');

  try {
    const response = yield services.authAPIv2.reset({ identity });

    if (response.ok) {
      yield put(SessionActions.passwordResetSuccess());
      yield put(
        ToastsActions.addToast({
          type: 'success',
          text: i18n.t('Please check your inbox to reset your password.'),
        }),
      );
    } else {
      yield put(SessionActions.passwordResetFailure(response.originalError));
    }
  } catch (error) {
    yield put(SessionActions.passwordResetFailure(error));
  }
}

export function* updatePassword(action) {
  const { token, password } = action;
  const services = yield getContext('services');

  try {
    const response = yield services.authAPIv2.updatePassword({ token, password });

    if (response.ok) {
      yield put(
        // don't save the user and the sessionKey since we
        // don't want them to be logged in automatically
        SessionActions.updatePasswordSuccess(null, ''),
      );
      yield put(
        ToastsActions.addToast({
          type: 'success',
          text: i18n.t('Your password was reset successfully.'),
        }),
      );
    } else {
      yield put(SessionActions.updatePasswordFailure(response.originalError));
    }
  } catch (error) {
    yield put(SessionActions.updatePasswordFailure(error));
  }
}
