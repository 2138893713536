export default class AchievementsAdminAPI {
  constructor(services) {
    this.services = services;
    services.afterLoad(() => {
      // after load
    });
  }

  list(query = '') {
    return this.services.baseAPIv2.get('/admin/achievements/', false, {
      match: query,
    });
  }

  create(data) {
    const {
      i18n,
      hidden,
      name,
      tagline,
      description,
      goal,
      measurement_type,
      category,
      icon_id,
      time_zone,
      start_date,
      end_date,
      disable_manual_entry,
      disable_leaderboard,
      owner_ids,
    } = data;

    return this.services.baseAPIv2.post('/admin/achievements', {
      i18n,
      hidden,
      name,
      tagline,
      description,
      goal,
      measurement_type,
      category,
      icon_id,
      time_zone,
      start_date,
      end_date,
      disable_manual_entry,
      disable_leaderboard,
      owner_ids,
    });
  }

  update(id, data) {
    const {
      i18n,
      hidden,
      name,
      tagline,
      description,
      goal,
      measurement_type,
      category,
      icon_id,
      time_zone,
      start_date,
      end_date,
      disable_manual_entry,
      disable_leaderboard,
      owner_ids,
    } = data;

    return this.services.baseAPIv2.put(`/admin/achievements/${id}`, {
      i18n,
      hidden,
      name,
      tagline,
      description,
      goal,
      measurement_type,
      category,
      icon_id,
      time_zone,
      start_date,
      end_date,
      disable_manual_entry,
      disable_leaderboard,
      owner_ids,
    });
  }

  archive(id) {
    return this.services.baseAPIv2.patch(`/admin/achievements/${id}`);
  }

  get(id) {
    return this.services.baseAPIv2.get(`/admin/achievements/${id}`, false);
  }
}
