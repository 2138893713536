export default class TicketsAPI {
  constructor(services) {
    this.services = services;
    services.afterLoad(() => {
      // after load
    });
  }

  transaction({ id }) {
    return this.services.baseAPIv2.get(`/tickets/transactions/${id}`, false);
  }
}
