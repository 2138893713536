import { getContext, put } from 'redux-saga/effects';

import LeaderboardActions from '../reducers/leaderboard';

export function* fetchLeaderboard(action) {
  const { name, challengeID } = action;
  const services = yield getContext('services');
  const response = yield services.dashAPI.leaderboard({ name, challengeID });

  // success?
  if (response.ok) {
    yield put(LeaderboardActions.fetchLeaderboardSuccess(name, response.data.entries));
  } else {
    yield put(LeaderboardActions.fetchLeaderboardFailure(response));
  }
}

export function* fetchGroupListLeaderboard(action) {
  const { groupListID } = action;
  const services = yield getContext('services');

  const response = yield services.dashAPI.groupListLeaderboard({ groupListID });

  // success?
  if (response.ok) {
    yield put(LeaderboardActions.fetchLeaderboardSuccess(groupListID, response.data.entries));
  } else {
    yield put(LeaderboardActions.fetchLeaderboardFailure(response));
  }
}

// v2 Group List Leaderboard only returns teams leaderboard for now
export function* fetchLeaderboardv2(action) {
  // pass
}

// v2 Group List Leaderboard only returns teams leaderboard for now
export function* fetchGroupListLeaderboardv2(action) {
  // pass
}
