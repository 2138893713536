import produce from 'immer';
import { createActions, createReducer } from 'reduxsauce';
import { createSelector } from 'reselect';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions(
  {
    fetchFeedRequest: ['challengeID'],
    fetchFeedSuccess: ['feed'],
    fetchFeedFailure: ['error'],
  },
  { prefix: 'DASHBOARD/' },
);

export const DashboardTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  feed: null,

  error: null,
  fetching: false,
};

/* ------------- Reducers ------------- */

// we're attempting to fetch the feed
export const requestFeed = state =>
  produce(state, draft => {
    draft.fetching = true;
  });

// we've successfully fetched the feed
export const successFeed = (state, action) =>
  produce(state, draft => {
    const { feed } = action;

    draft.fetching = false;
    draft.error = null;
    draft.feed = feed;
  });

// we've had a problem fetching the feed
export const failureFeed = (state, { error }) =>
  produce(state, draft => {
    draft.fetching = false;
    draft.error = error;
  });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_FEED_REQUEST]: requestFeed,
  [Types.FETCH_FEED_SUCCESS]: successFeed,
  [Types.FETCH_FEED_FAILURE]: failureFeed,
});

/* ------------- Selectors ------------- */

const dashboard = state => state.dashboard;
const getFeed = createSelector(dashboard, d => d.feed || []);
const isFeedLoaded = createSelector(dashboard, d => d.feed !== null);

export const selectors = state => {
  return {
    getFeed: getFeed(state),
    isFeedLoaded: isFeedLoaded(state),
  };
};
