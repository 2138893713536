export default class ActivityVideosAPI {
  constructor(services) {
    this.services = services;
    services.afterLoad(() => {
      // after load
    });
  }

  list({ query, challengeID }) {
    return this.services.baseAPIv2.get('/admin/activity-videos/', false, {
      match: query,
      challenge_id: challengeID,
    });
  }

  create(data) {
    const {
      image_url,
      name,
      subtitle,
      vimeo_url,
      video_class,
      log_as_activity,
      default_required_seconds_watched,
      default_activity_logged_for_watching,
      default_seconds_logged,
      default_meters_logged,
      default_units_logged,
      i18n,
    } = data;

    return this.services.baseAPIv2.post('/admin/activity-videos', {
      image_url,
      name,
      subtitle,
      vimeo_url,
      video_class,
      log_as_activity,
      default_required_seconds_watched,
      default_activity_logged_for_watching,
      default_seconds_logged,
      default_meters_logged,
      default_units_logged,
      i18n,
    });
  }

  update(id, data) {
    const {
      image_url,
      name,
      subtitle,
      vimeo_url,
      video_class,
      log_as_activity,
      default_required_seconds_watched,
      default_activity_logged_for_watching,
      default_seconds_logged,
      default_meters_logged,
      default_units_logged,
      i18n,
    } = data;

    return this.services.baseAPIv2.put(`/admin/activity-videos/${id}`, {
      image_url,
      name,
      subtitle,
      vimeo_url,
      video_class,
      log_as_activity,
      default_required_seconds_watched,
      default_activity_logged_for_watching,
      default_seconds_logged,
      default_meters_logged,
      default_units_logged,
      i18n,
    });
  }

  archive(id) {
    return this.services.baseAPIv2.patch(`/admin/activity-videos/${id}`);
  }

  get(id) {
    return this.services.baseAPIv2.get(`/admin/activity-videos/${id}`, false);
  }
}
