import { analyticsReducer } from '@recruit-tech/react-redux-analytics';
import { connectRouter } from 'connected-react-router';

import { combineReducers } from 'redux';

import AppActions, { AppTypes, reducer as app, selectors as appSelectors } from './app';
import ChallengesActions, {
  ChallengesTypes,
  reducer as challenges,
  selectors as challengesSelectors,
} from './challenges';
import DashboardActions, {
  DashboardTypes,
  reducer as dashboard,
  selectors as dashboardSelectors,
} from './dashboard';
import LeaderboardActions, {
  LeaderboardTypes,
  reducer as leaderboard,
  selectors as leaderboardSelectors,
} from './leaderboard';
import P2PLeaderboardActions, {
  P2PLeaderboardTypes,
  reducer as P2PLeaderboard,
  selectors as P2PLeaderboardSelectors,
} from './p2pLeaderboard';
import SessionActions, {
  reducer as session,
  selectors as sessionSelectors,
  SessionTypes,
} from './session';
import ThemeActions, { reducer as theme, selectors as themeSelectors, ThemeTypes } from './theme';
/* PLOP_INJECT_IMPORT */
import ToastsActions, {
  reducer as toasts,
  selectors as toastsSelectors,
  ToastsTypes,
} from './toasts';

export const Types = {
  ...AppTypes,
  ...ChallengesTypes,
  ...DashboardTypes,
  ...LeaderboardTypes,
  ...P2PLeaderboardTypes,
  ...ThemeTypes,
  /* PLOP_INJECT_EXPORT_TYPES */
  ...ToastsTypes,
  ...SessionTypes,
};

export const Actions = {
  ...AppActions,
  ...ChallengesActions,
  ...DashboardActions,
  ...LeaderboardActions,
  ...P2PLeaderboardActions,
  ...ThemeActions,
  /* PLOP_INJECT_EXPORT_ACTIONS */
  ...ToastsActions,
  ...SessionActions,
};

export const selectors = state => {
  return {
    ...appSelectors(state),
    ...challengesSelectors(state),
    ...dashboardSelectors(state),
    ...leaderboardSelectors(state),
    ...P2PLeaderboardSelectors(state),
    ...themeSelectors(state),
    /* PLOP_INJECT_EXPORT_SELECTORS */
    ...toastsSelectors(state),
    ...sessionSelectors(state),
  };
};

export default history =>
  combineReducers({
    app,
    challenges,
    dashboard,
    leaderboard,
    P2PLeaderboard,
    theme,
    /* PLOP_INJECT_EXPORT_REDUCER */
    toasts,
    session,

    // this is for nav history
    // check https://github.com/supasate/connected-react-router#step-1
    router: connectRouter(history),

    // react-redux-analytics reducer
    // https://github.com/recruit-tech/react-redux-analytics#2-register-reducer
    analytics: analyticsReducer,
  });
