export default class permissionsAPI {
  constructor(services) {
    this.services = services;
    services.afterLoad(() => {});
  }

  userPerms(userId) {
    return this.services.baseAPIv2.get(`/users/${userId}/permissions`, false);
  }
}
