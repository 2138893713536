import CronParser from 'cron-parser';

export function quartzToCrontab(quartz) {
  if (!quartz) return '* * * * *';

  const quartzArray = quartz.split(' ').splice(1, 5);
  const cronArray = quartzArray.map(e => {
    if (e === '?') return '*';
    if (e.length >= 3) {
      return e.replace(/MON|TUE|WED|THU|FRI|SAT|SUN/gi, day => {
        return {
          SUN: 0,
          MON: 1,
          TUE: 2,
          WED: 3,
          THU: 4,
          FRI: 5,
          SAT: 6,
        }[day];
      });
    }

    return e;
  });

  return cronArray.join(' ');
}

export function crontabToQuartz(crontab) {
  const quartz = CronParser.parseExpression(crontab, {}).stringify(true);
  const quartzArray = quartz.split(' ');

  const result = quartzArray.map((e, i) => {
    // if (i === 2 && e === '0') return '00';
    // if (i === 3 && e === '?') return '1/1';
    // if (i === 3 && e === '*') return '?';
    // if (i === 5 && e === '*') return '?';
    if (i === 5) {
      return e.replace(/0|1|2|3|4|5|6/gi, day => {
        return {
          0: 'SUN',
          1: 'MON',
          2: 'TUE',
          3: 'WED',
          4: 'THU',
          5: 'FRI',
          6: 'SAT',
        }[day];
      });
    }

    return e;
  });

  result.push('*');

  return result.join(' ');
}
