const images = {
  logo: require('assets/images/logo.png').default,
  footerImage: require('assets/images/footerImage.png').default,
  carrotDown: require('assets/images/carrot-down.png').default,
  goldPlace: require('assets/images/gold-place.png').default,
  silverPlace: require('assets/images/silver-place.png').default,
  bronzePlace: require('assets/images/bronze-place.png').default,
  sidebarCollage: require('assets/images/sidebar-collage.jpg').default,
  sidebarCollageGradient: require('assets/images/sidebar-collage-gradient.jpg').default,
  atlasGOBlack: require('assets/images/atlasGO-black.png').default,
  atlasGOWhite: require('assets/images/atlasGO-white.png').default,
  GOIcon: require('assets/images/GO-icon.png').default,
  backArrow: require('assets/images/icons/black/arrow_back.png').default,

  facebook: require('assets/images/facebook.png').default,
  facebookSm: require('assets/images/facebook-small.png').default,
  facebookWhite: require('assets/images/facebook-white.png').default,
  facebookWhiteSm: require('assets/images/facebook-white-small.png').default,

  strava: require('assets/images/strava.png').default,
  stravaSm: require('assets/images/strava-small.png').default,
  stravaWhite: require('assets/images/strava-white.png').default,
  stravaWhiteSm: require('assets/images/strava-white-small.png').default,

  achievementsIcon: require('assets/images/sidebar/navigation/achievement.png').default,
  activitiesIcon: require('assets/images/sidebar/navigation/activities-icon.svg').default,
  challengesIcon: require('assets/images/sidebar/navigation/challenges-icon.svg').default,
  dashboardIcon: require('assets/images/sidebar/navigation/dashboard-icon.svg').default,
  partnersIcon: require('assets/images/sidebar/navigation/partners-icon.svg').default,
  usersIcon: require('assets/images/sidebar/navigation/users-icon.svg').default,
  ticketsIcon: require('assets/images/sidebar/navigation/tickets-icon.svg').default,
  currenciesIcon: require('assets/images/sidebar/navigation/coin.svg').default,
  groupsIcon: require('assets/images/sidebar/navigation/group2.svg').default,
  infraIcon: require('assets/images/sidebar/navigation/cloud-computing.svg').default,
  newspaper: require('assets/images/sidebar/navigation/newspaper.svg').default,
  survey: require('assets/images/sidebar/navigation/survey1.png').default,
  report: require('assets/images/sidebar/navigation/report.svg').default,
  roles: require('assets/images/sidebar/navigation/roles.svg').default,
  map: require('assets/images/sidebar/navigation/map1.png').default,
  infoIcon: require('assets/images/challenges/i.png').default,
  infoIconDark: require('assets/images/challenges/i-dark.png').default,
  videosIcon: require('assets/images/sidebar/navigation/video.png').default,

  appStoreBadge: require('assets/images/app-store-badge.png').default,
  playStoreBadge: require('assets/images/google-play-badge.png').default,

  // feed images
  high5Before: require('assets/images/feed/highfive-before.png').default,
  high5After: require('assets/images/feed/highfive-after.png').default,

  downloadDemo: require('assets/images/app-store-demo.png').default,
  exitIcon: require('assets/images/exit.png').default,
  exitIconLight: require('assets/images/exit_light.png').default,
  padlockIcon: require('assets/images/padlock.png').default,

  blob: {
    primary: require('assets/images/primary-blob.png').default,
    secondary: require('assets/images/secondary-blob.png').default,
  },

  move: require('assets/images/challenges/move-on-app.png').default,
  earnPrizes: require('assets/images/challenges/prizes.png').default,
  selectDonation: require('assets/images/challenges/select-donation.png').default,
  celebrate: require('assets/images/challenges/celebrate.png').default,
  signup: require('assets/images/challenges/signup.png').default,
  buy: require('assets/images/challenges/buy.png').default,

  // misc
  dummy200: require('assets/images/img/dummy-200px.png').default,
  feedPic: require('assets/images/img/feed-pic.jpg').default,
  avatar: require('assets/images/avatar.jpg').default,
  delete: require('assets/images/challenges/join/delete-team@3x.png').default,
  videoPlaceholder: require('assets/images/page-editor/video-placeholder.jpg').default,
  checked: require('assets/images/img/checked.png').default,
  unchecked: require('assets/images/img/unchecked.png').default,
  pencil: require('assets/images/img/pencil.png').default,
  locationIcon: require('assets/images/location-icon.png').default,
  signupCheckmark: require('assets/images/signup-checkmark.png').default,
  agreeUnchecked: require('assets/images/agree-unchecked.png').default,
  agreeChecked: require('assets/images/agree-checked.png').default,
  remove: require('assets/images/remove.svg').default,
  arrowUp: require('assets/images/arrow-up.svg').default,
  fileUpload: require('assets/images/file-upload.svg').default,
  eye: require('assets/images/eye.png').default,
  eyeHide: require('assets/images/eye-hide.png').default,

  // android-gps-support
  huawei8ergy1: require('assets/images/android-gps-support/device-specific/huawei-8ergy-1.jpg')
    .default,
  huawei8ergy2: require('assets/images/android-gps-support/device-specific/huawei-8ergy-2.png')
    .default,
  huaweiprotectedapps: require('assets/images/android-gps-support/device-specific/huawei-protected-apps.png')
    .default,
  huaweiergy1: require('assets/images/android-gps-support/device-specific/huaweiergy-1.png')
    .default,
  huaweiergy2: require('assets/images/android-gps-support/device-specific/huaweiergy-2.png')
    .default,
  huaweiergy3: require('assets/images/android-gps-support/device-specific/huaweiergy-3.png')
    .default,
  huaweiergy4: require('assets/images/android-gps-support/device-specific/huaweiergy-4.png')
    .default,

  nokia9settings: require('assets/images/android-gps-support/device-specific/nokia-9-settings.png')
    .default,
  nokiadontoptimize: require('assets/images/android-gps-support/device-specific/nokia-dontoptimize.png')
    .default,
  nokiaoptimize: require('assets/images/android-gps-support/device-specific/nokia-optimize.png')
    .default,

  sonyergy1: require('assets/images/android-gps-support/device-specific/sonyergy-1.png').default,
  sonyergy2: require('assets/images/android-gps-support/device-specific/sonyergy-2.png').default,
  sonyergy3: require('assets/images/android-gps-support/device-specific/sonyergy-3.png').default,

  xiaomi9batterysettings: require('assets/images/android-gps-support/device-specific/xiaomi-9-battery-settings.png')
    .default,
  xiaomi9norestrictions: require('assets/images/android-gps-support/device-specific/xiaomi-9-no-restrictions.png')
    .default,
  xiaominotifications: require('assets/images/android-gps-support/device-specific/xiaomi-notifications.png')
    .default,
  xiaomiotherpermissions: require('assets/images/android-gps-support/device-specific/xiaomi-other-permissions.png')
    .default,
  xiaomibatterycache: require('assets/images/android-gps-support/device-specific/xiaomi-battery-cache.png')
    .default,
  xiaominorestrictions: require('assets/images/android-gps-support/device-specific/xiaomi-no-restrictions.png')
    .default,
  xiaomicustomnotifications: require('assets/images/android-gps-support/device-specific/xiaomi-custom-notifications.png')
    .default,
  xiaomiotherpermissions1: require('assets/images/android-gps-support/device-specific/xiaomi-other-permissions1.png')
    .default,

  // flags
  iconBelgium: require('assets/images/flags/belgium_32px.png').default,
  iconBulgaria: require('assets/images/flags/bulgaria_32px.png').default,
  iconFrance: require('assets/images/flags/france_32px.png').default,
  iconGermany: require('assets/images/flags/germany_32px.png').default,
  iconItaly: require('assets/images/flags/italy_32px.png').default,
  iconPortugal: require('assets/images/flags/portugal_32px.png').default,
  iconSpain: require('assets/images/flags/spain_32px.png').default,
  iconUK: require('assets/images/flags/uk_32px.png').default,
  iconUSA: require('assets/images/flags/usa_32px.png').default,
  globalIcon: require('assets/images/global_icon.svg').default,

  // stats
  statsActivities: require('assets/images/sidebar/stats/activities.svg').default,
  statsShares: require('assets/images/sidebar/stats/shares.svg').default,
  statsParticipants: require('assets/images/sidebar/stats/participants.svg').default,

  // overlay previews
  previewBikeRace: require('assets/images/previews/preview_bike_race.png').default,
  previewFriendsGarden: require('assets/images/previews/preview_friends_garden.png').default,
  previewMeditate: require('assets/images/previews/preview_meditate.png').default,
  previewMuscles: require('assets/images/previews/preview_muscles.png').default,
  previewTeamHands: require('assets/images/previews/preview_team_hands.png').default,

  // Slack
  slackRGB: require('assets/images/integrations/slack/Slack_RGB.svg').default,
  slackRGBWhite: require('assets/images/integrations/slack/Slack_RGB_White.svg').default,
  slackMonochromeBlack: require('assets/images/integrations/slack/Slack_Monochrome_Black.svg')
    .default,
  slackMonochromeWhite: require('assets/images/integrations/slack/Slack_Monochrome_White.svg')
    .default,
  slackMark: require('assets/images/integrations/slack/Slack_Mark.svg').default,
  slackMarkMonochromeBlack: require('assets/images/integrations/slack/Slack_Mark_Monochrome_Black.svg')
    .default,
  slackMarkMonochromeWhite: require('assets/images/integrations/slack/Slack_Mark_Monochrome_White.svg')
    .default,
  slackBot: require('assets/images/integrations/slack/slackbot.png').default,
  slackAppFeatureStats1: require('assets/images/integrations/slack-feature-stats-1.png').default,
  slackAppFeatureStats2: require('assets/images/integrations/slack-feature-stats-2.png').default,
  slackAppFeatureLeaderboard: require('assets/images/integrations/slack-feature-leaderboard.png')
    .default,
  slackAppFeatureMilestone: require('assets/images/integrations/slack-feature-milestone.png')
    .default,
  slackAppFeatureHighFives: require('assets/images/integrations/slack-feature-high-fives.png')
    .default,

  // Teams
  teams: require('assets/images/integrations/teams/microsoft-teams-logo.webp').default,
};

export default images;
