import produce from 'immer';
import { createActions, createReducer } from 'reduxsauce';
import { createSelector } from 'reselect';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions(
  {
    fetchLeaderboardRequest: ['name', 'challengeID'],
    fetchLeaderboardSuccess: ['name', 'entries'],
    fetchLeaderboardFailure: ['error'],

    selectLeaderboard: ['payload'],

    fetchGroupListLeaderboardRequest: ['groupListID'],

    fetchLeaderboard_v2Request: ['challengeID'],
    fetchLeaderboard_v2Success: ['name', 'entries'],
    fetchLeaderboard_v2Failure: ['error'],
  },
  { prefix: 'LEADERBOARD/' },
);

export const LeaderboardTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  leaderboard: {},
  visibleLeaderboard: 'individuals',

  error: null,
  fetching: false,
};

/* ------------- Reducers ------------- */

// we're attempting to fetch the leaderboard
export const requestLeaderboard = state =>
  produce(state, draft => {
    draft.fetching = true;
  });

// we've successfully fetched the leaderboard
export const successLeaderboard = (state, action) =>
  produce(state, draft => {
    const { name, entries } = action;

    draft.fetching = false;
    draft.error = null;
    draft.leaderboard[name] = entries;
  });

// we've had a problem fetching the leaderboard
export const failureLeaderboard = (state, { error }) =>
  produce(state, draft => {
    draft.fetching = false;
    draft.error = error;
  });

// we've selected a leaderboard type
export const selectLeaderboard = (state, action) =>
  produce(state, draft => {
    const id = action.payload;
    if (state.visibleLeaderboard !== id) draft.visibleLeaderboard = id;
    else draft.visibleLeaderboard = null;
  });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_LEADERBOARD_REQUEST]: requestLeaderboard,
  [Types.FETCH_LEADERBOARD_SUCCESS]: successLeaderboard,
  [Types.FETCH_LEADERBOARD_FAILURE]: failureLeaderboard,

  [Types.SELECT_LEADERBOARD]: selectLeaderboard,

  [Types.FETCH_GROUP_LIST_LEADERBOARD_REQUEST]: requestLeaderboard,

  [Types.FETCH_LEADERBOARD_V2_REQUEST]: requestLeaderboard,
  [Types.FETCH_LEADERBOARD_V2_SUCCESS]: successLeaderboard,
  [Types.FETCH_LEADERBOARD_V2_FAILURE]: failureLeaderboard,
});

/* ------------- Selectors ------------- */

const leaderboard = state => state.leaderboard;
const getVisibleLeaderboard = createSelector(leaderboard, l => l.visibleLeaderboard);

const getLeaderboard = state => name => {
  return createSelector(leaderboard, l => l.leaderboard[name])(state);
};

const isLeaderboardLoaded = state => name => {
  return createSelector(leaderboard, l => l.leaderboard[name] != null)(state);
};

export const selectors = state => {
  return {
    getLeaderboard: getLeaderboard(state),
    isLeaderboardLoaded: isLeaderboardLoaded(state),
    getVisibleLeaderboard: getVisibleLeaderboard(state),
  };
};
