// CRA automatically loads environment variables from .env file
// https://facebook.github.io/create-react-app/docs/adding-custom-environment-variables
export const ENV = process.env.NODE_ENV || 'development';

export const APP_NAME = 'atlasGO Dashboard';
export const LLC_NAME = 'atlasGO.';
export const APP_WEBSITE = 'https://atlasgo.org';
export const API_BASE_URL = 'https://api.atlas.run';
export const WS_BASE_URL = 'wss://api.atlas.run';
export const STAGING_API_BASE_URL = 'https://api.atlas.run';
export const STAGING_WS_BASE_URL = 'wss://api.atlas.run';
export const LOCALHOST_BASE_URL = 'http://localhost:8000';
export const LOCALHOST_RUBY_BASE_URL = 'http://localhost:4567';
export const LOCALHOST_WS_BASE_URL = 'ws://localhost:8000';
export const ATLASGO_TERMS_OF_USE = 'https://atlasgo.org/terms';
export const ATLASGO_PRIVACY_POLICY = 'https://atlasgo.org/privacy';
export const ATLASGO_CONSENT_COOKIE_NAME = 'atlasGOCookieConsent';

// TODO: we should probably create new marketing links. We pulled
// the following directly from our website https://atlasgo.org
export const APP_STORE_URL = 'https://apps.apple.com/us/app/id6443588741';
export const PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.atlasgoapp';

// atlasGO stuff
export const APP_SECRET = process.env.REACT_APP_SECRET || 'atlas-run';
export const APP_DOMAIN = process.env.REACT_APP_DOMAIN || 'https://dash.atlasgo.org';
export const APP_HOMEPAGE = APP_DOMAIN;
export const HOMEPAGE = APP_DOMAIN;
export const DEFAULT_COVER_IMAGE =
  'https://res.cloudinary.com/dyddo9jo1/image/upload/v1526490862/Screen_Shot_2018-05-15_at_6.20.31_PM_kt6j5d.png';
export const DEFAULT_USER_IMAGE_CLOUDINARY_PATH = 'v1562017093/Circle_pv6otd.png';
export const DEFAULT_USER_IMAGE = `https://res.cloudinary.com/dyddo9jo1/image/upload/${DEFAULT_USER_IMAGE_CLOUDINARY_PATH}`;
export const API_STATS_ROUTE =
  process.env.REACT_APP_API_STATS_ROUTE || 'jd3hm52h80wbtjaew6mguwfmn4fbkvujxi1ub9b3';

// cloudinary stuff
export const CLOUDINARY_CLOUD_NAME = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME || 'dyddo9jo1';
export const CLOUDINARY_API_KEY = process.env.REACT_APP_CLOUDINARY_API_KEY;
export const CLOUDINARY_API_SECRET = process.env.REACT_APP_CLOUDINARY_API_SECRET;
export const CLOUDINARY_TRANSPARENCY_BASE = 'jf7f6b0mvnmkdztb0y3z.png';

// logging
export const USE_REACTOTRON = process.env.REACT_APP_USE_REACTOTRON;
export const USE_REDUX_LOGGER = process.env.REACT_APP_USE_REDUX_LOGGER;
export const USE_REDUX_DEV_TOOLS = process.env.REACT_APP_USE_REDUX_DEV_TOOLS;
export const LOG_ROCKET_PROJECT =
  process.env.REACT_APP_LOG_ROCKET_PROJECT || 'atlasgotest/web-client';
export const SENTRY_DSN =
  process.env.REACT_APP_SENTRY_DSN || 'https://205e50f375d343aaa7e690918d7ac3c5@sentry.io/1463676';

// facebook
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;

// strava stuff
const apiURL = ENV === 'development' ? STAGING_API_BASE_URL : API_BASE_URL;
export const STRAVA_CLIENT_ID = process.env.REACT_APP_STRAVA_CLIENT_ID;
export const STRAVA_AUTH_URL = `https://www.strava.com/oauth/authorize?client_id=${STRAVA_CLIENT_ID}&response_type=code&redirect_uri=${apiURL}/v2/auth/strava/web/bounce&approval_prompt=auto&scope=activity:read`;

// stripe
export const STRIPE_SECRET_KEY = process.env.REACT_APP_STRIPE_SECRET_KEY;
export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

// MapBox
export const MAP_BOX_API_ACCESS_TOKEN =
  process.env.REACT_MAP_BOX_API_ACCESS_TOKEN ||
  'pk.eyJ1IjoiYXRsYXNydW4iLCJhIjoiY2t6cTRmaGN5MDB5OTJwcDQ1b2Z5cDMxaiJ9.H6o5JInrJFfX-7uA9cuPfg';

// terraform
export const TERRAFORM_API_TOKEN = process.env.REACT_APP_TERRAFORM_API_TOKEN;
export const TERRAFORM_WORKSPACE_ID = process.env.REACT_APP_TERRAFORM_WORKSPACE_ID;

// Datadog
export const DATADOG_APP_ID =
  process.env.REACT_APP_DATADOG_APP_ID || 'e33c7b33-7f42-4031-a372-0b1ec55ebc89';
export const DATADOG_CLIENT_TOKEN =
  process.env.REACT_APP_DATADOG_CLIENT_TOKEN || 'pub1c97ba1c3d9c152d5f06f9c56b02152f';

// Slack
export const SLACK_APP_REDIRECT_URI_HOST =
  process.env.REACT_APP_SLACK_APP_REDIRECT_URI_HOST || 'https://api.atlas.run';
