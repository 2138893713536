export default {
  // functions return fixtures

  setHeader: (header, value) => {
    return `Go ahead Ninja. I don't need this crap -> ${header}: ${value}`;
  },

  dummy: () => require('./dummy.json'),
  getChallengeID: () => '5cd340d5a6ff131f92d856a3',
  getChallenge: () => require('./challenge.json'),
  getFeed: () => require('./feed.json'),
  getIndividualsLeaderboard: () => require('./individualsLeaderboard.json'),
  getGroupListLeaderboard: () => require('./groupListLeaderboard.json'),
  getUserTrackingRecords: () => require('./userTrackingRecords'),
  getTestTrackersList: () => require('./testTrackersList'),
  getFundraisingTeamsLeaderboard: () => require('./fundraisingTeamsLeaderboard.json'),
};
