export default class groupsAPI {
  constructor(services) {
    this.services = services;
    services.afterLoad(() => {
      // after load
    });
  }

  list({ query, groupListID }) {
    return this.services.baseAPIv2.get('/admin/groups/', false, {
      match: query,
      group_list_id: groupListID,
    });
  }

  getGroupData({ groupID }) {
    return this.services.baseAPIv2.get(`/admin/groups/${groupID}`, false);
  }

  getGroupUsersData({ groupID, query }) {
    return this.services.baseAPIv2.post(`/admin/groups/users/${groupID}`, {
      match: query,
    });
  }

  create(data) {
    const { name, image_url, is_goal, is_p2p_goal, amount, groupListID } = data;

    return this.services.baseAPIv2.post('/admin/groups', {
      name,
      image_url,
      is_goal,
      is_p2p_goal,
      amount,
      group_list_id: groupListID,
    });
  }

  update(id, data) {
    const { name, image_url, is_goal, is_p2p_goal, amount, groupListID } = data;

    return this.services.baseAPIv2.put(`/admin/groups/${id}`, {
      name,
      image_url,
      is_goal,
      is_p2p_goal,
      amount,
      group_list_id: groupListID,
    });
  }

  delete(id) {
    return this.services.baseAPIv2.put(`/admin/groups/delete/${id}`);
  }

  createMany({ groups }) {
    return this.services.baseAPIv2.post('/admin/groups/create-many', {
      groups,
    });
  }

  addUser({ groupID, userID, groupListID = '', transferActivities = false }) {
    return this.services.baseAPIv2.put(`/admin/groups/${groupID}/add-user`, {
      user_id: userID,
      group_list_id: groupListID,
      transfer_activities: transferActivities,
    });
  }

  getGroupUserData({ groupID, userID, groupListID }) {
    const group_list_id = groupListID || '';
    return this.services.baseAPIv2.get(
      `/admin/groups/${groupID}/user-data/${userID}?group_list_id=${group_list_id}`,
      false,
    );
  }

  transferUser({ oldGroupID, newGroupID, userID, groupListID = '', transferActivities = false }) {
    return this.services.baseAPIv2.put(`/admin/groups/${oldGroupID}/transfer-user`, {
      new_group_id: newGroupID,
      user_id: userID,
      group_list_id: groupListID,
      transfer_activities: transferActivities,
    });
  }

  mergeActivities({ groupListID, groupID, userID }) {
    return this.services.baseAPIv2.put(`/admin/groups/${groupID}/merge-activities`, {
      user_id: userID,
      group_list_id: groupListID,
    });
  }

  removeUser({ groupID, userID }) {
    return this.services.baseAPIv2.put(`/admin/groups/${groupID}/remove-user`, {
      user_id: userID,
    });
  }
}
