export default class TrackingAPI {
  constructor(services) {
    this.services = services;
    services.afterLoad(() => {
      // after load
    });
  }

  trackersList() {
    return this.services.baseAPIv2.get('/tracking');
  }

  userTrackingHistory(userId) {
    return this.services.baseAPIv2.get(`/tracking/user/${userId}`);
  }

  updateTrackingHistoryItem(data) {
    const { id, activityId, activityName, groundTruthDistance } = data;

    return this.services.baseAPIv2.put(`/tracking/${id}`, {
      activity_id: activityId,
      activity_name: activityName,
      ground_truth_distance: groundTruthDistance,
    });
  }

  analyze(algorithm) {
    return this.services.baseAPIv2.post(`/tracking/analyze?algorithm=${algorithm}`);
  }

  trackingRecord(id) {
    return this.services.baseAPIv2.get(`/tracking/${id}`);
  }
}
