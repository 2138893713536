export default class ProfileAPI {
  constructor(services) {
    this.services = services;
    services.afterLoad(() => {});
  }

  profile({ sessionKey = null }) {
    const res = {};

    if (sessionKey !== null) res.session_key = sessionKey;

    return this.services.baseAPI.post('profile', res);
  }

  update(params) {
    return this.services.baseAPI.post('profile/update', params);
  }

  confirmDetails({ firstName, lastName, email, username, sessionKey, avatarURL }) {
    return this.services.baseAPI.post('profile/confirm_details', {
      first_name: firstName,
      last_name: lastName,
      email,
      username,
      session_key: sessionKey,
      avatar_url: avatarURL,
    });
  }
}
