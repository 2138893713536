import i18next from 'i18next';
import { all, call, put } from 'redux-saga/effects';

import ChallengesActions from '../reducers/challenges';
import DashboardActions from '../reducers/dashboard';

import { onDashMounted } from './dashboard';

export function* onComponentDidMount(action) {
  const { component, payload } = action;

  const language = i18next.language.toLowerCase();
  const distanceUnits = language === 'en' || language === 'en-us' ? null : 'km';

  // call the specific component mount handler here
  if (component === 'GoBoard') {
    const { challengeID } = payload;

    yield call(onDashMounted, { challengeID });
  } else if (component === 'Challenge') {
    const { challengeID } = payload;

    // parallelize api calls for challenge page
    yield all([
      put(ChallengesActions.fetchChallengeInfoRequest(challengeID, distanceUnits)),
      put(DashboardActions.fetchFeedRequest(challengeID)),
      put(ChallengesActions.fetchChallengeGroupListsRequest(challengeID)),
      put(ChallengesActions.fetchChallengeWysiwygContentRequest(challengeID)),
      put(ChallengesActions.fetchChallengeTicketsRequest(challengeID)),
    ]);
  } else if (component === 'Fundraise') {
    const { challengeID } = payload;

    // parallelize api calls for challenge donations page
    yield all([
      put(ChallengesActions.fetchChallengeInfoRequest(challengeID, distanceUnits)),
      put(ChallengesActions.fetchChallengeGroupListsRequest(challengeID)),
      put(ChallengesActions.fetchChallengeWysiwygContentRequest(challengeID)),
    ]);
  } else if (component === 'Group' || component === 'User') {
    const { challengeID } = payload;

    // parallelize api calls for challenge donations page
    yield all([put(ChallengesActions.fetchChallengeInfoRequest(challengeID, distanceUnits))]);
  }
}
