export default class usersAPI {
  constructor(services) {
    this.services = services;
    services.afterLoad(() => {
      // after load
    });
  }

  list({ query, challengeID, roleID }) {
    return this.services.baseAPIv2.get('/admin/users/', false, {
      match: query,
      challenge_id: challengeID,
      role_id: roleID,
    });
  }

  listEligibleOwners({ query }) {
    return this.services.baseAPIv2.get('/admin/users/eligible-owners', false, {
      match: query,
    });
  }

  createUser(data) {
    // const {
    //   first_name,
    //   last_name,
    //   location_telemetry
    //   challenge_id,
    // distance_units,
    //   avatar_url,
    //   role_id,
    // } = data;
    //
    // return this.services.baseAPIv2.post('/admin/users/create', {
    //   first_name,
    //   last_name,
    //   location_telemetry
    //   challenge_id,
    // distance_units,
    //   avatar_url,
    //   role_id,
    // });
  }

  getUserData({ userID }) {
    return this.services.baseAPIv2.get(`/admin/users/${userID}`, false);
  }

  updateUser(id, data) {
    const {
      first_name,
      last_name,
      location_telemetry,
      challenge_id,
      distance_units,
      avatar_url,
      role_id,
    } = data;

    return this.services.baseAPIv2.put(`/admin/users/${id}`, {
      first_name,
      last_name,
      location_telemetry,
      challenge_id,
      distance_units,
      avatar_url: typeof avatar_url === 'string' ? avatar_url : avatar_url.full,
      role_id,
    });
  }

  deleteUser(id) {
    return this.services.baseAPIv2.delete(`/admin/users/${id}`, false);
  }

  deleteUsers(user_ids, remove_subscriber = true) {
    return this.services.baseAPIv2.post(`/admin/users/bulk-delete`, {
      user_ids,
      remove_subscriber,
    });
  }

  getActivityData({ activityID }) {
    return this.services.baseAPIv2.get(`/admin/users/activity/${activityID}`, false);
  }

  deleteActivity({ activityID }) {
    return this.services.baseAPIv2.get(`/admin/users/activity/delete/${activityID}`, false);
  }

  createActivity(data) {
    const { userID, created_at, activity_type_id, challenge_id, seconds, meters, units } = data;

    return this.services.baseAPIv2.post('/admin/users/activity/create', {
      user_id: userID,
      created_at,
      activity_type_id,
      challenge_id,
      seconds,
      meters,
      units,
    });
  }

  updateActivity(id, data) {
    const { userID, created_at, activity_type_id, challenge_id, seconds, meters, units } = data;

    return this.services.baseAPIv2.put('/admin/users/activity/update', {
      user_id: userID,
      created_at,
      activity_type_id,
      challenge_id,
      seconds,
      meters,
      units,
      activity_record_id: id,
    });
  }

  getUserDonations({ userID }) {
    return this.services.baseAPIv2.get(`/admin/users/${userID}/donations`, false);
  }

  getDonationData({ donationID }) {
    return this.services.baseAPIv2.get(`/admin/users/donations/${donationID}`, false);
  }

  createDonation(data) {
    const {
      userID,
      amount,
      donatorName,
      donatorEmail,
      challenge_id,
      currency_id,
      created_at,
      status,
      is_anonymous,
    } = data;

    return this.services.baseAPIv2.post('/admin/users/donations/create', {
      user_id: userID,
      amount,
      name: donatorName,
      email: donatorEmail,
      challenge_id,
      currency_id,
      created_at,
      status,
      is_anonymous,
    });
  }

  updateDonation(id, data) {
    const {
      userID,
      amount,
      donatorName,
      donatorEmail,
      challenge_id,
      currency_id,
      created_at,
      status,
      is_anonymous,
    } = data;

    return this.services.baseAPIv2.put('/admin/users/donations/update', {
      user_id: userID,
      amount,
      name: donatorName,
      email: donatorEmail,
      challenge_id,
      currency_id,
      created_at,
      donation_id: id,
      status,
      is_anonymous,
    });
  }

  deleteDonation({ donationID }) {
    return this.services.baseAPIv2.get(`/admin/users/donations/delete/${donationID}`, false);
  }
}
