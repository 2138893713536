export default class ActivityTypesAPI {
  constructor(services) {
    this.services = services;
    services.afterLoad(() => {});
  }

  list() {
    return this.services.baseAPIv2.get('activity-types');
  }
}
